import { createAction, props } from '@ngrx/store';
//da sostituire con @pwa-graphql
import { SensorNodeLastPosition } from '@pwa/graphql';
import { Dictionary, SensorNode } from '../../models';

export const LOAD_SENSOR_NODES = '[SensorNodes] Load Sensor Nodes';

export const CHANGE_SELECTED_SENSOR_NODE_SERIAL_NUMBER = '[SensorNodes] Change Selected Sensor Node';

export const PUT_SENSOR_NODE = '[SensorNodes] Put Sensor Node';

export const UPDATE_SENSOR_NODE = '[SensorNodes] Update Sensor Node';

export const SET_SENSOR_NODES_POSITION = '[SensorNodes] Set Sensor Nodes Position';

export const SET_SENSOR_NODES_TIMEZONES = '[SensorNodes] Set Sensor Nodes Timezones';

export const LoadSensorNodes = createAction(
  LOAD_SENSOR_NODES,
  props<{
    sensorNodes: SensorNode[],
    index: number;
  }>()
)

export const ChangeSelectedSensorNodeSerialNumber = createAction(
  CHANGE_SELECTED_SENSOR_NODE_SERIAL_NUMBER,
  props<{
    sensorNode: string
  }>()
)

export const PutSensorNode = createAction(
  PUT_SENSOR_NODE,
  props<{
    serial: string,
    name: string
  }>()
)

export const UpdateSensorNode = createAction(
  UPDATE_SENSOR_NODE,
  props<{
    serial: string,
    name: string
  }>()
)

export const SetSensorNodesPosition = createAction(
  SET_SENSOR_NODES_POSITION,
  props<{
    payload: SensorNodeLastPosition[]
  }>()
)

export const SetSensorNodesTimezones = createAction(
  SET_SENSOR_NODES_TIMEZONES,
  props<{
    timezones: Dictionary<string>
  }>()
)
