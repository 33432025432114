import { Component, OnInit, OnDestroy, } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import * as fromStore from '../../../store';
import { distinctUntilChanged, filter, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import * as models from '../../../models';
import { GraphqlService } from '../../../services/graphql.service';
import { NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { MapComponent } from '../../../evja/components/map/map.component';
import { DialogService } from 'primeng/dynamicdialog';
import { RenameSensorNodeComponent } from '../rename-sensor-node/rename-sensor-node.component';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'apps/web-app/src/environments/environment';
import { AnalyticsService } from '../../../@core/utils';


@Component({
  selector: 'pwa-select-sensor-node',
  templateUrl: './select-sensor-node.component.html',
  styleUrls: ['./select-sensor-node.component.scss'],
  providers: [DialogService]
})
export class SelectSensorNodeComponent implements OnInit, OnDestroy {
  mapComponent = MapComponent;
  destroy$: Subject<void> = new Subject<void>();
  destroySubscribeToLastMeasure$: Subject<void> = new Subject<void>();
  sensorNodes$: Observable<models.SensorNode[]> | undefined;
  sensorNodesAdmin: models.SensorNode[] = [];
  sensorNodesUser: models.SensorNode[] = [];
  selectedSensorNodeFormControl = new FormControl();
  editButtonDisabled: boolean = true;


  constructor(
    private store: Store<AppState>,
    private graphqlService: GraphqlService,
    private dialogService: DialogService,
    public translate: TranslateService,
    private keycloakService: KeycloakService,
    private analytics: AnalyticsService
  ) { }

  ngOnInit(): void {
    // this.sensorNodes$ = this.store.select(fromStore.selectAllSensorNodes);

    if (this.isAdmin()) {
      this.store
        .select(fromStore.selectAllSensorNodes)
        .pipe(
          map(sensorNode => sensorNode.sort((a, b) => a.serialNumber.toLowerCase().localeCompare(b.serialNumber.toLowerCase()))),
          takeUntil(this.destroy$)
        )
        .subscribe(sensorNode => this.sensorNodesAdmin = sensorNode);
    } else {
      this.store
        .select(fromStore.selectAllSensorNodes)
        .pipe(
          map(sensorNode => sensorNode.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))),
          takeUntil(this.destroy$)
        )
        .subscribe(sensorNode => this.sensorNodesUser = sensorNode);
    }


    this.store
      .select(
        fromStore.selectCurrentSensorNode
      )
      .pipe(
        filter(v => v != undefined),
        distinctUntilChanged())
      .subscribe(selectedSensorNode => {
        this.graphqlService.subscribeToLastMeasure
          (
            {
              sensorNodeSerial: selectedSensorNode!.serialNumber
            }
          )
          .pipe(takeUntil(this.destroySubscribeToLastMeasure$))
          .subscribe(v => this.store.dispatch(fromStore.AddMeasure(
            {
              payload:
              {
                id: v.data.lastMeasure.id,
                type: v.data.lastMeasure.type,
                timestamp: v.data.lastMeasure.timestamp,
                value: +v.data.lastMeasure.value
              }
            }
          )));
        this.selectedSensorNodeFormControl.setValue(selectedSensorNode);
        this.editButtonDisabled = false;
      });
    this.selectedSensorNodeFormControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged()
      )
      .subscribe((node: models.SensorNode) => {
        this.store.dispatch(fromStore.ChangeSelectedSensorNodeSerialNumber({ sensorNode: node.serialNumber }));
        this.store.dispatch(fromStore.LoadNotifications({ payload: { username: this.keycloakService.getUsername(), language: this.translate.currentLang } }))
        this.destroySubscribeToLastMeasure$.next();
        this.graphqlService.subscribeToLastMeasure
          (
            {
              sensorNodeSerial: node.serialNumber
            }
          )
          .pipe(takeUntil(this.destroySubscribeToLastMeasure$))
          .subscribe(v => this.store.dispatch(fromStore.AddMeasure(
            {
              payload: {
                id: v.data.lastMeasure.id,
                type: v.data.lastMeasure.type,
                timestamp: v.data.lastMeasure.timestamp,
                value: +v.data.lastMeasure.value
              }
            }

          )));
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroySubscribeToLastMeasure$.next();
    this.destroySubscribeToLastMeasure$.complete();
  }

  open() {
    this.dialogService.open(RenameSensorNodeComponent,
      {
        header: `${this.translate.instant('COMMON.CHANGESNODENAME')}`,
        width: '100%',
        baseZIndex: 10000
      });
  }

  isAdmin(): boolean {
    return this.keycloakService.isUserInRole(environment.keycloak.config.adminRole);
  }

  getEventHeader(eventName: string, eventParams?: {
    [key: string]: any;
  }) {
    this.analytics.trackEvent(eventName, eventParams);
  }

}
