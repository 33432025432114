import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbButtonModule, NbCardModule, NbIconModule, NbInputModule } from '@nebular/theme';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { RenameSensorNodeComponent } from './rename-sensor-node.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [RenameSensorNodeComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NbInputModule,
    TooltipModule,
    InputTextModule,
    TranslateModule
  ]
})
export class RenameSensorNodeModule { }
