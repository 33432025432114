import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbButtonModule, NbSelectModule, NbSpinnerModule, NbToastrModule, NbToggleModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserProfileWindowComponent } from './user-profile-window.component';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [UserProfileWindowComponent],
  imports: [
    CommonModule,
    NbButtonModule,
    ReactiveFormsModule,
    FormsModule,
    NbToastrModule,
    NbSpinnerModule,
    NbSelectModule,
    InputTextModule,
    DropdownModule,
    NbToggleModule,
    ConfirmDialogModule,
    TranslateModule
  ],
  exports: [UserProfileWindowComponent]
})
export class UserProfileWindowModule { }
