import {Component} from '@angular/core';

@Component({
  selector: 'pwa-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <pwa-header></pwa-header>
      </nb-layout-header>

      <nb-layout-header *ngIf="!isMobile()" subheader style="position: sticky">
        <pwa-subheader></pwa-subheader>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column [class]="iosClass()">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <pwa-footer></pwa-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  isMobile(): boolean {
    return !(document.body.offsetWidth < 1108);
  }

  iosClass(){
    if(navigator.userAgent.includes('iPhone')){
      return 'iPhone';
    } else if(navigator.userAgent.includes('iPad')){
      return 'iPad';
    } else if(navigator.userAgent.includes('Macintosh')){
      return 'iPadAir';
    }
    return '';
  }
}
