import { createAction, props } from '@ngrx/store';
import { Notification } from '../../models';

interface Dictionary<T> {
  [key: string]: T;
}

export const LOAD_NOTIFICATIONS = '[Notifications] Load Notifications';

export const LOAD_NOTIFICATIONS_FAILED = '[Notifications] Load Notifications Failed';

export const SET_NOTIFICATIONS = '[Notifications] Load Notifications Success';

export const CHANGE_NOTIFICATIONS = '[Notifications] Change Notifications';

export const UPDATE_NOTIFICATIONS = '[Notifications] Update Notifications';

export const CHANGE_LANGUAGE = '[Notifications] Change Language';

export const CHANGE_LANGUAGE_FAILED = '[Notifications] Change Language Failed';

export const SET_LANGUAGE = '[Notifications] Change Language Success';

export const CHANGE_EMAIL_NOTIFICATION = '[Notifications] Change Email Notification';

export const CHANGE_WEB_NOTIFICATION = '[Notifications] Change Web Notification';

export const LoadNotifications = createAction(
  LOAD_NOTIFICATIONS,
  props<{
    payload: {
      username: string,
      language: string;
    }
  }>()
)

export const LoadNotificationsFailed = createAction(
  LOAD_NOTIFICATIONS_FAILED
)

export const SetNotifications = createAction(
  SET_NOTIFICATIONS,
  props<{
    notifications: Notification[],
    templates: Dictionary<string> | null;
  }>()
)

export const ChangeNotifications = createAction(
  CHANGE_NOTIFICATIONS,
  props<{
    notifications: Notification[]
  }>()
)

export const UpdateNotifications = createAction(
  UPDATE_NOTIFICATIONS,
  props<{
    payload: string[]
  }>()
)

export const ChangeLanguage = createAction(
  CHANGE_LANGUAGE,
  props<{
    payload: string
  }>()
)

export const ChangeEmailNotification = createAction(
  CHANGE_EMAIL_NOTIFICATION,
  props<{
    payload: {
      alert: boolean,
      disease: boolean;
    }
  }>()
)

export const ChangeWebNotification = createAction(
  CHANGE_WEB_NOTIFICATION,
  props<{
    payload: {
      alert: boolean,
      disease: boolean;
    }
  }>()
)

export const ChangeLanguageFailed = createAction(
  CHANGE_LANGUAGE_FAILED,
)

export const SetLanguage = createAction(
  SET_LANGUAGE,
  props<{ templates: Dictionary<string> }>()
)
