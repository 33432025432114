import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { environment } from '../../../environments/environment';


// declare const ga: any;

@Injectable()
export class AnalyticsService {
  private enabled: boolean;

  constructor(
    private location: Location,
    private router: Router,
    private analytics: AngularFireAnalytics,
  ) {
    this.enabled = environment.firebaseConfig.enabled;

  }

  trackPageViews() {
    if (this.enabled) {
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
      )
        .subscribe(() => {
          // ga('send', {hitType: 'pageview', page: this.location.path()});
          this.analytics.logEvent('screen_view');
        });
    }
  }

  trackEvent(eventName: string, eventParams?: {
    [key: string]: any;
  }) {
    if (this.enabled) {
      // ga('send', 'event', eventName);
      this.analytics.logEvent(eventName, eventParams);
    }
  }
}
