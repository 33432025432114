<div class="container">
  <div class="row">
    <div class="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 align-self-center">
      {{ 'COMMON.SNODESERIAL' | translate }}:
    </div>
    <div class="col-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
      <input
        type="text"
        pInputText
        placeholder="{{ (sensorNodes$ | async)?.serialNumber }}"
        [style]="{ width: '100%' }"
        disabled
      />
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 align-self-center">
      {{ 'COMMON.SNODENAME' | translate }}:
    </div>
    <div class="col-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8" #renameSensornode>
      <input
        *ngIf="!isGuest()"
        type="text"
        pInputText
        [formControl]="sensorNodeName"
        (keypress)="(sensorNodeName.valid)"
        (keydown.enter)="
          sensorNodeName.invalid || !sensorNodeName.dirty
            ? null
            : updateSensorNode()
        "
        [ngClass]="
          sensorNodeName.invalid && sensorNodeName.touched
            ? 'ng-invalid ng-dirty'
            : ''
        "
        [pTooltip]="sensorNodeName.errors?.maxlength ? tooltipMaxLenght : ''"
        [appendTo]="renameSensornode"
        tooltipPosition="top"
        tooltipEvent="focus"
        [style]="{ width: '100%' }"
      />
      <input
        *ngIf="isGuest()"
        type="text"
        pInputText
        placeholder="{{ sensorNodeName.value }}"
        [style]="{ width: '100%' }"
        readonly
        disabled
      />
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-6 text-center">
      <button
        nbButton
        status="danger"
        (click)="ref.close()"
        (click)="this.sensorNodeName.reset()"
      >
        {{ 'COMMON.CANCELBUTTON' | translate }}
      </button>
    </div>
    <div class="col-6 text-center">
      <!-- If is guest confirm button no have effets-->
      <!-- Add this check if client modify html and remove disabled property -->
      <button
        nbButton
        status="success"
        (click)="!isGuest() ? updateSensorNode() : null"
        (click)="ref.close()"
        [disabled]="sensorNodeName.invalid
        || !sensorNodeName.dirty ||
        (sensorNodeName.value.length < 3)"
      >
        {{ 'COMMON.CONFIRMATIONBUTTON' | translate }}
      </button>
    </div>
  </div>
</div>
