import { Injectable } from '@angular/core';
import * as userActions from '../actions/user.actions';
import * as notificationActions from '../actions/notifications.actions';
import * as sensorNodeActions from '../actions/sensorNodes.actions';
import { GraphqlService } from '../../services/graphql.service';
import { switchMap, tap } from 'rxjs/operators';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { User } from '../../models';

@Injectable()
export class UserEffects {

  constructor(
    private actions$: Actions,
    private store: Store,
    private graphqlService: GraphqlService,
    private logger: NGXLogger
  ) { }

  loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.LoadUser),
      switchMap((payload) =>
        this.graphqlService.fetchUser(
          {
            username: payload.username
          },
          {
            fetchPolicy: "no-cache" // no need to use the cache the query will always be exectued 1 time only
          }
        )
      ),
      switchMap((res:any) => {
        this.logger.debug("User", res.data.user);
        return [
          userActions.SetUser({
            user: {
              ...res.data.user,
              profiling: {
                ...res.data.user.profiling,
                language: res.data.user?.profiling?.language != "" ? res.data.user?.profiling?.language : "en"
              }
            },
          }),
          sensorNodeActions.LoadSensorNodes(
            {
              sensorNodes: res.data.user.sensorNodes,
              index: res.data.user?.profiling?.sensorNode != ""
                ? res.data.user.sensorNodes.map(sensorNode => sensorNode.serialNumber).indexOf(res.data.user.profiling.sensorNode)
                : 0
            }
          ),
          notificationActions.LoadNotifications({
            payload: {
              username: res.data.user.username,
              language: res.data.user?.profiling?.language != "" ? res.data.user?.profiling?.language : "en"
            }
          })
        ];
      })
    )
  });

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.UpdateUser),
      tap(c => this.logger.debug("updateUser$", c)),
      switchMap((payload:any) =>
        [
          notificationActions.ChangeLanguage({ payload: payload?.profiling?.language }),
          notificationActions.ChangeEmailNotification({ payload: payload?.profiling?.emailNotification }),
          notificationActions.ChangeWebNotification({ payload: payload?.profiling?.webNotification })
        ]
      )
    )
  });


}
