import { Sensor, SensorExtended } from "./sensor";
// import { SensorEntityState } from '../store/reducers/sensors.reducer'

export interface SensorNode {
  id?: string;
  serialNumber: string;
  name: string;
  sensors?: Sensor[];
  diagnosticSensors?: Sensor[];
  deleted: boolean;
  positions?: Position;
  timezone?: string;
  diseases?: string[];
};

// interface LatLong {
//   lat: string,
//   long: string;
// }
export interface Position {
  lat?: string,
  long?: string,
  timezone?: string;
}

// type SensorNodeOmit = Omit<SensorNode, 'sensors'>;

// export interface ChangeSelectedSensorNodePayload extends SensorNodeOmit {
//   sensors: SensorExtended[]
// }


// export interface SelectedSensorNode extends SensorNodeOmit {
//   sensorEntity: SensorEntityState;
// }



