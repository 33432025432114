import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../app.state';
import { User } from '../../models';
import * as fromUser from '../reducers/user.reducer';


export const selectUserState = createFeatureSelector<fromUser.UserState>('user');

export const selectUser = createSelector(
  selectUserState,
  state => state
);

export const selectUserUsername = createSelector(
  selectUserState,
  state => state?.username
);

export const selectUserName = createSelector(
  selectUserState,
  state => state?.name
);

export const selectUserOrganizationName = createSelector(
  selectUserState,
  state => state?.organization?.name
);

export const selectUserOrganizationEid = createSelector(
  selectUserState,
  state => state?.organization?.eid
);

export const selectUserThresholds = createSelector(
  selectUserState,
  state => state?.thresholds != null ? state?.thresholds : []
);

export const selectUserProfileImage = createSelector(
  selectUserState,
  state =>
    state?.profiling?.profileImage != ""
      ? state?.profiling?.profileImage
      // base64 of logoriflesso.png
      : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QoDDwUu4zRrfwAAAf1JREFUWMPtlj1v00AYgJ9L7DpfDflQ2kYihSpVCyIMDJVYupeBBTHB0IGVP8CPYEPqgoTECl2YWMrGAqJDCQGB1BSahBBC0mAnjRPTYyqgmtDYUmUGn3S60/ne933O78edkFJKPGwBPG4+gA/gA3gKIKVEcSLQNeu0jSLtbgm9/5HhDwMATU0SD82RiJ4nHbuIpibH0ieEGA+gP9yj2tqg3HhEQ39Bb1BHSon4rQkhgkS102RPLZOfus50Yhk1GD4e4rhSPLB0StU13tUeYJg745yLVLTAYvYWC9lV1GDIPcDA6lCsrPGmcg/T+ubIt7HQLJfO3GF++gZKUHMXhJXWBh/qDx0ZP/Rt19zlbe0+Tf2luyww+p/Zbjzm+37ZdZS3jNfsfH2CZfWcA3zpPKfWfoYQIwSFiqakmVBSI9VIhnxqPaVpbI0EGJkFTX0T09pDHCGQUpKInGMuc41U7AIH0qKpv2K7sU7XrNr26/0yTX2TmcRlZwDtbtGmDCA8kaGQu83izCqBgArA2fRVlECMrd27HMjBEWCLdrfkIgbMCkhsPRkpkEut/DIOoCgRZtNXiIfzf5XpDarOXZBLrZCZXLKtZ+JLRLWsbX0ynGd+6iad/fe2b4nIgvtC5N+GJ30hKl4Y/XP08g+If2bBSRs+nPtvQh/gvwDwtBT/BBwGwDK3OFIIAAAAAElFTkSuQmCC"
);

export const selectUserLanguage = createSelector(
  selectUserState,
  state => state?.profiling?.language
);
