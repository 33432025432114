import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbPopoverDirective,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';
import { KeycloakService } from 'keycloak-angular';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, Subject, of } from 'rxjs';
import {
  bufferTime,
  catchError,
  filter,
  first,
  map,
  mergeMap,
  skipWhile,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { UserData } from '../../../@core/data/users';
import { AnalyticsService } from '../../../@core/utils';
import { ChangePasswordWindowComponent } from '../../../evja/components/change-password-window/change-password-window.component';
import { UserProfileWindowComponent } from '../../../evja/components/user-profile-window/user-profile-window.component';
import { DateWithTimezoneApplied } from '../../../evja/utils/date';
import { BatteryStatus } from '../../../models/batteryStatus';
import { GraphqlService } from '../../../services/graphql.service';
import * as fromStore from '../../../store';

@Component({
  selector: 'pwa-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  providers: [DialogService],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild(NbPopoverDirective) notificationsTemplate!: NbPopoverDirective;

  //check window resize to show / hide username on menu for mobile view
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 768) {
      if (this.userMenu[0]?.data !== 'USERDATA') {
        this.userMenu.unshift({
          title: this.usernameForMobile,
          icon: {
            icon: 'user',
            pack: 'fa',
          },
          data: 'USERDATA',
        });
      }
    } else {
      if (this.userMenu[0]?.data === 'USERDATA') {
        this.userMenu.shift();
      }
    }
  }

  hideMenuOnClick: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();
  destroySubscribeToLastStatus$: Subject<void> = new Subject<void>();
  userPictureOnly = true;
  user: any;
  name!: Observable<string>; //user name
  organizationName!: Observable<string>;
  picture: Observable<string> | undefined;
  notificationsNotCompleted: number = 0;
  lastMeasureTimestamp: string = '';
  batteryStatus!: BatteryStatus;
  lastTimestampBattery: string = '';

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];
  userMenu: NbMenuItem[] = [
    {
      title: 'Preferences',
      icon: {
        icon: 'sliders-h',
        pack: 'fa',
      },
      data: 'PREFERENCES',
    },
    {
      title: 'Change Password',
      icon: {
        icon: 'unlock-alt',
        pack: 'fa',
      },
      data: 'CHANGEPASSWORD',
    },
    {
      title: 'Contact us',
      icon: {
        icon: 'phone',
        pack: 'fa',
      },
      data: 'CONTACTUS',
      expanded: false,
      children: [
        {
          title: 'Whatsapp',
          icon: {
            icon: 'whatsapp-brands_mod',
            pack: 'evjaicon',
          },
          url: environment.whatsappEvja,
        },
        {
          title: 'Email',
          icon: {
            icon: 'envelope',
            pack: 'fa',
          },
          url: 'mailto:' + environment.emailEvja,
        },
      ],
    },
    {
      title: 'Log out',
      icon: {
        icon: 'sign-out-alt',
        pack: 'fa',
      },
      data: 'LOGOUT',
    },
  ];

  currentTheme = 'default';
  usernameForMobile: any;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private breakpointService: NbMediaBreakpointsService,
    private keycloakService: KeycloakService,
    private apollo: Apollo,
    public translate: TranslateService,
    private store: Store,
    // private windowService: NbWindowService
    private windowService: DialogService,
    private analytics: AnalyticsService,
    private graphqlService: GraphqlService,
  ) {}

  ngOnInit() {
    this.store
      .select(fromStore.selectCurrentSensorNodeSerialNumber)
      .pipe(
        skipWhile((serial) => serial == null),
        switchMap((sensorNodeSerial) =>
          this.graphqlService
            .subscribeToLastStatus({
              sensorNodeSerial: sensorNodeSerial,
            })
            .pipe(
              takeUntil(this.destroySubscribeToLastStatus$),
              // la subscription emette un valore alla volta, utilizzato il bufferTime che, ogni x millisecondi
              // controlla se sul buffer è stato emesso qualcosa e li aggrega, utile per le logiche di eccezione
              bufferTime(environment.bufferTimeRefreshMS),
              filter((data) => data?.length > 0),
            ),
        ),
      )
      .subscribe((result) => {
        if (result?.length > 0) {
          let statusCheck = {};
          result
            .filter((r) => !!r?.data?.lastStatus)
            .map((r) => r.data.lastStatus)
            .forEach((item) => {
              // create a object to analyze es. {"timestamp":"202XX", "xa0":"..", "xb0":".."}
              if (item.timestamp) {
                this.lastTimestampBattery = DateWithTimezoneApplied(
                  item.timestamp,
                  this.translate.currentLang,
                );
                if (this.lastTimestampBattery == 'Invalid DateTime') {
                  this.lastTimestampBattery = '';
                }
              }
              statusCheck[item.type] = item.value;
            });
          this.batteryStatus = {
            level:
              parseInt(statusCheck[environment.batteryLevelSensor] || '0') || 0,
            charging: statusCheck[environment.batteryInChargeSensor] === 'Y',
            date: this.lastTimestampBattery,
          };
        }
      });

    this.store
      .select(fromStore.selectCurrentSensorNodeSerialNumber)
      .pipe(
        skipWhile((serial) => serial == null),
        switchMap((sensorNodeSerial) =>
          this.graphqlService
            .fetchStatus(
              {
                input: {
                  sensorNodeSerial: sensorNodeSerial,
                  sensors: [
                    environment.batteryInChargeSensor,
                    environment.batteryLevelSensor,
                  ],
                  aggregateByTimestamp: true,
                  limit: 2,
                },
              },
              {
                fetchPolicy: 'no-cache',
              },
            )
            .pipe(
              map((result) => {
                if (result?.data?.status?.data?.length > 0) {
                  return {
                    batteryLevel:
                      result.data.status.data[0][
                        environment.batteryLevelSensor
                      ] || '0',
                    batteryInCharge:
                      result.data.status.data[0][
                        environment.batteryInChargeSensor
                      ] || 'N',
                    timestamp: result.data.status.data[0]['timestamp'] || '',
                  };
                } else {
                  return {
                    batteryLevel: '0',
                    batteryInCharge: 'N',
                    timestamp: '',
                  };
                }
              }),
              catchError((err) => {
                console.error(err);
                return of({
                  batteryInCharge: 'N',
                  batteryLevel: '0',
                  timestamp: '',
                });
              }),
            ),
        ),
      )
      .subscribe((result) => {
        this.lastTimestampBattery = DateWithTimezoneApplied(
          result?.timestamp,
          this.translate.currentLang,
        );
        if (this.lastTimestampBattery == 'Invalid DateTime') {
          this.lastTimestampBattery = '';
        }
        this.batteryStatus = {
          level: parseInt(result?.batteryLevel || '0') || 0,
          charging: result?.batteryInCharge === 'Y',
          date: this.lastTimestampBattery,
        };
      });

    //LastDetection
    this.store
      .select(fromStore.selectCurrentLastMeasureTimestamp)
      .pipe(takeUntil(this.destroy$))
      .subscribe((lastMeas) => {
        this.lastMeasureTimestamp =
          DateWithTimezoneApplied(lastMeas, this.translate.currentLang) ==
          'Invalid DateTime'
            ? '--'
            : DateWithTimezoneApplied(lastMeas, this.translate.currentLang);
      });

    // observable for user name on header
    this.name = this.store.select(fromStore.selectUserName);

    this.name.pipe(filter((value) => !!value)).subscribe((user) => {
      this.usernameForMobile = user;

      if (window.innerWidth < 768) {
        this.userMenu.unshift({
          title: user,
          icon: {
            icon: 'user',
            pack: 'fa',
          },
          data: 'USERDATA',
        });
      }
    });

    // translating userMenu on lang change
    this.translate.onLangChange.subscribe((_) => {
      this.userMenu.forEach((item) => this.getTranslation(item, item.data));
      this.getUserNotification();
    });

    // observable for user title (organization name) on header
    this.organizationName = this.store.select(
      fromStore.selectUserOrganizationName,
    );

    // observable for user profileImage
    this.picture = this.store.select(fromStore.selectUserProfileImage);

    // notification badge
    this.store
      .select(fromStore.selectCurrentNotCompleted)
      .subscribe((notCompleted) => {
        this.notificationsNotCompleted = notCompleted;
      });

    this.currentTheme = this.themeService.currentTheme;

    this.userService
      .getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => (this.user = users.nick));

    const { md } = this.breakpointService.getBreakpointsMap();
    const { is } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint),
        takeUntil(this.destroy$),
      )
      .subscribe((currentBreakpoint) => {
        this.userPictureOnly = currentBreakpoint.width < md;
        this.hideMenuOnClick = currentBreakpoint.width <= is;
      });

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe((themeName) => (this.currentTheme = themeName));

    // listening on userMenu interaction
    this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { data } }) => data),
        takeUntil(this.destroy$),
      )
      .subscribe((data) => {
        switch (data) {
          case 'PREFERENCES':
            this.analytics.trackEvent(
              'HeaderComponent.ngOnInit.ClickPreference',
              { type: 'menu_item' },
            );

            //AGGIUNTA LA CHIAMATA ALL'APERTURA PER EVITARE DATI NON SINCRONIZZATI TRA DISPOSITIVI LOGGATI CONTEMPORANEAMENTE
            this.graphqlService
              .fetchUser(
                {
                  username: this.keycloakService.getUsername(),
                },
                {
                  fetchPolicy: 'no-cache', // no need to use the cache the query will always be exectued 1 time only
                },
              )
              .pipe(
                mergeMap((res: any) => {
                  fromStore.SetUser({
                    user: {
                      ...res.data.user,
                      profiling: {
                        ...res.data.user.profiling,
                        language:
                          res.data.user?.profiling?.language != ''
                            ? res.data.user?.profiling?.language
                            : 'en',
                      },
                    },
                  }),
                    fromStore.LoadSensorNodes({
                      index: res.data.user.sensorNodes,
                      sensorNodes:
                        res.data.user?.profiling?.sensorNode != ''
                          ? res.data.user.sensorNodes
                              .map((sensorNode) => sensorNode.serialNumber)
                              .indexOf(res.data.user.profiling.sensorNode)
                          : 0,
                    }),
                    fromStore.LoadNotifications({
                      payload: {
                        username: res.data.user.username,
                        language:
                          res.data.user?.profiling?.language != ''
                            ? res.data.user?.profiling?.language
                            : 'en',
                      },
                    });
                  return of(res);
                }),
              )
              .subscribe((res) => {
                this.windowService.open(UserProfileWindowComponent, {
                  header: this.translate.instant('COMMON.PREFERENCES'),
                  data: { user: res.data.user },
                  baseZIndex: 10000,
                  width: '75vw',
                });
              });

            // this.store
            //   .select(fromStore.selectUser)
            //   .pipe(
            //     mergeMap((v) =>
            //       this.translate
            //         .get('USERPROFILEWINDOW')
            //         .pipe(map((r) => ({ user: v, translation: r })))
            //     ),
            //     first()
            //   )
            //   .subscribe((v) => {

            //     this.windowService.open(UserProfileWindowComponent, {
            //       header: v.translation['title'],
            //       data: { ...v },
            //       baseZIndex: 10000,
            //       width: '75vw',
            //     })
            //   });
            break;
          case 'CHANGEPASSWORD':
            this.analytics.trackEvent(
              'HeaderComponent.ngOnInit.ClickChangePassword',
              { type: 'menu_item' },
            );
            this.store.select(fromStore.selectUserUsername).subscribe((v) =>
              this.windowService.open(ChangePasswordWindowComponent, {
                header: this.translate.instant('CHANGEPASSWORDWINDOW.TITLE'),
                data: v,
                baseZIndex: 10000,
              }),
            );
            break;
          case 'CONTACTUS':
            this.analytics.trackEvent(
              'HeaderComponent.ngOnInit.ClickContactUs',
              { type: 'menu_item' },
            );
            break;
          case 'LOGOUT':
            this.analytics.trackEvent('HeaderComponent.ngOnInit.ClickLogOut', {
              type: 'menu_item',
            });
            this.apollo.client.resetStore();
            this.keycloakService.logout();
            break;
          default:
          //
        }
      });

    this.menuService.onItemSelect().subscribe((submenu) => {
      if (submenu.item.title == 'Whatsapp') {
        this.analytics.trackEvent('HeaderComponent.ngOnInit.ClickWhatsapp', {
          type: 'menu_item',
        });
      } else if (submenu.item.title == 'Email') {
        this.analytics.trackEvent('HeaderComponent.ngOnInit.ClickEmail', {
          type: 'menu_item',
        });
      }
    });

    this.menuService.onItemClick().subscribe(() => {
      if (this.hideMenuOnClick) {
        this.sidebarService.collapse('menu-sidebar');
      }
    });

    // .subscribe(title => this.sicknDestroy(title));
  }

  //get notification

  getUserNotification() {
    this.store.dispatch(
      fromStore.LoadNotifications({
        payload: {
          username: this.keycloakService.getUsername(),
          language: this.translate.currentLang,
        },
      }),
    );
  }

  getTranslation(item: NbMenuItem, key: string) {
    if (key !== 'USERDATA') {
      const k = `HEADERMENU.${key}`;
      this.translate
        .get(k)
        .pipe(first())
        .subscribe((translation: string) => {
          item.title = translation;
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroySubscribeToLastStatus$.next();
    this.destroySubscribeToLastStatus$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.analytics.trackEvent('HeaderComponent.toggleSidebar', {
      type: 'button',
    });
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
  }

  destroyNotificationsPopoverComponent(): void {
    this.notificationsTemplate.hide();
  }

  isMobile(): boolean {
    return !(document.body.offsetWidth < 1108);
  }

  getEventHeader(
    eventName: string,
    eventParams?: {
      [key: string]: any;
    },
  ) {
    this.analytics.trackEvent(eventName, eventParams);
  }
}