import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CanAuthenticationGuard } from './services/can-authentication-guard.guard';
import { environment } from '../environments/environment';
import { RequiredRole } from './services/required-role';

export let routes: Routes = [];

export function noAuthRoute(): void {
  routes.push(
    {
      path: 'pages',
      loadChildren: () => import('./pages/pages.module')
        .then(m => m.PagesModule),
    },
  );
  return;
}

function pushKeycloakRoute(): void {
  routes.push(
    {
      path: 'pages',
      loadChildren: () => import('./pages/pages.module')
        .then(m => m.PagesModule),
      canActivate: [CanAuthenticationGuard],
      data: {
        roles: [
          { client: environment.keycloak.config.clientId, role: environment.keycloak.config.userRole },
          { client: 'account', role: 'view-profile' },
        ] as RequiredRole[]
      }
    }
  );

  return;
}

if (environment.keycloak.enabled) {
  pushKeycloakRoute()
} else {
  noAuthRoute()
}

routes.push(
  {
    path: 'error',
    loadChildren: () => import('./pages/error-page/error-page.module').then(m => m.ErrorPageModule)
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' }
)

const config: any = {
  useHash: false,
  relativeLinkResolution: 'legacy'
}

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
