import { DateRange, DateTimeRange } from "../../models";
import { DateTime, Zone } from 'luxon';


export const MS_IN_A_DAY: number = 86400000; // 24 * 60 * 60 * 1000
export const FOUR_HOURS_MS = 14400000;
export const THIRTY_DAYS_MS = 2592000000;
export const THIRTY_ONE_DAYS_MS = 2678400000;
export const TWO_MONTH_MS = 5259600000;
export const THREE_MONTH_MS = 7884000000;
export const ONE_YEAR = 36720000000;
export const FIVE_YEARS = 157788000000;
export const SIX_YEAR = 189216000000;
export const MILLISECONDS_IN_ONE_MINUTE = 60000;

export const DefaultDatePickerYearRange: [string, string] = [
  '2017',
  (DateTime.now().year + 1).toString()
];

export const FormattedPickerYearRange = `${DefaultDatePickerYearRange[0]}:${DefaultDatePickerYearRange[1]}`;

export function getDifferenceFromTwoDate(date?:DateRange) {
  return DateTime.fromJSDate(date.end).diff(DateTime.fromJSDate(date.start), 'days').toObject();
}

export function DefaultDateRange(): DateRange {
  return {
    start: DateTime.now().set({ second: 0, millisecond: 0 }).minus({ days: 1 }).toJSDate(),
    end:  DateTime.now().set({ second: 0, millisecond: 0 }).toJSDate()
  }
}

export function WeekDateRange(): DateRange {
  return {
    start: DateTime.now().minus({ days: 7 }).set({ second: 0, millisecond: 0 }).toJSDate(),
    end: DateTime.now().set({ second: 0, millisecond: 0 }).toJSDate()
  }
}

export function DefaultDateTimeRange(): DateTimeRange {
  return {
    start: DateTime.now().set({ second: 0, millisecond: 0 }).minus({ days: 1 }),
    end: DateTime.now().set({ second: 0, millisecond: 0 })
  }
}

export function WeekDateTimeRange(): DateTimeRange {
  return {
    start: DateTime.now().minus({ days: 7 }).set({ second: 0, millisecond: 0 }),
    end: DateTime.now().set({ second: 0, millisecond: 0 })
  }
}

// use this for all timestamp in application
export function DateWithTimezoneApplied(date: string, locale: string = 'en-GB', formattingOptions: Intl.DateTimeFormatOptions = DateTime.DATETIME_SHORT_WITH_SECONDS) {
  return DateTime.fromISO(
    date,
    {
      setZone: true,
    }
  )
  // Defaults to en-GB locale which is formatted as dd/mm/yyyy HH:mm:ss
  // https://evja.atlassian.net/browse/EBUG-65
    .setLocale('en-GB')
    .toLocaleString(formattingOptions);
}

// timestam in format HH:mm:ss, dd/MM/yyyy ZZZZ -> 12:45:00, 17/11/2020 UTC+1
export function notificationFormatTimestamp(date: string): string {
  return DateTime.fromISO(date, { setZone: true }).toFormat("HH:mm:ss, dd/MM/yyyy ('UTC'ZZ)");
}

export function is24HoursOrWeek(diffInDays: number) {
  return (diffInDays === 1 || diffInDays === 7 ) ? true : false;
}

export function applyLocalTimeWithUTCOffsetIso(date: Date ) {
  return DateTime.fromJSDate(date).setLocale('en-GB').toISO({includeOffset: true});
}

export function manageTimezones(dateRange: DateRange, timezone: string | Zone ) {
  const differenceBetweenDateInDays = DateTime.fromMillis(dateRange.end.valueOf()).diff(DateTime.fromMillis(dateRange.start.valueOf()), 'days').days;
  const shouldApplyTimezone = is24HoursOrWeek(differenceBetweenDateInDays);

  let startDate: string;
  let endDate: string;

  if (shouldApplyTimezone) {
    startDate = applyLocalTimeWithUTCOffsetIso(dateRange.start);
    endDate = applyLocalTimeWithUTCOffsetIso(dateRange.end);
  } else {
    startDate = getDateOfSpecificTzIso(dateRange.start, timezone);
    endDate = getDateOfSpecificTzIso(dateRange.end, timezone);
  }

  return {
    startDate, endDate
  };
}

export function getDateOfSpecificTzIso(date: Date, zone: Zone | string = 'UTC', dateOnly = false): string {
  return DateTime.fromJSDate(date).setZone(zone, {keepLocalTime: true}).setLocale('en-GB').toISO({includeOffset: true});

}
export function getDateOfSpecificTzJsDate(date: Date, zone = 'UTC', dateOnly = false): Date {
  return getDateOfSpecificTz(date, zone, dateOnly).setLocale('en-GB').toJSDate();
}

export function getDateToSpecificFormatString(date: DateTime | Date | string  , dateFormat = 'yyyy-MM-dd\'T\'HH:mm:ssZZ'): string {
  let dt: DateTime;
  if (date instanceof DateTime) {
    dt = date

  } else if (date instanceof Date) {
    dt = DateTime.fromJSDate(date)

  } else if (typeof(date) === 'string') {
    // Usually when its a string it means its already formatted well
    return date;

  } else {
    throw new Error("Date must be of type String | Date | DateTime");
  }

  //const dt = date instanceof DateTime ? date : DateTime.fromJSDate(date);
  return dt.toFormat(dateFormat);
}


function getDateOfSpecificTz(date: Date, zone: Zone | string = 'UTC', dateOnly = false) {
  const dateToReturn = DateTime.now().setZone(zone, {keepLocalTime: true});

  if (!dateToReturn.isValid) {
    throw new Error('Timezone not valid. ' + JSON.stringify({date, zone}));
  }

  return dateToReturn.set({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    hour: dateOnly ? 0 : date.getHours(),
    minute: dateOnly ? 0 : date.getMinutes(),
    second: dateOnly ? 0 : date.getSeconds(),
    millisecond: dateOnly ? 0 : date.getMilliseconds()
  });
}

// https://stackoverflow.com/a/66473876
export function getTimezoneOffsetFrom(timezone: string): number {
  if (timezone === void 0) { timezone = "UTC"; }
  let date = new Date();
  let objFromStr = (str: string) => {
    let array = str.replace(":", " ").split(" ");
    return {
      day: parseInt(array[0]),
      hour: parseInt(array[1]),
      minute: parseInt(array[2])
    };
  };
  let str = date.toLocaleString(['nl-NL'], { timeZone: timezone, day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false });
  let other = objFromStr(str);
  str = date.toLocaleString(['nl-NL'], { day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false });
  let myLocale = objFromStr(str);
  let amsterdamOffset = (other.day * 24 * 60) + (other.hour * 60) + (other.minute);
  let myLocaleOffset = (myLocale.day * 24 * 60) + (myLocale.hour * 60) + (myLocale.minute);
  return myLocaleOffset - amsterdamOffset + date.getTimezoneOffset();

}

// Returns the dateRange for calendars
export function getLocaleDateString(): string {
  return "dd/mm/yy";
}

export function removeTimezoneOffsetFrom(date: Date): Date {
  const offset = -date.getTimezoneOffset() * MILLISECONDS_IN_ONE_MINUTE; // get the timezone offset in milliseconds
  const time = date.getTime() + offset; // remove the timezone offset
  return new Date(time);
}
