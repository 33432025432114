import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { MapComponent } from './map.component';





@NgModule({
  declarations: [MapComponent],
  imports: [
    CommonModule,
    LeafletModule,
    AccordionModule,
    TranslateModule.forChild(),
  ],
  exports: [MapComponent]
})
export class MapModule { }
