import { Injectable } from '@angular/core';
import PouchDB from 'pouchdb';
import PouchDBAuthentication from 'pouchdb-authentication';
import { BehaviorSubject, forkJoin, from, Observable, of } from 'rxjs';
import { map, switchMap } from "rxjs/operators";
import { environment } from '../../environments/environment';
import { Notification } from '../models/notification';

PouchDB.plugin(PouchDBAuthentication);

interface PageDocument {
  notifications: Notification[]
}

type TemplatesDocument = Record<string, Record<string, string>>;

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private couchDB: PouchDB.Database;

  constructor() {
    const c = environment.couchDB;
    const credPart = c.useUriWithCredentials ? (c.username + ':' + c.password + '@') : '';
    const portPart = c.port ? (':' + c.port) : '';
    const uriWithCred: string = `${environment.protocol}://${credPart}${c.host}${portPart}/${c.databases.notifications}`;
    const uri: string = `${environment.protocol}://${c.host}${portPart}/${c.databases.notifications}`;

    this.couchDB = c.useUriWithCredentials ?
      new PouchDB(uriWithCred) :
      new PouchDB(uri, {
        fetch: (url, opts) => {
          opts.credentials = 'include';
          return PouchDB.fetch(url, opts);
        }
      });
  }


  login(): Observable<PouchDB.Authentication.LoginResponse> {
    // noinspection JSVoidFunctionReturnValueUsed
    return from(this.couchDB.logIn(
      environment.couchDB.username,
      environment.couchDB.password,
      {
        fetch: null
      }
    ));
  }

  fetchTemplates() {
    return from(this.couchDB.get<TemplatesDocument>('templates'));
  }

  fetchNotifications(username: string , selectedSensorNodeSerialNumber: string) {
    return from(this.couchDB.get<PageDocument>(`${username}/${selectedSensorNodeSerialNumber}`))
      .pipe(
        switchMap(masterDoc => {

          const observables: Observable<Notification[]>[] = [];

          observables.push(of(masterDoc.notifications));

          return forkJoin(observables).pipe(
            map((pages) => {
              return ([] as Notification[]).concat(...pages);
            }),
            map((notifications) => this.sortNotifications(notifications))
          );
        })
      );
  }

  private sortNotifications(notifications: Notification[]) {
    return notifications.sort((a, b) => {
      return a.timestamp > b.timestamp ? -1 : 1;
    });
  }


  updateNotification(newNotifications: any[], ids: string[]) {

    let docId = `${newNotifications[0].username}/${newNotifications[0].sensor_node_serial}`;

    this.couchDB.get<PageDocument>(docId).then((dbNotifications) => {
      dbNotifications.notifications = dbNotifications.notifications.map((dbNotification) => {
            const updatedNotification = newNotifications.find((n) => n.id === dbNotification.id);
            if (updatedNotification) {
              return {...dbNotification, completed: updatedNotification.completed};
            } else {
              return dbNotification;
            }
          });
          return this.couchDB.put(dbNotifications);
    });
  }

  private currentNotifications = new BehaviorSubject<Notification[]>([]);
  private latestUsernameSubscribed = '';
  private masterSubscription: PouchDB.Core.Changes<PageDocument>;

  subscribeToChanges(username: string,selectedSensorNodeSerialNumber:string): Observable<Notification[]> {
    if (this.latestUsernameSubscribed !== username) {
      this.latestUsernameSubscribed = username;
      if (this.masterSubscription) {
        this.masterSubscription.cancel();
      }

      this.masterSubscription = this.couchDB.changes<PageDocument>({
        doc_ids: [`${username}/${selectedSensorNodeSerialNumber}`],
        since: 'now',
        live: true,
        include_docs: true,
      });

      this.masterSubscription.on('change', (masterChange:any) => {
        if (masterChange.changes) {
          let notifications: Notification[] = [];
          notifications = masterChange.doc.notifications;
          this.currentNotifications.next(notifications);
        }
      });
    }

    return this.currentNotifications.asObservable();
  }

}

