import { createEntityAdapter, EntityState, EntityAdapter } from '@ngrx/entity';
import { Dictionary, Notification } from '../../models';
import * as notificationActions from '../actions/notifications.actions';
import { createReducer, on } from '@ngrx/store';

export interface NotificationEntityState extends EntityState<Notification> {
  _id: string | null;
  _rev: string | null;
  notCompleted: number;
  templates: Dictionary<string> | null;
};

const notificationEntityAdapter: EntityAdapter<Notification> = createEntityAdapter<Notification>({
  selectId: (notification: Notification) => notification.id,
});

const notificationEntityInitialState: NotificationEntityState = notificationEntityAdapter.getInitialState({
  _id: null,
  _rev: null,
  notCompleted: 0,
  templates: null
});

export const notificationReducer = createReducer(
  notificationEntityInitialState,
  on(notificationActions.LoadNotifications, (state => (state))),
  on(notificationActions.LoadNotificationsFailed, (state => (notificationEntityAdapter.setAll([], state)))),
  on(notificationActions.SetNotifications, ((state, { notifications, templates }) => (
    notificationEntityAdapter.setAll(
      notifications,
      {
        ...state,
        notCompleted: notifications.filter(n => n.completed === false).length,
        templates: templates
      }
    )
  ))),
  on(notificationActions.ChangeNotifications, ((state, { notifications }) => (
    notificationEntityAdapter.setAll(
      notifications,
      {
        ...state,
        notCompleted: notifications.filter(v => v.completed === false).length
      }
    )
  ))),
  on(notificationActions.UpdateNotifications, ((state, { payload }) => (
    notificationEntityAdapter.updateMany(
      payload.map(id => {
        return {
          id: id,
          changes: {
            completed: true
          }
        };
      }),
      {
        ...state,
        notCompleted: getNotCompletedFromState(state)
      }
    )
  ))),
  on(notificationActions.ChangeLanguage, (state => (state))),
  on(notificationActions.ChangeLanguageFailed, (state => (state))),
  on(notificationActions.SetLanguage, ((state, { templates }) => ({ ...state, templates: templates })))
)

function getNotCompletedFromState(state: NotificationEntityState) {
  let notCompleted = 0;
  if (state.entities) {
    for (const key in state.entities) {
      if (state.entities.hasOwnProperty(key)) {
        if (!state.entities[key].completed) {
          notCompleted++;
        }
      }
    }
    return notCompleted;
  }
  return notCompleted;
}

export const getId = (state: NotificationEntityState) => state._id;

export const getRev = (state: NotificationEntityState) => state._rev;

export const getTemplates = (state: NotificationEntityState) => state.templates;

export const getNotCompleted = (state: NotificationEntityState) => state.notCompleted;

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = notificationEntityAdapter.getSelectors();

export const notificationEntitySelectIds = selectIds;

export const notificationEntitySelectEntities = selectEntities;

export const notificationEntitySelectAll = selectAll;

export const notificationEntitySelectTotal = selectTotal;
