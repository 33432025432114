/* eslint-disable @ngrx/on-function-explicit-return-type */
import { createReducer, on } from '@ngrx/store';
import * as UserActions from '../actions/user.actions';
import * as fromUser from '../reducers/user.reducer';
import { User } from '../../models';

export interface UserState extends User { }

export const UserInitialState: Readonly<UserState> = {
  id: "",
  username: "",
  name: "",
  organization: undefined,
  sensorNodes: [],
  thresholds: [],
  active: true,
  deleted: false,
  profiling: {
    profileImage: "",
    decimalDigits: 3,
    sensorNode: undefined,
    language: undefined
  }
};

export const userReducer = createReducer(
  UserInitialState,
  on(UserActions.LoadUser, state => ({ ...state})),
  on(UserActions.SetUser, (state , { user }) => (user)),
  on(UserActions.UpdateUser, (state , { user }) => (user)),
)
