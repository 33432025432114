/* eslint-disable @ngrx/on-function-explicit-return-type */
import { createEntityAdapter, EntityState, EntityAdapter } from '@ngrx/entity';
import { SensorExtended } from '../../models';
import * as sensorActions from '../actions/sensors.actions';
import { getCardStatus, buildChartSeries, addMeasureToLast4Measures, AddMeasureToLast24hMeasures } from '../../evja/utils/store';
import { createReducer, on } from '@ngrx/store';

export interface SensorEntityState extends EntityState<SensorExtended> {
  lastMeasureTimestamp: string;
};

const sensorEntityAdapter: EntityAdapter<SensorExtended> = createEntityAdapter<SensorExtended>({
  selectId: (sensor: SensorExtended) => sensor.type,
});

const sensorEntityInitialState: SensorEntityState = sensorEntityAdapter.getInitialState({
  lastMeasureTimestamp: "",
});

export const sensorReducer = createReducer(
  sensorEntityInitialState,
  on(sensorActions.SetSensors, (state, { sensors }) => (sensorEntityAdapter.setAll(sensors, { ...state }))),
  on(sensorActions.LoadSensorsData, state => ({ ...state, lastMeasureTimestamp: "" })),
  on(sensorActions.SetSensorsData, (state, { payload }) => {
    let lastMeasureTimestamp = payload?.reduce((a: any, b: any) => [...a, ...b.measures], []).map(m => m.timestamp).reduce((a, b) => b > a ? b : a, "");
    return payload?.length > 0
      ? sensorEntityAdapter.updateMany(
        payload?.map((item: any) => (
          {
            id: item.sensor.type,
            changes: {
              loading: false,
              cardStatus: getCardStatus(item.measures.slice().reverse(), item.threshold),
              threshold: item.threshold,
              lastMeasure: item.measures[0] != null ? item.measures[0] : null,
              last4Measures: item.measures.slice().reverse(),
              last4MeasuresChartSeries: buildChartSeries(false, item.sensor, item.measures.slice().reverse(), item.threshold)
            }
          }
        )),
        {
          ...state,
          lastMeasureTimestamp: state.lastMeasureTimestamp < lastMeasureTimestamp ? lastMeasureTimestamp : state.lastMeasureTimestamp
        }
      )
      :
      ({ ...state, lastMeasureTimestamp: "" })
  }),
  on(sensorActions.LoadLast48hMeasures, (state) => state),
  on(sensorActions.SetLast48hMeasures, (state, { id, last48hMeasures, type }) => (
    sensorEntityAdapter.updateOne(
      {
        id: id,
        changes: {
          last24hMeasures: {
            loaded: true,
            measures: (last48hMeasures !== undefined ? last48hMeasures : [])
          },
        }
      },
      { ...state }))
  ),
  on(sensorActions.UpdateThreshold, (state, { payload }) =>
  (
    sensorEntityAdapter.updateOne(
      {
        id: payload.type,
        changes: {
          cardStatus: getCardStatus(state.entities[payload.type]?.last4Measures!, payload),
          threshold: payload,
        }
      },
      state
    ))
  ),
  on(sensorActions.AddMeasure, (state, { payload }) => {

    let last4Measures = addMeasureToLast4Measures(payload, state.entities[payload.type]!.last4Measures!);
    let last24hMeasures = AddMeasureToLast24hMeasures(payload, state.entities[payload.type]!.last24hMeasures.measures, last4Measures[last4Measures.length - 1].timestamp);

    return sensorEntityAdapter.updateOne(
      {
        id: payload.type,
        changes: {
          cardStatus: getCardStatus(last4Measures, state.entities[payload.type]!.threshold),
          lastMeasure: last4Measures[last4Measures.length - 1],
          last4Measures: last4Measures,
          last4MeasuresChartSeries: buildChartSeries(false, state.entities[payload.type], last4Measures),
          last24hMeasures: {
            loaded: state.entities[payload.type]!.last24hMeasures.loaded,
            measures: last24hMeasures
          },
        }
      },
      {
        ...state, lastMeasureTimestamp: (state.lastMeasureTimestamp < last4Measures[last4Measures.length - 1].timestamp ? last4Measures[last4Measures.length - 1].timestamp : state.lastMeasureTimestamp)
      }
    )
  })
)

export const getLastMeasureTimestamp = (state: SensorEntityState) => state.lastMeasureTimestamp;

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = sensorEntityAdapter.getSelectors();

export const sensorEntitySelectIds = selectIds;

export const sensorEntitySelectEntities = selectEntities;

export const sensorEntitySelectAll = selectAll;

export const sensorEntitySelectTotal = selectTotal;
