import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { combineLatest, of, Subject } from 'rxjs';
import { filter, first, map, mergeMap, skipWhile, takeUntil } from 'rxjs/operators';
import { Notification } from '../../../models';
import * as fromStore from '../../../store';
import { notificationFormatTimestamp } from '../../utils/date';
import { formatSensorLabel, replaceNumberView } from '../../utils/sensors';
import { NotificationsWindowComponent } from '../notifications-window/notifications-window.component';
import { DateTime } from 'luxon';
import { NOTIFICATIONS_TYPE } from '../../../constants/notification_type';

@Component({
  selector: 'pwa-notifications-popover',
  templateUrl: './notifications-popover.component.html',
  styleUrls: ['./notifications-popover.component.scss'],
  providers: [DialogService]
})
export class NotificationsPopoverComponent implements OnInit, OnDestroy {
  @Output() destroy: EventEmitter<void> = new EventEmitter();

  private destroy$: Subject<void> = new Subject<void>();
  dialogRef: any;
  last5NotCompletedNotifications: any[] = [];
  allNotifications: any[] = [];
  notCompleted: Number = 0;
  icons = { "threshold": "fas fa-exclamation-triangle fa-lg", "disease": "fas fa-viruses fa-lg" };
  translation: any;


  constructor(public translateService: TranslateService,
    private store: Store,
    private dialogService: DialogService
  ) {
  }

  ngOnInit(): void {
    combineLatest(
      [
        this.store
          .select(
            fromStore.selectCurrentTemplates
          )
          .pipe(
            filter(v => v != undefined)),
        this.store
          .select(
            fromStore.selectAllNotifications
          )
      ]
    ).subscribe(v => {
      const [templates, notifications] = v;
      this.allNotifications = notifications.map((notification: Notification) => {
        this.translationSensor(notification?.sensor_node_serial);
        return {
          ...notification,
          icon: this.icons[notification.type],
          content: this.templateFormat(
            !!templates![notification.type] ? templates![notification.type]['text'] : templates?.["templates"][notification.type]['text'],
            notification.type,
            notification.sensor_node_serial,
            notification.terms,
            this.translation
          )
        };
      });
      this.last5NotCompletedNotifications = this.allNotifications.filter(n => n.completed === false).slice(0, window.innerWidth < 768 ? 2 : 5);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  };

  templateFormat(template: string,notificationType:string, sensorNodeserialNumber: string, terms: any, transletedSensor: any) {
    return template.replace(/{(\d+)}/g, (match, number) => {
      if(notificationType === NOTIFICATIONS_TYPE.THRESHOLD){
        switch (match) {
          case "{0}"://Match {0} => SENSORNODE
          return sensorNodeserialNumber;
          case "{1}"://MATCH {1} SENSORTYPE
          let sensorType = terms.sensor_type.substring(0, 2);
          return `${this.translateService.instant("NOTIFICATIONS")[sensorType]}`.replace('{number}', ``);
          case "{2}"://MATCH {2} TIMESTAMP
          return notificationFormatTimestamp(terms.timestamp);
          case "{3}"://MATCH {3} MEASURE VALUE
          return replaceNumberView(terms.value);
          case "{4}"://MATCH {4} UNIT MEASURE
          return terms.unit_measure;
          case "{5}"://MATCH {5} DIFFERENZE FROM VALUE
          return terms.difference;
          case "{6}"://MATCH {6} EXCESS OF THRESHOLD
          return this.translateService.instant("NOTIFICATIONS")[terms.excess_type.toUpperCase()];
          case "{7}"://MATCH {6} TYPE OF THRESHOLD
          if (terms.excess_type === "higher") {
            return this.translateService.instant("NOTIFICATIONS")["MAXIMUM"];
          } else {
            return this.translateService.instant("NOTIFICATIONS")["MINIMUM"];
          }
        }
      } else {
        if(notificationType === NOTIFICATIONS_TYPE.DISEASE){
          switch (match) {
            case "{0}":
              return notificationFormatTimestamp(terms.infection_date);
            case "{1}":
              return sensorNodeserialNumber;
            case "{2}":
              return this.translateService.instant("NOTIFICATIONS")[terms.type.toUpperCase()];;
          }
        }
      }
    })
  }

  handleNotificationClick($event) {
    $event.stopPropagation();
    this.store.dispatch(fromStore.UpdateNotifications({ payload: [$event.currentTarget.value] }));
  }

  handleMarkAllAsRead($event): void {
    $event.stopPropagation();
    this.store.dispatch(fromStore.UpdateNotifications({ payload: this.allNotifications.map((n: any) => n.id) }));
  }

  handleMore($event): void {
        this.dialogRef = this.dialogService.open(NotificationsWindowComponent, {
          header: this.translateService.instant("NOTIFICATIONS.WINDOW")['title'],
          data: { notifications: this.allNotifications },
          baseZIndex: 40
        });
        this.destroy.emit();
  }

  //fixed this
  translationSensor(sensorNodeSerial: string) {
    this.store.select(
      fromStore.selectSensorNodeEntity, { serialNumber: sensorNodeSerial }
    )
      .pipe(
        skipWhile(v => v == null),
        map(sensorNode => ({
          "sensorNodeName": sensorNode.name,
          "sensors": sensorNode.sensors.map(s => s.type)
        })),
        takeUntil(this.destroy$),
      )
      .subscribe(result => {
        this.translation = {
          [result.sensorNodeName]: result.sensors.reduce((acc, cur) => (
            {
              ...acc,
              [cur]: formatSensorLabel(cur, result.sensors)
            }), {})
        };
      });
  }

}
