import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { BatteryStatus } from "../../../models/batteryStatus";

@Component({
  selector: 'pwa-battery-icon',
  template: `
    <div [title]="title">
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="28" viewBox="0 0 72 56">
        <path id="batteryPath" d=""/>
        <text xml:space="preserve"
              style="font-size:35px;fill:#000000;stroke-width:4; font-weight: bold; user-select:none" x="25" y="40">
            <tspan style="stroke-width:4" x="25" y="40">{{ !batteryStatus?.date ? '?' : '' }}</tspan>
        </text>
      </svg>
    </div>
  `,
})
export class BatteryIconComponent implements OnInit, OnChanges {
  @Input() batteryStatus: BatteryStatus;

  title = '';
  class: string;

  ngOnInit() {
    this.updateBattery();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateBattery();
  }

  updateBattery() {
    const status = this.batteryStatus || {level: 0, charging: false, date: ''};
    const level: 0 | 25 | 50 | 75 | 100 = status.level > 75 ? 100 : status.level > 50 ? 75 : status.level > 25 ? 50 : status.level === 0 ? 0 : 25;

    this.title = status.date;
    const batteryLevel = `battery-${level}-${status.charging ? 'y' : 'n'}`;
    if (batteryIconPaths[batteryLevel]) {
      document.getElementById('batteryPath').setAttribute('d', batteryIconPaths[batteryLevel]);
    } else {
      document.getElementById('batteryPath').setAttribute('d', batteryIconPaths['battery-0-n']);
    }
  }
}

export const batteryIconPaths = {
  "battery-0-n": "M68 18c2.219 0 4 1.781 4 4v12c0 2.219-1.781 4-4 4v5c0 2.75-2.25 5-5 5h-58c-2.75 0-5-2.25-5-5v-30c0-2.75 2.25-5 5-5h58c2.75 0 5 2.25 5 5v5zM68 34v-12h-4v-9c0-0.563-0.438-1-1-1h-58c-0.563 0-1 0.438-1 1v30c0 0.563 0.438 1 1 1h58c0.563 0 1-0.438 1-1v-9h4z",
  "battery-0-y": "M68 18c2.219 0 4 1.781 4 4v12c0 2.219-1.781 4-4 4v5c0 2.75-2.25 5-5 5h-58c-2.75 0-5-2.25-5-5v-30c0-2.75 2.25-5 5-5h58c2.75 0 5 2.25 5 5v5zM68 34v-12h-4v-9c0-0.563-0.438-1-1-1h-58c-0.563 0-1 0.438-1 1v30c0 0.563 0.438 1 1 1h58c0.563 0 1-0.438 1-1v-9h4z m -48.498113,-6.437735 12.437506,-0.08324 5.083248,7.992669 16.667321,-8.139796 -11.733689,-0.132294 -5.084575,-7.293947 z",
  "battery-25-n": "M8 40v-24h16v24h-16zM68 18c2.219 0 4 1.781 4 4v12c0 2.219-1.781 4-4 4v5c0 2.75-2.25 5-5 5h-58c-2.75 0-5-2.25-5-5v-30c0-2.75 2.25-5 5-5h58c2.75 0 5 2.25 5 5v5zM68 34v-12h-4v-9c0-0.563-0.438-1-1-1h-58c-0.563 0-1 0.438-1 1v30c0 0.563 0.438 1 1 1h58c0.563 0 1-0.438 1-1v-9h4z",
  "battery-25-y": "M8 40v-24h16v24h-16zM68 18c2.219 0 4 1.781 4 4v12c0 2.219-1.781 4-4 4v5c0 2.75-2.25 5-5 5h-58c-2.75 0-5-2.25-5-5v-30c0-2.75 2.25-5 5-5h58c2.75 0 5 2.25 5 5v5zM68 34v-12h-4v-9c0-0.563-0.438-1-1-1h-58c-0.563 0-1 0.438-1 1v30c0 0.563 0.438 1 1 1h58c0.563 0 1-0.438 1-1v-9h4z m -48.498113,-6.437735 12.437506,-0.08324 5.083248,7.992669 16.667321,-8.139796 -11.733689,-0.132294 -5.084575,-7.293947 z",
  "battery-50-n": "M8 40v-24h28v24h-28zM68 18c2.219 0 4 1.781 4 4v12c0 2.219-1.781 4-4 4v5c0 2.75-2.25 5-5 5h-58c-2.75 0-5-2.25-5-5v-30c0-2.75 2.25-5 5-5h58c2.75 0 5 2.25 5 5v5zM68 34v-12h-4v-9c0-0.563-0.438-1-1-1h-58c-0.563 0-1 0.438-1 1v30c0 0.563 0.438 1 1 1h58c0.563 0 1-0.438 1-1v-9h4z",
  "battery-50-y": "M 8,40 V 16 H 36 V 40 Z M 68,18 c 2.219,0 4,1.781 4,4 v 12 c 0,2.219 -1.781,4 -4,4 v 5 c 0,2.75 -2.25,5 -5,5 H 5 C 2.25,48 0,45.75 0,43 V 13 C 0,10.25 2.25,8 5,8 h 58 c 2.75,0 5,2.25 5,5 z m 0,16 V 22 h -4 v -9 c 0,-0.563 -0.438,-1 -1,-1 H 5 c -0.563,0 -1,0.438 -1,1 v 30 c 0,0.563 0.438,1 1,1 h 58 c 0.563,0 1,-0.438 1,-1 v -9 z m -48.498113,-6.437735 12.437506,-0.08324 5.083248,7.992669 16.667321,-8.139796 -11.733689,-0.132294 -5.084575,-7.293947 z",
  "battery-75-n": "M8 40v-24h40v24h-40zM68 18c2.219 0 4 1.781 4 4v12c0 2.219-1.781 4-4 4v5c0 2.75-2.25 5-5 5h-58c-2.75 0-5-2.25-5-5v-30c0-2.75 2.25-5 5-5h58c2.75 0 5 2.25 5 5v5zM68 34v-12h-4v-9c0-0.563-0.438-1-1-1h-58c-0.563 0-1 0.438-1 1v30c0 0.563 0.438 1 1 1h58c0.563 0 1-0.438 1-1v-9h4z",
  "battery-75-y": "M8 40v-24h40v24h-40zM68 18c2.219 0 4 1.781 4 4v12c0 2.219-1.781 4-4 4v5c0 2.75-2.25 5-5 5h-58c-2.75 0-5-2.25-5-5v-30c0-2.75 2.25-5 5-5h58c2.75 0 5 2.25 5 5v5zM68 34v-12h-4v-9c0-0.563-0.438-1-1-1h-58c-0.563 0-1 0.438-1 1v30c0 0.563 0.438 1 1 1h58c0.563 0 1-0.438 1-1v-9h4z m -48.498113,-6.437735 12.437506,-0.08324 5.083248,7.992669 16.667321,-8.139796 -11.733689,-0.132294 -5.084575,-7.293947 z",
  "battery-100-n": "M60 16v24h-52v-24h52zM64 34h4v-12h-4v-9c0-0.563-0.438-1-1-1h-58c-0.563 0-1 0.438-1 1v30c0 0.563 0.438 1 1 1h58c0.563 0 1-0.438 1-1v-9zM72 22v12c0 2.219-1.781 4-4 4v5c0 2.75-2.25 5-5 5h-58c-2.75 0-5-2.25-5-5v-30c0-2.75 2.25-5 5-5h58c2.75 0 5 2.25 5 5v5c2.219 0 4 1.781 4 4z",
  "battery-100-y": "M60 16v24h-52v-24h52zM64 34h4v-12h-4v-9c0-0.563-0.438-1-1-1h-58c-0.563 0-1 0.438-1 1v30c0 0.563 0.438 1 1 1h58c0.563 0 1-0.438 1-1v-9zM72 22v12c0 2.219-1.781 4-4 4v5c0 2.75-2.25 5-5 5h-58c-2.75 0-5-2.25-5-5v-30c0-2.75 2.25-5 5-5h58c2.75 0 5 2.25 5 5v5c2.219 0 4 1.781 4 4z m -52.498113,5.437735 12.437506,-0.08324 5.083248,7.992669 16.667321,-8.139796 -11.733689,-0.132294 -5.084575,-7.293947 z"
}
