import { createAction, props } from '@ngrx/store';
import { User } from '../../models';

export const LOAD_USER = '[User] Load User';

export const SET_USER = '[User] Load User Success';

export const UPDATE_USER = '[User] Update User';

export const LoadUser = createAction(
  LOAD_USER,
  props<{
    username: string
  }>()
)

export const SetUser = createAction(
  SET_USER,
  props<{
    user: User
  }>()
)

export const UpdateUser = createAction(
  UPDATE_USER,
  props<{
    user: User
  }>()
);

