import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../app.state';
import * as fromSensorNode from '../reducers/sensorNodes.reducer';

export const selectSensorNodesState = createFeatureSelector<fromSensorNode.SensorNodeEntityState>('sensorNodeEntity');

export const selectSensorNodesSerialNumbers = createSelector(
  selectSensorNodesState,
  fromSensorNode.sensorNodeEntitySelectIds
);

export const selectSensorNodesEntities = createSelector(
  selectSensorNodesState,
  fromSensorNode.sensorNodeEntitySelectEntities
);

export const selectAllSensorNodes = createSelector(
  selectSensorNodesState,
  fromSensorNode.sensorNodeEntitySelectAll
);

export const selectSensorNodeTotal = createSelector(
  selectSensorNodesState,
  fromSensorNode.sensorNodeEntitySelectTotal
);

export const selectCurrentSensorNodeSerialNumber = createSelector(
  selectSensorNodesState,
  fromSensorNode.getSelectedSensorNodeSerialNumber
);

export const selectCurrentSensorNodePosition = createSelector(
  selectSensorNodesState,
  fromSensorNode.getSelectedSensorNodePosition
);

export const selectCurrentSensorNode = createSelector(
  selectSensorNodesEntities,
  selectCurrentSensorNodeSerialNumber,
  (entities, serialNumber) => entities[serialNumber]
);

export const selectCurrentSensorNodeTimezone = createSelector(
  selectCurrentSensorNode,
  (currentSensorNode) => currentSensorNode?.timezone
);

export const selectSensorNodeEntity = createSelector(
  selectSensorNodesEntities,
  (entities, props) => entities[props.serialNumber]
);
