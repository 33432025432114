import { createEntityAdapter, EntityState, EntityAdapter } from '@ngrx/entity';
import { SensorNode } from '../../models';
import * as sensorNodesActions from '../actions/sensorNodes.actions';
import { createReducer, on } from '@ngrx/store';

export interface SensorNodeEntityState extends EntityState<SensorNode> {
  selectedSensorNodeSerialNumber: string | null;
};

const sensorNodeEntityAdapter: EntityAdapter<SensorNode> = createEntityAdapter<SensorNode>({
  selectId: (sensorNode: SensorNode) => sensorNode.serialNumber,
  sortComparer: (a: SensorNode, b: SensorNode) => a.name.localeCompare(b.name)
});

const sensorNodeEntityInitialState: SensorNodeEntityState = sensorNodeEntityAdapter.getInitialState({
  selectedSensorNodeSerialNumber: null
});

export const sensorNodesReducer = createReducer(
  sensorNodeEntityInitialState,
  on(sensorNodesActions.LoadSensorNodes, (state, { sensorNodes, index }) => (
    sensorNodeEntityAdapter.setAll(sensorNodes, {
      ...state,
      selectedSensorNodeSerialNumber: sensorNodes.length > 0
        ? sensorNodes[index > 0 ? index : 0].serialNumber
        : null
    }))),
  on(sensorNodesActions.ChangeSelectedSensorNodeSerialNumber, (state, { sensorNode }) => ({ ...state, selectedSensorNodeSerialNumber: sensorNode })),
  on(sensorNodesActions.PutSensorNode, state => state),
  on(sensorNodesActions.SetSensorNodesPosition, (state, { payload }) => (
    sensorNodeEntityAdapter.updateMany(payload?.map((sensorNodeLastPosition: any) => {
      return {
        id: sensorNodeLastPosition.sensorNodeSerial,
        changes: {
          positions: {
            lat: sensorNodeLastPosition.gpsp ? sensorNodeLastPosition.gpsp.split(",")[0] : null,
            long: sensorNodeLastPosition.gpsp ? sensorNodeLastPosition.gpsp.split(", ")[1] : null,
            timezone: sensorNodeLastPosition.timezone
          }
        }
      }
    }), { ...state })
  )),
  on(sensorNodesActions.SetSensorNodesTimezones, (state, { timezones }) => (
    sensorNodeEntityAdapter
      .updateMany(Object.keys(timezones).map(serialNumber => ({
        id: serialNumber,
        changes: {
          timezone: timezones[serialNumber]
        }
      })),
        { ...state })
  )),
  on(sensorNodesActions.UpdateSensorNode, (state, { name, serial }) => (
    sensorNodeEntityAdapter.updateOne(
      {
        id: serial,
        changes: {
          name: name
        },
      },
      { ...state }
    )
  ))
)

export const getSelectedSensorNodeSerialNumber = (state: SensorNodeEntityState) => state.selectedSensorNodeSerialNumber;

export const getSelectedSensorNodePosition = (state: SensorNodeEntityState) => state?.entities[state.selectedSensorNodeSerialNumber]?.positions;


const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = sensorNodeEntityAdapter.getSelectors();

export const sensorNodeEntitySelectIds = selectIds;

export const sensorNodeEntitySelectEntities = selectEntities;

export const sensorNodeEntitySelectAll = selectAll;

export const sensorNodeEntitySelectTotal = selectTotal;
