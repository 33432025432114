import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbSidebarService } from '@nebular/theme';
import { bufferTime, catchError, filter, map, skipWhile, switchMap, takeUntil, } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../store';
import { DialogService } from 'primeng/dynamicdialog';
import { DateWithTimezoneApplied } from '../../../evja/utils/date';
import { AnalyticsService } from '../../../@core/utils';
import { GraphqlService } from '../../../services/graphql.service';
import { environment } from 'apps/web-app/src/environments/environment';
import { BatteryStatus } from "../../../models/batteryStatus";


@Component({
  selector: 'pwa-subheader',
  styleUrls: ['./subheader.component.scss'],
  templateUrl: './subheader.component.html',
  providers: [DialogService],
})
export class SubheaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  destroySubscribeToLastStatus$: Subject<void> = new Subject<void>();
  lastMeasureTimestamp: string;

  batteryStatus: BatteryStatus;

  constructor(
    private sidebarService: NbSidebarService,
    public translate: TranslateService,
    private store: Store,
    private analytics: AnalyticsService,
    private graphqlService: GraphqlService,
  ) {
  }

  ngOnInit() {
    this.store
      .select(fromStore.selectCurrentSensorNodeSerialNumber)
      .pipe(
        skipWhile((serial) => serial == null),
        switchMap((sensorNodeSerial) =>
          this.graphqlService
            .subscribeToLastStatus({
              sensorNodeSerial: sensorNodeSerial,
            })
            .pipe(
              takeUntil(this.destroySubscribeToLastStatus$),
              bufferTime(environment.bufferTimeRefreshMS),
              filter(r => r?.length > 0),
            )
        )
      )
      .subscribe((result) => {
        let statusCheck = {};
        if (result.length > 0) {
          result.filter(r => !!r?.data?.lastStatus).map(r => r.data.lastStatus).forEach(item => {
            statusCheck['timestamp'] = item.timestamp;
            statusCheck[item.type] = item.value;
          });
          this.batteryStatus = {
            level: parseInt(statusCheck[environment.batteryLevelSensor] || '0') || 0,
            charging: statusCheck[environment.batteryInChargeSensor] === 'Y',
            date: this.getTimestampTranslated(statusCheck['timestamp']),
          }
        }
      });

    this.store
      .select(fromStore.selectCurrentSensorNodeSerialNumber)
      .pipe(
        skipWhile((serial) => serial == null),
        switchMap((sensorNodeSerial) =>
          this.graphqlService
            .fetchStatus(
              {
                input: {
                  sensorNodeSerial: sensorNodeSerial,
                  sensors: [environment.batteryInChargeSensor, environment.batteryLevelSensor],
                  aggregateByTimestamp: true,
                  limit: 2,
                },
              },
              {
                fetchPolicy: 'no-cache',
              }
            )
            .pipe(
              map((result) => {
                const fetchedStatus = ((result?.data?.status?.data || [])[0]) || {};
                return {
                  batteryLevel: parseInt(fetchedStatus[environment.batteryLevelSensor]) || 0,
                  batteryInCharge: fetchedStatus[environment.batteryInChargeSensor] || 'N',
                  timestamp: fetchedStatus.timestamp || '',
                }
              }),
              catchError(err => {
                console.error(err);
                return of({batteryInCharge: 'N', batteryLevel: 0, timestamp: ''})
              })
            )
        )
      )
      .subscribe((result) => {
        this.batteryStatus = {
          level: result.batteryLevel,
          charging: result.batteryInCharge === 'Y',
          date: this.getTimestampTranslated(result.timestamp),
        }
      });

    //LastDetection
    this.store
      .select(fromStore.selectCurrentLastMeasureTimestamp)
      .pipe(takeUntil(this.destroy$))
      .subscribe((lastMeas) => {
        this.lastMeasureTimestamp = this.getTimestampTranslated(lastMeas);
      });
  }

  getTimestampTranslated(timestamp: string) {
    return DateWithTimezoneApplied(timestamp, this.translate.currentLang) ==
    'Invalid DateTime'
      ? ''
      : DateWithTimezoneApplied(timestamp, this.translate.currentLang);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroySubscribeToLastStatus$.next();
    this.destroySubscribeToLastStatus$.complete();
  }

  isMobile(): boolean {
    return !(document.body.offsetWidth < 1108);
  }

  getEventHeader(
    eventName: string,
    eventParams?: {
      [key: string]: any;
    }
  ) {
    this.analytics.trackEvent(eventName, eventParams);
  }
}
