import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isMobile } from '../../../evja/utils/breakpoints';
import { DateWithTimezoneApplied } from '../../../evja/utils/date';
import * as fromStore from '../../../store';

@Component({
  selector: 'pwa-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  lastMeasureTimestamp: string;
  yearNow = DateTime.now().year;
  constructor(
    public translate: TranslateService,
    private store: Store,
  ) { }

  ngOnInit() {
    //LastDetection
    this.store
      .select(
        fromStore.selectCurrentLastMeasureTimestamp
      )
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(lastMeas => {
        this.lastMeasureTimestamp = DateWithTimezoneApplied(lastMeas, this.translate.currentLang) == 'Invalid DateTime' ? "--" : DateWithTimezoneApplied(lastMeas, this.translate.currentLang);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isMobile(): boolean {
    return !(document.body.offsetWidth < 1108);
  }


}
