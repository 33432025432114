/**
 * @license
 * Copyright Evja SRL. All Rights Reserved.
 */
export const environment = {
  disableDisease: false,
  production: false,
  logLevel: 1, // TRACE = 0 | DEBUG = 1 | INFO = 2 | LOG = 3 | WARN = 4 | ERROR = 5| FATAL = 6 | OFF = 7 (nb to see debug logs in the console enable verbose in your browser dev tools)
  sensors: {
    temperature: ['a'],
    // solarRadiation: ["d", "e", "f"],
    solarRadiation: ['d'],
    airSensor: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'm', 'z'],
    substrateSensor: ['h', 'i', 'l', 'n'],
    waterSensor: ['hd', 'hc', 'he', 'lc', 'ra', 'sa', 'ta', 'ua', 'va'],
  },
  renameSensorNodeLenght: 30,
  timezoneSensor: 'ya',
  gpspSensor: 'yb',
  batteryInChargeSensor: 'xa0',
  batteryLevelSensor: 'xb0',
  bufferTimeRefreshMS: 300000,
  unitMeasure: 'metric',
  weather: {
    openWeatherAPIkey: '7b9f5f4e3078c1cfcf1fc6ce333433d3',
    openWeatherURL: 'api.openweathermap.org/data/3.0',
    cacheTTL: 480,
    cacheInLocalStorage: false,
  },
  emailEvja: 'opi@evja.eu',
  whatsappEvja: 'https://api.whatsapp.com/send?phone=393401522358&text=',
  calendar: {
    downyMidlewDaysOffset: 12,
    colorEvent: {
      note: {
        background: '#FFFFA4',
        text: '#000000',
      },
      driftrisk: {
        background: '#FCCC55',
        text: '#000000',
      },
      cleanliness: {
        background: '#6EBE71',
        text: '#000000',
      },
      fieldrelease: {
        background: '#FCCC55',
        text: '#000000',
      },
      treatment: {
        background: '#12AFCB',
        text: '#FFFFFF',
      },
      threat: {
        background: 'FCCC55',
        text: '#000000',
      },
      audit: {
        background: '#FFFFA4',
        text: '#000000',
      },
      infection: {
        background: '#333333',
        text: '#FFFFFF',
      },
    },
    eventLimit: 300, //raise the limit of events displayed in the calendar
  },
  graphql: `https://api.evja.org/graphql/`,
  subscriptionSecureKey: '6b79a353-fac3-4bb3-a1fb-5b1a81324628',
  keycloak: {
    enabled: true,
    refreshTokenIntervalMS: 3600000,
    initTimeout: 5000,
    postLoginUrl: '',
    config: {
      realm: 'OPI-Dev',
      url: 'https://authz.evja.org/auth',
      clientId: 'angular-client',
      userRole: 'user-role',
      adminRole: 'admin-role',
      guestRole: 'guest-role',
      diseaseEnable: 'diseaseEnable',
      calendarForms: 'calendarForm',
      operationRole: 'operation-role',
      monitoringRole: 'monitoring-role',
      fullRole: 'full-role',
      history: 'historyRoute',
      charts: 'chartsRoute',
      calendar: 'calendarRoute',
      fertigation: 'fertigationRoute',
      greenhouse: 'greenhouseRoute',
      prospectus: 'prospectusRoute',
    },
  },
  couchDB: {
    useUriWithCredentials: true,
    host: 'couchdb.evja.org',
    port: '',
    username: 'angular-client',
    password: 'angular',
    organizationDbPrefix: 'eid_',
    databases: {
      calendar: 'organizations',
      notifications: 'notifications',
      prospectus: 'prospectus',
    },
  },
  protocol: 'https',
  /* Firebase Config Dev */
  firebaseConfig: {
    enabled: true,
    apiKey: 'AIzaSyAznTknpuIyB8FS95Dq5i6C34oKsLj4BOA',
    authDomain: 'evja-dev.firebaseapp.com',
    projectId: 'evja-dev',
    storageBucket: 'evja-dev.appspot.com',
    messagingSenderId: '345438592346',
    appId: '1:345438592346:web:53f5ec944800acaf3ba763',
    measurementId: 'G-92SHYY10GE',
  },
  displayProgressLog: true,
  delayBeforeNavigationToErrorPage: 3000,
  demoProxy: 'https://apiweb.evja.eu',
  prospectusServer: 'https://api.evja.org/prospectus',
  nominatimParams: {
    url: 'https://nominatim.openstreetmap.org/reverse?',
    format: 'json',
    params: {
      lat: 'lat',
      lon: 'lon',
      format: 'format',
    },
  },
  agromodelsServer: 'https://api.evja.org/agromodels',
};
