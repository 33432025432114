import { Location, PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { KeycloakService } from 'keycloak-angular';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AppState } from '../../../app.state';
import * as fromStore from '../../../store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { SensorNode } from '@pwa/graphql';

@Component({
  selector: 'pwa-rename-sensor-node',
  templateUrl: './rename-sensor-node.component.html',
  styleUrls: ['./rename-sensor-node.component.scss']
})
export class RenameSensorNodeComponent implements OnInit {
  sensorNodeName: FormControl = new FormControl("", [Validators.maxLength(environment.renameSensorNodeLenght)]);
  tooltipMaxLenght: string = "";
  sensorNodes$: any;

  context: any;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private store: Store<AppState>,
    private toastrService: NbToastrService,
    private keycloakService: KeycloakService,
    private platformLocation: PlatformLocation,
    private location: Location,
    private translateService:TranslateService
  ) { }

  ngOnInit(): void {
    // this code is used to close dialog with back button
    this.location.go(this.platformLocation.pathname);
    this.platformLocation.onPopState(() => {
      this.location.go(this.platformLocation.pathname);
      this.ref.close();
    });

    this.sensorNodes$ = this.store.select(fromStore.selectCurrentSensorNode);

    this.context = this.config.data;
    this.tooltipMaxLenght = this.translateService.instant('COMMON.SNODERENAMEMAXLENGHT').replace('{number}', environment.renameSensorNodeLenght);
    this.store
      .select(
        fromStore.selectCurrentSensorNode
      )
      .pipe(take(1))
      .subscribe((sensorNode: any) => {
        this.sensorNodeName.setValue(sensorNode.name);
      });
  }

  updateSensorNode(): void {
    this.store
      .select(
        fromStore.selectCurrentSensorNode
      )
      .pipe(take(1))
      .subscribe((sensorNode: any) => {
        this.store.dispatch(fromStore.PutSensorNode({serial : sensorNode.serialNumber, name : this.sensorNodeName.value}));
        this.sensorNodeName.reset();
      });
    this.ref.close();
    this.toastrService.show(status, this.translateService.instant('COMMON.CONFIRMCHANGENAMESNODE'), { status: 'success' });

  }

  isGuest(): boolean {
    return this.keycloakService.isUserInRole(environment.keycloak.config.guestRole);
  }

}
