import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectSensorNodeComponent } from './select-sensor-node.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbActionsModule, NbButtonModule, NbCardModule, NbIconModule, NbInputModule, NbPopoverModule } from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { MapModule } from '../map/map.module';
import { DynamicDialogModule } from 'primeng/dynamicdialog';





@NgModule({
  declarations: [SelectSensorNodeComponent],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
    NbInputModule,
    TranslateModule.forChild(),
    NbActionsModule,
    NbPopoverModule,
    MapModule,
    DynamicDialogModule
  ],
  exports: [SelectSensorNodeComponent]
})
export class SelectSensorNodeModule { }
