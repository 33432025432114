import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../app.state';
import { buildChartSeries } from '../../evja/utils/store';
import * as fromSensor from '../reducers/sensors.reducer';

export const selectSensorsState = createFeatureSelector<fromSensor.SensorEntityState>('sensorEntity');

export const selectSensorsIds = createSelector(
  selectSensorsState,
  fromSensor.sensorEntitySelectIds
);

export const selectSensorsEntities = createSelector(
  selectSensorsState,
  fromSensor.sensorEntitySelectEntities
);

export const selectAllSensors = createSelector(
  selectSensorsState,
  fromSensor.sensorEntitySelectAll
);

export const selectAllSensorsTypes = createSelector(
  selectAllSensors,
  sensors => sensors.map(sensor => sensor.type)
);

export const selectSensorTotal = createSelector(
  selectSensorsState,
  fromSensor.sensorEntitySelectTotal
);

export const selectCurrentLastMeasureTimestamp = createSelector(
  selectSensorsState,
  fromSensor.getLastMeasureTimestamp
);

export const selectSensorEntity = createSelector(
  selectSensorsEntities,
  (entities, props) => entities[props.type]
);

export const selectSensorEntityMeasureUnit = createSelector(
  selectSensorsEntities,
  (entities, props) => entities[props.type].unitMeasure
);

export const selectSensorEntityLastMeasure = createSelector(
  selectSensorsEntities,
  (entities, props) => {
    if (entities[props.type] !== undefined) {
      return entities[props.type].lastMeasure;
    }
    return null;
  }
);

export const selectSensorEntityLast4MeasuresChartSeries = createSelector(
  selectSensorsEntities,
  (entities, props) => {
    if (entities[props.type] !== undefined) {
      return entities[props.type].last4MeasuresChartSeries;
    }
    return buildChartSeries(false);
  }
);

export const selectSensorEntityLast24hMeasures = createSelector(
  selectSensorsEntities,
  (entities, props) => {
    if (entities[props.type] !== undefined) {
      return entities[props.type].last24hMeasures.measures;
    }
    return buildChartSeries(true);
  }
);

export const selectSensorEntityLast24hMeasuresLoaded = createSelector(
  selectSensorsEntities,
  (entities, props) => {
    if (entities[props.type] !== undefined) {
      return entities[props.type].last24hMeasures.loaded;
    }
    return buildChartSeries(true);
  }
);

export const selectSensorEntityThreshold = createSelector(
  selectSensorsEntities,
  (entities, props) => {
    if (entities[props.type] !== undefined) {
      return entities[props.type].threshold;
    }
    return buildChartSeries(true);
  }
);
