import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../app.state';
import * as fromNotification from '../reducers/notifications.reducer';


export const selectNotificationsState = createFeatureSelector<fromNotification.NotificationEntityState>('notificationEntity');

export const selectNotificationsIds = createSelector(
  selectNotificationsState,
  fromNotification.notificationEntitySelectIds
);

export const selectNotificationsEntities = createSelector(
  selectNotificationsState,
  fromNotification.notificationEntitySelectEntities
);

export const selectAllNotifications = createSelector(
  selectNotificationsState,
  fromNotification.notificationEntitySelectAll
);

export const selectNotificationTotal = createSelector(
  selectNotificationsState,
  fromNotification.notificationEntitySelectTotal
);

export const selectCurrentId = createSelector(
  selectNotificationsState,
  fromNotification.getId
);

export const selectCurrentRev = createSelector(
  selectNotificationsState,
  fromNotification.getRev
);

export const selectCurrentTemplates = createSelector(
  selectNotificationsState,
  fromNotification.getTemplates
);

export const selectCurrentNotCompleted = createSelector(
  selectNotificationsState,
  fromNotification.getNotCompleted
);
