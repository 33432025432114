const SENSORS_ICONS = {
  "default": "microchip-solid",
  "a": "temperature-high-solid",
  "b": "humidity_new3",
  "c": "pressure",
  "d": "sun-solid",
  "e": "sun-solid",
  "f": "sun-regular",
  "g": "leaf-solid",
  "h": "soil-temp_new",
  "i": "soil-vwc",
  "l": "soil-ec_new",
  "m": "co2_new",
  "n": "balance_new",
  "za": "dew-point_new",
  "zb": "vpd_new",
  "zc": "du_new"
};

export function getSensorIcon(type: string): string {
  if (type.startsWith('z')) {
    return SENSORS_ICONS[type.substr(0, 2)] !== undefined ? SENSORS_ICONS[type.substr(0, 2)] : SENSORS_ICONS['default'];
  } else {
    return SENSORS_ICONS[type.substr(0, 1)] !== undefined ? SENSORS_ICONS[type.substr(0, 1)] : SENSORS_ICONS['default'];
  }
}

export function formatSensorLabel(label: string, sensors: string[]): string {
  let sensorsList: string[] = [];
  let sensorsSorted: string[] = [];
  let sensorsLabels = {};
  let sensorsCount = {};

  sensorsSorted = sensors.sort();
  sensors.forEach(sensor => {
    sensorsList.push(sensor.substr(0, 2));
  });


  let i = 0;
  let j = 0;
  let count = 1;
  while (i < sensorsSorted.length) {
    if (sensorsSorted[i].startsWith(sensorsList[j])) {
      sensorsLabels[sensorsSorted[i]] = count;
      sensorsCount[sensorsList[j]] = count;
      i++;
      count++;
    } else {
      count = 1;
      j++;
    }
  }

  if (sensorsCount[label.substr(0, 2)] == 1) {
    return "";
  } else {
    return sensorsLabels[label];
  }

}

export function replaceNumberView(numberToReplace): string {
  if (numberToReplace != null) {
    return numberToReplace.toString().replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }
  return "--";
}

export function setDecimalToView(numberToConvert: number) {
  return replaceNumberView(numberToConvert);
}
