
import { ActionReducerMap } from '@ngrx/store';
import * as fromStore from './store';

export interface AppState {
  readonly user: fromStore.UserState;
  readonly sensorNodeEntity: fromStore.SensorNodeEntityState;
  readonly sensorEntity: fromStore.SensorEntityState;
  readonly notificationEntity: fromStore.NotificationEntityState;
}

export const appReducer: ActionReducerMap<AppState> = {
  user: fromStore.userReducer,
  sensorNodeEntity: fromStore.sensorNodesReducer,
  sensorEntity: fromStore.sensorReducer,
  notificationEntity: fromStore.notificationReducer
};

