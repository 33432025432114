<form [formGroup]="changePasswordFormGroup">
  <div class="container" style="max-width: 25rem">
    <div class="row">
      <div class="col-12 mt-3">
        <strong>{{'COMMON.PASSWORD' | translate}}:</strong>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <p-password
          formControlName="oldPassword"
          [toggleMask]="true"
          [feedback]="false"
        ></p-password>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <strong>{{ 'COMMON.NEW' | translate }} {{'COMMON.PASSWORD' | translate}}</strong>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <p-password
          formControlName="newPassword"
          [toggleMask]="true"
        ></p-password>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <strong>{{ 'COMMON.CONFIRMATIONBUTTON' | translate }} {{'COMMON.PASSWORD' | translate}}:</strong>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <p-password
          formControlName="confirmNewPassword"
          [toggleMask]="true"
          [feedback]="false"
        ></p-password>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <p
          class="text-danger"
          *ngIf="
            changePasswordFormGroup.get('confirmNewPassword').invalid &&
            changePasswordFormGroup.get('confirmNewPassword').dirty
          "
        >
          <small>{{ 'CHANGEPASSWORDWINDOW.CONFIRMPASSWORDERROR' | translate }}</small>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3 text-end">
        <button
          [nbSpinner]="loading"
          [disabled]="
            changePasswordFormGroup.invalid || !changePasswordFormGroup.dirty
          "
          type="button"
          status="success"
          nbButton
          (click)="confirmUpdate()"
        >
          {{ 'COMMON.CONFIRMATIONBUTTON' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
