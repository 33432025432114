import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { environment } from 'apps/web-app/src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, distinctUntilKeyChanged, first, map, retry, skipWhile, switchMap } from 'rxjs/operators';
import { getSensorIcon } from '../../evja/utils/sensors';
import { buildChartSeries, getDefaultThreshold } from '../../evja/utils/store';
import { GraphqlService } from '../../services/graphql.service';
import * as SensorNodesActions from '../actions/sensorNodes.actions';
import * as SensorsActions from '../actions/sensors.actions';
import * as SensorNodesSelectors from '../selectors/sensorNodes.selectors';
import { SensorNode } from '../../models';

@Injectable()
export class SensorNodesEffects {

  constructor(
    private actions$: Actions,
    private store: Store,
    private graphqlService: GraphqlService,
    private logger: NGXLogger
  ) { }

  loadSensorNodes$ = createEffect(() => this.actions$.pipe(
    ofType(SensorNodesActions.LoadSensorNodes),
    switchMap((s) =>
      s.sensorNodes.length > 0
        ? this.graphqlService.getSensorNodesLastPosition(
          {
            input: {
              sensorNodesSerials: s.sensorNodes.map(sensorNode => sensorNode.serialNumber),
              gpspFamily: environment.gpspSensor,
              timezoneFamily: environment.timezoneSensor
            },
          },
          {
            fetchPolicy: 'no-cache'
          }
        ).pipe(
          first(),
          map(positions => {
            return {
              positions: positions.data.getSensorNodesLastPosition,
              timezones: positions.data.getSensorNodesLastPosition.reduce((a, b) => {
                let { sensorNodeSerial, gpsp, timezone } = b;
                return { ...a, [sensorNodeSerial]: timezone ? timezone : 'UTC' };
              }, {})
            };
          }),
          retry(3),
          catchError(() => of({ positions: [], timezones: {} }))
        )
        : of({ positions: [], timezones: {} })
    ),
    switchMap((result: any) => [
      SensorNodesActions.SetSensorNodesPosition({ payload: result.positions }),
      SensorNodesActions.SetSensorNodesTimezones({ timezones: result.timezones })
    ])
  ));


  changeSelectedSensorNodeSerialNumber$ = createEffect(() => this.actions$.pipe(
    ofType(SensorNodesActions.ChangeSelectedSensorNodeSerialNumber),
    skipWhile(payload => payload.sensorNode == ""),
    distinctUntilKeyChanged('sensorNode'),
    map((action) => action.sensorNode),
    switchMap((payload: string) =>
      this.store
        .select(
          SensorNodesSelectors.selectSensorNodeEntity, { serialNumber: payload }
        )
        .pipe(
          first()
        )),
    switchMap((node: any) => {
      return [
        SensorsActions.SetSensors(
          {
            sensors:
              node.sensors.map(sensor => (
                {
                  ...sensor,
                  loading: true,
                  icon: getSensorIcon(sensor.type),
                  cardStatus: 'basic',
                  threshold: getDefaultThreshold(sensor.type),
                  lastMeasure: null,
                  last4Measures: [],
                  last4MeasuresChartSeries: buildChartSeries(false),
                  last24hMeasures: {
                    loaded: false,
                    measures: []
                  },
                }
              ))
          }
        )
      ];
    })));



  updateSensorNode$ = createEffect(() => this.actions$.pipe(
    ofType(SensorNodesActions.PutSensorNode),
    switchMap((action) =>
      this.graphqlService.updateSensorNode
        (
          {
            input: {
              serialNumber: action.serial,
              updateSensorNodePatch: {
                set: {
                  name: action.name
                }
              }
            }
          }
        )
        .pipe(
          retry(3),
          map(res => res?.data?.updateSensorNode)
        )
    ),
    switchMap(res => [SensorNodesActions.UpdateSensorNode({ serial: res.serialNumber, name: res.name })
    ])
  )
  );

}
