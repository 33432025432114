<div class="container">
  <div class="row">
    <div class="col-2 align-self-center text-end ms-2">
      <i style="cursor: pointer" [nbPopover]="mapTemplate" nbPopoverPlacement="top-left" class="fas fa-map-marked-alt"></i>
      <ng-template #mapTemplate
        (click)="getEventHeader('SelectSensorNodeComponent.getEventHeader.ClickMap', {type: 'button'})">
        <div class="container ps-0 pe-0">
          <div class="row g-0">
            <div class="col-12" style="width: 30rem; min-width: 15rem; max-width: 50vw;">
              <pwa-map [withAccordion]="false" [windowMap]="false" [zoom]="5"></pwa-map>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <div *ngIf="isAdmin()" class="col-7 align-self-center pe-0">
      <p-dropdown [options]="sensorNodesAdmin" [formControl]="selectedSensorNodeFormControl" optionLabel="serialNumber"
        [filter]="true" filterBy="serialNumber,name" [showClear]="false"
        [style]="{'width': '100%', 'max-width': '30vw'}"
        placeholder="{{'COMMON.SELECTSENSORNODEPLACEHOLDER' | translate}}">
        <ng-template let-snode pTemplate="selectedItem">
          <div *ngIf="snode">
            <div>{{snode.serialNumber}} [{{snode.name}}]
            </div>
          </div>
        </ng-template>
        <ng-template let-snode pTemplate="item">
          <div>
            <div>{{snode.serialNumber}} [{{snode.name}}]
            </div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div *ngIf="!isAdmin()" class="col col-7 align-self-center pe-0">
      <p-dropdown [options]="sensorNodesUser" [formControl]="selectedSensorNodeFormControl" optionLabel="serialNumber"
        [filter]="true" filterBy="name" [showClear]="false" [style]="{'width': '100%', 'max-width': '30vw'}"
        placeholder="{{'COMMON.SELECTSENSORNODEPLACEHOLDER' | translate}}">
        <ng-template let-snode pTemplate="selectedItem">
          <div *ngIf="snode">
            <div>{{snode.name}}</div>
          </div>
        </ng-template>
        <ng-template let-snode pTemplate="item">
          <div>
            <div>{{snode.name}}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="col-2 align-self-center">
      <i style="cursor: pointer;" (click)="open()" class="fas fa-pen"></i>
    </div>
  </div>
</div>
