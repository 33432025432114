<form [formGroup]="updateUserFormGroup" *ngIf="updateUserFormGroup">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-6 col-xl-6">
        <div class="row">
          <div class="col-12 mt-1">
            <strong>{{ 'USERPROFILEWINDOW.ORGANIZATION' | translate }}:</strong>
          </div>
          <div class="col-12 mt-1">
            <input
              pInputText
              [style]="{ width: '100%' }"
              formControlName="organization"
              type="text"
            />
          </div>
          <div class="col-12 mt-3">
            <strong>{{ 'USERPROFILEWINDOW.USERNAME' | translate }}:</strong>
          </div>
          <div class="col-12 mt-1">
            <input
              fullWidth
              formControlName="username"
              type="text"
              pInputText
              [style]="{ width: '100%' }"
            />
          </div>
          <div class="col-12 mt-3">
            <strong>{{ 'USERPROFILEWINDOW.NAME' | translate }}:</strong>
          </div>
          <div class="col-12 mt-1">
            <input
              fullWidth
              formControlName="name"
              type="text"
              pInputText
              [style]="{ width: '100%' }"
              [ngClass]="
                updateUserFormGroup.get('name').invalid &&
                updateUserFormGroup.get('name').touched
                  ? 'ng-invalid ng-dirty'
                  : ''
              "
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-6 col-xl-6">
        <div class="row">
          <div class="col-12 mt-1">
            <strong>{{ 'USERPROFILEWINDOW.LANGUAGE' | translate }}:</strong>
          </div>
          <div class="col-12">
            <p-dropdown
              [baseZIndex]="10000"
              appendTo="body"
              [style]="{ width: '100%' }"
              formControlName="language"
              [options]="options"
              optionLabel="name"
            >
            </p-dropdown>
          </div>
          <div class="col-12 mt-3">
            <strong
              >{{ 'USERPROFILEWINDOW.FAVOURITESENSORNODE' | translate }}:</strong
            >
          </div>
          <div *ngIf="isAdmin()" class="col-12">
            <p-dropdown
              [baseZIndex]="10000"
              appendTo="body"
              [style]="{ width: '100%' }"
              formControlName="favoriteSensorNode"
              [filter]="true"
              filterBy="value,name"
              [options]="sensorNodesOption"
              optionLabel="value"
            >
              <ng-template let-snode pTemplate="selectedItem">
                <div *ngIf="snode">
                  <div>{{ snode.value }} [{{ snode.name }}]</div>
                </div>
              </ng-template>
              <ng-template let-snode pTemplate="item">
                <div>
                  <div>{{ snode.value }} [{{ snode.name }}]</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div *ngIf="!isAdmin()" class="col-12">
            <p-dropdown
              [baseZIndex]="10000"
              appendTo="body"
              [style]="{ width: '100%' }"
              formControlName="favoriteSensorNode"
              [filter]="true"
              filterBy="name"
              [options]="sensorNodesOption"
              optionLabel="name"
            >
            </p-dropdown>
          </div>
          <div class="col-12 mt-3 text-center">
            <strong>{{ 'USERPROFILEWINDOW.WEBNOTIF' | translate }}:</strong>
          </div>
          <div class="col-12 col-md-6 col-lg-6 col-xl-6 mt-2">
            <div class="row">
              <div class="col-6 col-md-6 col-lg-6 col-xl-6 align-self-center">
                <strong
                  >{{ 'USERPROFILEWINDOW.THRESHOLD' | translate }}:</strong
                >
              </div>
              <div class="col-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                <nb-toggle formControlName="webAlert"></nb-toggle>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6 col-xl-6 mt-2">
            <div class="row">
              <div class="col-6 col-md-6 col-lg-6 col-xl-6 align-self-center">
                <strong
                  >{{ 'USERPROFILEWINDOW.PREDICTION' | translate }}:</strong
                >
              </div>
              <div class="col-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                <nb-toggle formControlName="webDisease"></nb-toggle>
              </div>
            </div>
          </div>
          <div class="col-12 mt-3 text-center">
            <strong>{{ 'USERPROFILEWINDOW.EMAILNOTIF' | translate }}:</strong>
          </div>
          <div class="col-12 col-md-6 col-lg-6 col-xl-6 mt-2">
            <div class="row">
              <div class="col-6 col-md-6 col-lg-6 col-xl-6 align-self-center">
                <strong
                  >{{ 'USERPROFILEWINDOW.THRESHOLD' | translate }}:</strong
                >
              </div>
              <div class="col-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                <nb-toggle formControlName="emailAlert"></nb-toggle>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6 col-xl-6 mt-2">
            <div class="row">
              <div class="col-6 col-md-6 col-lg-6 col-xl-6 align-self-center">
                <strong
                  >{{ 'USERPROFILEWINDOW.PREDICTION' | translate }}:</strong
                >
              </div>
              <div class="col-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                <nb-toggle formControlName="emailDisease"></nb-toggle>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-5 text-end">
        <p-confirmDialog>
          <ng-template pTemplate="message" let-message>
            <div
              class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border"
            >
              <i class="pi pi-exclamation-circle text-6xl text-primary-500"></i>
              <p>{{ message.message }}</p>
            </div>
          </ng-template>
          <ng-template pTemplate="footer">
            <ul class="notification-list">
              <li *ngFor="let list of listForDisabledNotification">
                {{ list | translate | titlecase }}
              </li>
            </ul>
            <button
              [nbSpinner]="loading"
              type="button"
              status="danger"
              nbButton
              (click)="closeDialog()"
            >
              {{ 'COMMON.CANCELBUTTON' | translate }}
            </button>
            <button
              [nbSpinner]="loading"
              type="button"
              status="success"
              (click)="confirmUpdate()"
              nbButton
            >
              {{ 'COMMON.CONFIRMATIONBUTTON' | translate }}
            </button>
          </ng-template>
        </p-confirmDialog>
        <button
          [nbSpinner]="loading"
          [disabled]="updateUserFormGroup.invalid || !updateUserFormGroup.dirty"
          type="button"
          status="success"
          nbButton
          (click)="checkNotificationChanges()"
        >
          {{ 'COMMON.CONFIRMATIONBUTTON' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
