import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsWindowComponent } from './notifications-window.component';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { PaginatorModule } from 'primeng/paginator';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [NotificationsWindowComponent],
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    DropdownModule,
    PaginatorModule,
    TranslateModule
  ],
  exports: [NotificationsWindowComponent]
})
export class NotificationsWindowModule { }
