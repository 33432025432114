import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  of,
} from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  map,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { COMPONENTS_LIST } from '../../models/componentsList';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'apps/web-app/src/environments/environment';

export interface componentLoadingState {
  name: string;
  state: Observable<boolean>;
}

export interface WarningError {
  component: COMPONENTS_LIST;
  err: string;
}

export interface FatalError {
  type: 'NETWORK' | 'AUTHENTICATION' | 'UNKNOWN' | 'WINDOW_RELOAD';
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class LoadingService implements OnDestroy {
  // Loading States of components in current page
  private componentsLoadingStates: componentLoadingState[] = [];

  //RETRIABLE WARNINGS LIST
  warningError$: BehaviorSubject<WarningError[]> = new BehaviorSubject([]);

  // trigger for the warning to execute a retry
  // When the retry button is clicked it will emit an observable
  private retryBtnClicked$ = new Subject<string>();
  // Consumable observable
  retryTrigger$ = this.retryBtnClicked$.asObservable();

  // subscription to the loading$ obs
  loadingSub: Subscription = new Subscription();

  destroy$ = new Subject();
  ref: NbDialogRef<unknown> | undefined;

  loadingText$ = new BehaviorSubject(null);

  // This is the component to use for bootsraping the dialog. Usually ProgressLoaderComponent
  // we dont instantiate it directly to avoid circular dependency issues as ProgressLoaderComponent itself depend on this service
  modalComponent: any = null;

  // Fatal Errors
  fatal$: BehaviorSubject<FatalError[]> = new BehaviorSubject([]);

  // warning skip state
  autoSkipWarning: boolean = this.getAutoSkipWarningState();

  delayBeforeNavigationToErrorPage =
    environment.delayBeforeNavigationToErrorPage;

  constructor(
    private dialogService: NbDialogService,
    private keycloakService: KeycloakService,
    private logger: NGXLogger,
    private router: Router,
  ) {}

  // Combines all loading component's state into one observable
  get loading$() {
    const loadingArray = this.componentsLoadingStates?.map((c) => c.state);
    return combineLatest(loadingArray);
  }

  // Total number of components to load
  get loadingLength() {
    const l = this.componentsLoadingStates?.length;
    return l ? l : 0;
  }

  // Use this withing ngOnDestroy to reset the service after you leave a page
  reset() {
    if (!!this.loadingSub) {
      this.loadingSub.unsubscribe();
      this.loadingSub = null;
    }
    this.removeAllWarnings();
    this.componentsLoadingStates = null;
    this.warningError$.next([]);
    this.logger.debug('loading have been reset');
  }

  // Set pages data and handle the modal
  initPage(
    components: componentLoadingState[],
    modalComponent: unknown,
    translationService: TranslateService,
  ) {
    this.modalComponent = modalComponent;
    this.componentsLoadingStates = components;
    this.logger.debug('loading modal init', this.componentsLoadingStates);

    this.loadingSub = this.loading$
      .pipe(
        map((data) => data.some((state) => state === true)),
        delay(50),
        tap((v) => this.handleLoadingModal(v, modalComponent)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.getTranslations(translationService);

    this.initFatalErrorCheck(modalComponent);
  }

  // Init modal
  private handleLoadingModal(
    isAnyComponentLoading: boolean,
    modalComponent: any,
    selfClose = true,
  ) {
    if (isAnyComponentLoading) {
      if (!this.ref && modalComponent) {
        this.ref = this.dialogService.open(modalComponent, {
          hasBackdrop: true,
          closeOnBackdropClick: false,
          dialogClass: 'loading-dialog',
          closeOnEsc: false,
        });
      }
    } else if (
      ((!!this.ref &&
        !this.warningError$.value?.length &&
        !this.fatal$.value.length) ||
        this.autoSkipWarning) &&
      selfClose
    ) {
      this.close();
    }
  }

  initFatalErrorCheck(modalCOmponent: any) {
    this.fatal$
      .pipe(
        tap((fatalArray) => {
          const isAnyFatalError = !!fatalArray.length;
          const errorLog = fatalArray;
          // add an infinitely loading loading component since the app failed
          this.closeManually();
          //isAnyFatalError ? (this.componentsLoadingStates = [{ name: COMPONENTS_LIST.APP, state: of(true)}]) : null
          this.handleLoadingModal(isAnyFatalError, modalCOmponent, false);

          if (isAnyFatalError && !environment.displayProgressLog) {
            this.logger.debug('LOGGIN OUT AND NAVGATING TO ERROR', fatalArray);
            console.log('LOGGIN ERROR', fatalArray);
            setTimeout(async () => {
              await this.keycloakService.logout();
              await this.router.navigate(['/error']);
            }, this.delayBeforeNavigationToErrorPage);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  closeManually() {
    if (this.ref) {
      this.close();
    }
  }

  private close() {
    if (this.ref) {
      this.ref.close();
      this.ref = null;
    }
  }

  // Use when retry button is clicked
  retryWarnings() {
    this.retryBtnClicked$.next(this.generatePushID());
  }

  retryAllSubscribers() {
    this.componentsLoadingStates
      .map((s: any) => s.name)
      .forEach((component: COMPONENTS_LIST) => {
        this.addWarning({
          component,
          err: this.fatal$.value[0].message,
        });
      });

    this.removeFatalErrors();
    this.retryWarnings();
  }

  getAutoSkipWarningState(): boolean {
    // Creation if not existant
    if (localStorage.getItem('autoWarningState') === null) {
      localStorage.setItem('autoWarningState', '0');
      return false;
    }

    const v = Number(localStorage.getItem('autoWarningState'));
    return !!v;
  }

  activateAutoSkipWarning() {
    localStorage.setItem('autoWarningState', '1');
    this.autoSkipWarning = true;
  }

  disableAutoSkipWarning() {
    localStorage.setItem('autoWarningState', '0');
    this.autoSkipWarning = false;
  }

  // fix for ngx-translate-messageformat-compiler
  private fixTranslations(translations: any) {
    switch (typeof translations) {
      case 'function':
        return translations();
      case 'object':
        if (Array.isArray(translations)) {
          return translations.map((t) => this.fixTranslations(t));
        }

        for (const key in translations) {
          translations[key] = this.fixTranslations(translations[key]);
        }
        return translations;
      default:
        return translations;
    }
  }

  // This is a workaround because translateModule cant be inittated in app.module
  // It wlll cause a circular dependency issue, so we init this when the first page is in memory
  getTranslations(translationService: TranslateService) {
    // fix for ngx-translate-messageformat-compiler
    // this.loadingText$.next(
    //   this.fixTranslations(translationService.instant('PROGRESSBAR')),
    // );
    const TRANSLATION_KEY = 'PROGRESSBAR';

    translationService
      .stream(TRANSLATION_KEY)
      .pipe(
        // Avoid setting the same translations multiple times
        distinctUntilChanged(),
        // set translations
        tap((translations) => {
          this.loadingText$.next(translations);
        }),
        // cleanup
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  // This adds a fatal error
  // USE WITH CAUTION BECAUSE IT WILL BLOCK ALL USER INTERACTIONS
  async addFatalError(e: FatalError) {
    //this.modalComponent.stopSimulatingProgress();
    const v = this.fatal$.value;
    this.fatal$.next([...v, e]);
  }

  // Clears the fatal error list and is used as a trigger to close the dialog
  async removeFatalErrors() {
    this.fatal$.next([]);
  }

  addWarning(warning: WarningError) {
    // Removes duplicates
    function getUniqueListBy(arr: WarningError[], key): WarningError[] {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    }

    const val = this.warningError$.value;
    const newVal = getUniqueListBy([...val, warning], 'err');
    this.warningError$.next(newVal);
  }

  removeAllWarnings() {
    this.warningError$.next([]);
  }

  removeWarningsForComponent(component: COMPONENTS_LIST) {
    const value = this.warningError$.value;
    const newVal = value.filter((v) => v.component !== component);
    this.warningError$.next(newVal);
  }

  generatePushID(size = 20) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-={}[]|\\:;"<>,.?/~`';
    const charactersLength = characters.length;
    for (let i = 0; i < size; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
