import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsPopoverComponent } from './notifications-popover.component';
import { NbButtonModule } from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicDialogModule } from 'primeng/dynamicdialog';



@NgModule({
  declarations: [NotificationsPopoverComponent],
  imports: [
    CommonModule,
    NbButtonModule,
    DynamicDialogModule,
    TranslateModule.forChild(),
  ],
  exports: [NotificationsPopoverComponent]
})
export class NotificationsPopoverModule { }
