import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Map: { input: any; output: any };
  MeasuresResult: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type AddDiagnosticSensorInput = {
  deleted: Scalars['Boolean']['input'];
  max?: InputMaybe<Scalars['String']['input']>;
  min?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  unitMeasure?: InputMaybe<Scalars['String']['input']>;
};

export type AddDiseasePredictionInput = {
  damage?: InputMaybe<Scalars['Int']['input']>;
  detection: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
  infection?: InputMaybe<Scalars['String']['input']>;
  noOutbreak?: InputMaybe<Scalars['String']['input']>;
  outbreak?: InputMaybe<Scalars['String']['input']>;
  sensorNodeSerialNumber: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type AddDiseaseSpotInput = {
  eventDate: Scalars['String']['input'];
  sensorNodeSerialNumber: Scalars['String']['input'];
  spottedOn: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type AddDownyMildewPredictionInput = {
  detection: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
  infection?: InputMaybe<Scalars['String']['input']>;
  noOutbreak?: InputMaybe<Scalars['String']['input']>;
  outbreak?: InputMaybe<Scalars['String']['input']>;
  sensorNodeSerialNumber: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

export type AddDownyMildewSpotInput = {
  eventDate: Scalars['String']['input'];
  sensorNodeSerialNumber: Scalars['String']['input'];
  spottedOn: Scalars['String']['input'];
};

export type AddInvalidDataInput = {
  data?: InputMaybe<Array<InputMaybe<InvalidDataMap>>>;
  dataTimestamp?: InputMaybe<Scalars['String']['input']>;
  sensorNodeSerialNumber: Scalars['String']['input'];
  serverTimestamp: Scalars['String']['input'];
  status: Scalars['String']['input'];
};

export type AddOrganizationInput = {
  active: Scalars['Boolean']['input'];
  deleted: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  parent: Scalars['Boolean']['input'];
};

export type AddSensorInput = {
  deleted: Scalars['Boolean']['input'];
  echarts?: InputMaybe<EchartsRef>;
  echartsAverage?: InputMaybe<EchartsRef>;
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  multiplier?: InputMaybe<Scalars['Float']['input']>;
  round?: InputMaybe<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
  unitMeasure?: InputMaybe<Scalars['String']['input']>;
};

export type AddSensorNodeInput = {
  deleted: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  serialNumber: Scalars['String']['input'];
};

export type AddUserGroupsInput = {
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  username: Scalars['String']['input'];
};

export type AddUserGroupsResponse = {
  __typename?: 'AddUserGroupsResponse';
  status: Scalars['String']['output'];
};

export type AddUserInput = {
  active: Scalars['Boolean']['input'];
  deleted: Scalars['Boolean']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  passwd?: InputMaybe<Scalars['String']['input']>;
  profiling?: InputMaybe<ProfilingRef>;
  userRole?: InputMaybe<Role>;
  username: Scalars['String']['input'];
};

export type AddUserThresholdInput = {
  enabled: Scalars['Boolean']['input'];
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
  sensorNodeSerial: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type AverageChartDataInput = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  period: Scalars['String']['input'];
  sensorNodeSerialNumber: Scalars['String']['input'];
  sensorsType: Array<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type CalculationResult = {
  __typename?: 'CalculationResult';
  data?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
};

export type ChartDataInput = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  sensorNodeSerialNumber: Scalars['String']['input'];
  sensorsType: Array<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type ChartSerie = {
  __typename?: 'ChartSerie';
  data?: Maybe<Array<Maybe<TimestampValue>>>;
  itemStyle: ItemStyle;
  lineStyle: LineStyle;
  name: Scalars['String']['output'];
  smoothed: Scalars['Boolean']['output'];
  symbol: Scalars['String']['output'];
  symbolSize: Scalars['Float']['output'];
  type: Scalars['String']['output'];
  yAxisIndex: Scalars['Int']['output'];
};

export type ChartsDataInput = {
  averageOnly?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  sensorNodeSerial: Scalars['String']['input'];
  sensorTypes: Array<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type ChartsSerie = {
  __typename?: 'ChartsSerie';
  averageCharts?: Maybe<Array<Maybe<ChartSerie>>>;
  charts?: Maybe<Array<Maybe<ChartSerie>>>;
};

export type DashboardMeasures = {
  __typename?: 'DashboardMeasures';
  measures: Array<Maybe<Measure>>;
  sensor: Scalars['String']['output'];
};

export type DashboardMeasuresString = {
  __typename?: 'DashboardMeasuresString';
  measures: Array<Maybe<MeasureString>>;
  sensor: Scalars['String']['output'];
};

export type DataSet = {
  __typename?: 'DataSet';
  data?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  name: Scalars['String']['output'];
};

export type DayNightAverageInput = {
  endDate: Scalars['String']['input'];
  sensorNodeSerialNumber: Scalars['String']['input'];
  sensors?: InputMaybe<Array<Scalars['String']['input']>>;
  startDate: Scalars['String']['input'];
  which?: InputMaybe<Scalars['String']['input']>;
};

export type DayNightCalculationResult = {
  __typename?: 'DayNightCalculationResult';
  day?: Maybe<Scalars['String']['output']>;
  night?: Maybe<Scalars['String']['output']>;
};

export type DayTimeAverageInput = {
  endDate: Scalars['String']['input'];
  sensorNodeSerialNumber: Scalars['String']['input'];
  sensors?: InputMaybe<Array<Scalars['String']['input']>>;
  startDate: Scalars['String']['input'];
};

export type DegreeDaysInput = {
  TM?: InputMaybe<Scalars['Float']['input']>;
  Tm?: InputMaybe<Scalars['Float']['input']>;
  Topt?: InputMaybe<Scalars['Float']['input']>;
  data?: InputMaybe<Scalars['Boolean']['input']>;
  endDate: Scalars['String']['input'];
  rate?: InputMaybe<Rate>;
  sensor: Scalars['String']['input'];
  sensorNodeSerialNumber: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

export type DeleteGroupAttributeInput = {
  attribute: Scalars['String']['input'];
  group: Scalars['String']['input'];
};

export type DeleteGroupAttributeResponse = {
  __typename?: 'DeleteGroupAttributeResponse';
  status: Scalars['String']['output'];
};

export type DeleteUserAttributeInput = {
  attribute: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type DeleteUserAttributeResponse = {
  __typename?: 'DeleteUserAttributeResponse';
  status: Scalars['String']['output'];
};

export type DeleteUserGroupsInput = {
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  username: Scalars['String']['input'];
};

export type DeleteUserGroupsResponse = {
  __typename?: 'DeleteUserGroupsResponse';
  status: Scalars['String']['output'];
};

export type DgraphRethinkRes = {
  __typename?: 'DgraphRethinkRes';
  dgraph: Scalars['String']['output'];
  rethink: Scalars['String']['output'];
};

export type DiagnosticSensor = {
  __typename?: 'DiagnosticSensor';
  deleted: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  max?: Maybe<Scalars['String']['output']>;
  min?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  unitMeasure?: Maybe<Scalars['String']['output']>;
};

export type DiseasePrediction = {
  __typename?: 'DiseasePrediction';
  data: PredictionData;
  detection: Scalars['String']['output'];
  endDate: Scalars['String']['output'];
  id: Scalars['String']['output'];
  startDate: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type DiseaseSpot = {
  __typename?: 'DiseaseSpot';
  eventDate: Scalars['String']['output'];
  id: Scalars['String']['output'];
  spottedOn: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type DownyMildewPrediction = {
  __typename?: 'DownyMildewPrediction';
  detection: Scalars['String']['output'];
  endDate: Scalars['String']['output'];
  id: Scalars['String']['output'];
  infection?: Maybe<Scalars['String']['output']>;
  noOutbreak?: Maybe<Scalars['String']['output']>;
  outbreak?: Maybe<Scalars['String']['output']>;
  startDate: Scalars['String']['output'];
};

export type DownyMildewSpot = {
  __typename?: 'DownyMildewSpot';
  eventDate: Scalars['String']['output'];
  id: Scalars['String']['output'];
  spottedOn: Scalars['String']['output'];
};

export type Echarts = {
  __typename?: 'Echarts';
  color?: Maybe<Scalars['String']['output']>;
  dashOffset?: Maybe<Scalars['Float']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
  symbolSize?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type EchartsRef = {
  color?: InputMaybe<Scalars['String']['input']>;
  dashOffset?: InputMaybe<Scalars['Float']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  symbolSize?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type EidRef = {
  eid?: InputMaybe<Scalars['String']['input']>;
};

export type EphemerisInput = {
  date?: InputMaybe<Array<Scalars['String']['input']>>;
  sensorNodeSerialNumber: Scalars['String']['input'];
};

export type EphemerisOutput = {
  __typename?: 'EphemerisOutput';
  moonPhase?: Maybe<Scalars['String']['output']>;
  sunrise?: Maybe<Scalars['String']['output']>;
  sunset?: Maybe<Scalars['String']['output']>;
};

export type EvapotranspirationInput = {
  endDate: Scalars['String']['input'];
  sensor: Scalars['String']['input'];
  sensorNodeSerialNumber: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

export type FetchItem = {
  id: Scalars['Int']['input'];
};

export type FetchMeasure = {
  aggregateByTimestamp?: InputMaybe<Scalars['Boolean']['input']>;
  asc?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  offsetLimit?: InputMaybe<Scalars['Int']['input']>;
  sensorNodeSerial: Scalars['String']['input'];
  sensors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type FetchNodeStatus = {
  desc?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  idSensorNode: Scalars['Int']['input'];
  idTest?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type FetchSensorNode = {
  id: Scalars['Int']['input'];
  serialNo?: InputMaybe<Scalars['String']['input']>;
};

export type FetchStatus = {
  aggregateByTimestamp?: InputMaybe<Scalars['Boolean']['input']>;
  asc?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sensorNodeSerial: Scalars['String']['input'];
  sensors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type File = {
  __typename?: 'File';
  content: Scalars['String']['output'];
  contentType: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type GenerateDownyMildewPredictionInput = {
  endDate: Scalars['String']['input'];
  sensorNodeSerialNumber: Scalars['String']['input'];
  sensors?: InputMaybe<Array<Scalars['String']['input']>>;
  startDate: Scalars['String']['input'];
};

export type GetDashboardMeasuresInput = {
  limit: Scalars['Int']['input'];
  sensorNodeSerialNumber: Scalars['String']['input'];
  sensors: Array<Scalars['String']['input']>;
};

export type GetDiseasePredictionsInput = {
  detectionFrom?: InputMaybe<Scalars['String']['input']>;
  detectionTo?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  infectionFrom?: InputMaybe<Scalars['String']['input']>;
  infectionTo?: InputMaybe<Scalars['String']['input']>;
  noOutbreakFrom?: InputMaybe<Scalars['String']['input']>;
  noOutbreakTo?: InputMaybe<Scalars['String']['input']>;
  outbreakFrom?: InputMaybe<Scalars['String']['input']>;
  outbreakTo?: InputMaybe<Scalars['String']['input']>;
  sensorNodeSerialNumber: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type GetDiseaseSpotsInput = {
  eventDateFrom?: InputMaybe<Scalars['String']['input']>;
  eventDateTo?: InputMaybe<Scalars['String']['input']>;
  sensorNodeSerialNumber: Scalars['String']['input'];
  spottedOnFrom?: InputMaybe<Scalars['String']['input']>;
  spottedOnTo?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type GetDownyMildewPredictionsInput = {
  detectionFrom?: InputMaybe<Scalars['String']['input']>;
  detectionTo?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  infectionFrom?: InputMaybe<Scalars['String']['input']>;
  infectionTo?: InputMaybe<Scalars['String']['input']>;
  noOutbreakFrom?: InputMaybe<Scalars['String']['input']>;
  noOutbreakTo?: InputMaybe<Scalars['String']['input']>;
  outbreakFrom?: InputMaybe<Scalars['String']['input']>;
  outbreakTo?: InputMaybe<Scalars['String']['input']>;
  sensorNodeSerialNumber: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type GetDownyMildewSpotInput = {
  eventDateFrom?: InputMaybe<Scalars['String']['input']>;
  eventDateTo?: InputMaybe<Scalars['String']['input']>;
  sensorNodeSerialNumber: Scalars['String']['input'];
  spottedOnFrom?: InputMaybe<Scalars['String']['input']>;
  spottedOnTo?: InputMaybe<Scalars['String']['input']>;
};

export type GetSensorNodesLastPositionInput = {
  gpspFamily: Scalars['String']['input'];
  sensorNodesSerials: Array<Scalars['String']['input']>;
  timezoneFamily: Scalars['String']['input'];
};

export type GroupAttribute = {
  key: Scalars['String']['input'];
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GroupAttributeResult = {
  __typename?: 'GroupAttributeResult';
  key: Scalars['String']['output'];
  value?: Maybe<Array<Scalars['String']['output']>>;
};

export type GroupAttributesResult = {
  __typename?: 'GroupAttributesResult';
  attributes?: Maybe<Array<GroupAttributeResult>>;
};

export type IdRef = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type InvalidData = {
  __typename?: 'InvalidData';
  dataTimestamp?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  serverTimestamp: Scalars['String']['output'];
  status: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type InvalidDataInput = {
  sensorNodeSerialNumber: Scalars['String']['input'];
  serverTimestampFrom?: InputMaybe<Scalars['String']['input']>;
  serverTimestampTo?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type InvalidDataMap = {
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ItemStyle = {
  __typename?: 'ItemStyle';
  color: Scalars['String']['output'];
};

export type LastDayTimeAverageInput = {
  endDate: Scalars['String']['input'];
  sensorNodeSerialNumber: Scalars['String']['input'];
  sensors?: InputMaybe<Array<Scalars['String']['input']>>;
  startDate: Scalars['String']['input'];
};

export type LastDegreeDaysInput = {
  sensor: Scalars['String']['input'];
  sensorNodeSerialNumber: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

export type LastEvapotranspirationInput = {
  sensor: Scalars['String']['input'];
  sensorNodeSerialNumber: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

export type LastNightTimeAverageInput = {
  endDate: Scalars['String']['input'];
  sensorNodeSerialNumber: Scalars['String']['input'];
  sensors?: InputMaybe<Array<Scalars['String']['input']>>;
  startDate: Scalars['String']['input'];
};

export type LastRadiationSumInput = {
  sensor: Scalars['String']['input'];
  sensorNodeSerialNumber: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

export type LineStyle = {
  __typename?: 'LineStyle';
  type: Scalars['String']['output'];
  width: Scalars['Float']['output'];
};

export type LoginForm = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String']['output'];
  expiresIn: Scalars['Int']['output'];
  notBeforePolicy: Scalars['Int']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  scope: Scalars['String']['output'];
  sessionState: Scalars['String']['output'];
  tokenType: Scalars['String']['output'];
};

export type Measure = {
  __typename?: 'Measure';
  id: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type MeasureKVinput = {
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type MeasureKVinputError = {
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MeasureString = {
  __typename?: 'MeasureString';
  id: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addDiagnosticSensor?: Maybe<DiagnosticSensor>;
  addDiseasePrediction?: Maybe<DiseasePrediction>;
  addDiseaseSpot?: Maybe<DiseaseSpot>;
  addDownyMildewPrediction?: Maybe<DownyMildewPrediction>;
  addDownyMildewSpot?: Maybe<DownyMildewSpot>;
  addInvalidData?: Maybe<Array<Maybe<InvalidData>>>;
  addMeasure: DgraphRethinkRes;
  addMeasureSecure: DgraphRethinkRes;
  addOrganization?: Maybe<Organization>;
  addSensor?: Maybe<Sensor>;
  addSensorNode?: Maybe<SensorNode>;
  addStatus: DgraphRethinkRes;
  addUser?: Maybe<UserExtended>;
  addUserGroups: AddUserGroupsResponse;
  addUserThreshold?: Maybe<UserThreshold>;
  autoUploader: File;
  deleteGroupAttribute: DeleteGroupAttributeResponse;
  deleteUserAttribute: DeleteUserAttributeResponse;
  deleteUserGroups: DeleteUserGroupsResponse;
  generateDownyMildewPrediction: Array<Maybe<DownyMildewPrediction>>;
  infoToken: TokenInfo;
  login: LoginResponse;
  logout: Scalars['String']['output'];
  refreshToken: LoginResponse;
  updateDiagnosticSensor?: Maybe<DiagnosticSensor>;
  updateGroupAttribute: UpdateGroupAttributeResponse;
  updateGroupAttributes: UpdateGroupAttributesResponse;
  updateOrganization?: Maybe<Organization>;
  updateSensor?: Maybe<Sensor>;
  updateSensorNode?: Maybe<SensorNode>;
  updateSensorNodeAdmin?: Maybe<SensorNode>;
  updateUser?: Maybe<UserExtended>;
  updateUserAdmin?: Maybe<UserExtended>;
  updateUserAttribute: UpdateUserAttributeResponse;
  updateUserAttributes: UpdateUserAttributesResponse;
  updateUserPassword: UpdateUserPasswordResponse;
  updateUserThreshold?: Maybe<UserThreshold>;
};

export type MutationAddDiagnosticSensorArgs = {
  input: AddDiagnosticSensorInput;
};

export type MutationAddDiseasePredictionArgs = {
  input: AddDiseasePredictionInput;
};

export type MutationAddDiseaseSpotArgs = {
  input: AddDiseaseSpotInput;
};

export type MutationAddDownyMildewPredictionArgs = {
  input: AddDownyMildewPredictionInput;
};

export type MutationAddDownyMildewSpotArgs = {
  input: AddDownyMildewSpotInput;
};

export type MutationAddInvalidDataArgs = {
  input: AddInvalidDataInput;
};

export type MutationAddMeasureArgs = {
  input: PushMeasure;
};

export type MutationAddMeasureSecureArgs = {
  input: PushMeasure;
};

export type MutationAddOrganizationArgs = {
  input: AddOrganizationInput;
};

export type MutationAddSensorArgs = {
  input: AddSensorInput;
};

export type MutationAddSensorNodeArgs = {
  input: AddSensorNodeInput;
};

export type MutationAddStatusArgs = {
  input: PushStatus;
};

export type MutationAddUserArgs = {
  input: AddUserInput;
};

export type MutationAddUserGroupsArgs = {
  input: AddUserGroupsInput;
};

export type MutationAddUserThresholdArgs = {
  input: AddUserThresholdInput;
};

export type MutationAutoUploaderArgs = {
  file: UploadExtend;
};

export type MutationDeleteGroupAttributeArgs = {
  input: DeleteGroupAttributeInput;
};

export type MutationDeleteUserAttributeArgs = {
  input: DeleteUserAttributeInput;
};

export type MutationDeleteUserGroupsArgs = {
  input: DeleteUserGroupsInput;
};

export type MutationGenerateDownyMildewPredictionArgs = {
  input?: InputMaybe<GenerateDownyMildewPredictionInput>;
};

export type MutationInfoTokenArgs = {
  accessToken: Scalars['String']['input'];
};

export type MutationLoginArgs = {
  input: LoginForm;
};

export type MutationLogoutArgs = {
  refreshToken: Scalars['String']['input'];
};

export type MutationRefreshTokenArgs = {
  refreshToken: Scalars['String']['input'];
};

export type MutationUpdateDiagnosticSensorArgs = {
  input: UpdateDiagnosticSensorInput;
};

export type MutationUpdateGroupAttributeArgs = {
  input: UpdateGroupAttributeInput;
};

export type MutationUpdateGroupAttributesArgs = {
  input: UpdateGroupAttributesInput;
};

export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};

export type MutationUpdateSensorArgs = {
  input: UpdateSensorInput;
};

export type MutationUpdateSensorNodeArgs = {
  input: UpdateSensorNodeUserInput;
};

export type MutationUpdateSensorNodeAdminArgs = {
  input: UpdateSensorNodeInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserExtendedInput;
};

export type MutationUpdateUserAdminArgs = {
  input: UpdateUserExtendedAdminInput;
};

export type MutationUpdateUserAttributeArgs = {
  input: UpdateUserAttributeInput;
};

export type MutationUpdateUserAttributesArgs = {
  input: UpdateUserAttributesInput;
};

export type MutationUpdateUserPasswordArgs = {
  input: UpdateUserPasswordInput;
};

export type MutationUpdateUserThresholdArgs = {
  input: UpdateUserThresholdInput;
};

export type NightTimeAverageInput = {
  endDate: Scalars['String']['input'];
  sensorNodeSerialNumber: Scalars['String']['input'];
  sensors?: InputMaybe<Array<Scalars['String']['input']>>;
  startDate: Scalars['String']['input'];
};

export type Notification = {
  __typename?: 'Notification';
  alert?: Maybe<Scalars['Boolean']['output']>;
  disease?: Maybe<Scalars['Boolean']['output']>;
};

export type NotificationRef = {
  alert?: InputMaybe<Scalars['Boolean']['input']>;
  disease?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Organization = {
  __typename?: 'Organization';
  active: Scalars['Boolean']['output'];
  deleted: Scalars['Boolean']['output'];
  eid: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organizations?: Maybe<Array<Maybe<Organization>>>;
  parent: Scalars['Boolean']['output'];
  users?: Maybe<Array<Maybe<User>>>;
  vatid: Scalars['String']['output'];
};

export type PredictionData = {
  __typename?: 'PredictionData';
  damage?: Maybe<Scalars['Int']['output']>;
  infection?: Maybe<Scalars['String']['output']>;
  noOutbreak?: Maybe<Scalars['String']['output']>;
  outbreak?: Maybe<Scalars['String']['output']>;
};

export type PredictionResult = {
  __typename?: 'PredictionResult';
  infectionDate: Scalars['String']['output'];
  outbreakDate: Scalars['String']['output'];
};

export type Profiling = {
  __typename?: 'Profiling';
  decimalDigits?: Maybe<Scalars['Int']['output']>;
  emailNotification?: Maybe<Notification>;
  language?: Maybe<Scalars['String']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  sensorNode?: Maybe<Scalars['String']['output']>;
  webNotification?: Maybe<Notification>;
};

export type ProfilingRef = {
  decimalDigits?: InputMaybe<Scalars['Int']['input']>;
  emailNotification?: InputMaybe<NotificationRef>;
  language?: InputMaybe<Scalars['String']['input']>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  sensorNode?: InputMaybe<Scalars['String']['input']>;
  webNotification?: InputMaybe<NotificationRef>;
};

export type PushMeasure = {
  measures?: InputMaybe<Array<InputMaybe<MeasureKVinput>>>;
  sensorNodeSerial: Scalars['String']['input'];
  timestamp: Scalars['String']['input'];
};

export type PushStatus = {
  measures?: InputMaybe<Array<InputMaybe<StatusKVinput>>>;
  sensorNodeSerial: Scalars['String']['input'];
  timestamp: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  allDiagnosticSensors?: Maybe<Array<Maybe<DiagnosticSensor>>>;
  allOrganizations?: Maybe<Array<Maybe<Organization>>>;
  allSensorNodes?: Maybe<Array<Maybe<SensorNode>>>;
  allSensors?: Maybe<Array<Maybe<Sensor>>>;
  allUsers?: Maybe<Array<Maybe<User>>>;
  averageChartData: Array<Maybe<ChartSerie>>;
  chartData: Array<Maybe<ChartSerie>>;
  charts: ChartsSerie;
  dayNightAverage?: Maybe<DayNightCalculationResult>;
  dayTimeAverage?: Maybe<CalculationResult>;
  degreeDays?: Maybe<CalculationResult>;
  diagnosticSensor?: Maybe<DiagnosticSensor>;
  ephemeris: Array<Maybe<EphemerisOutput>>;
  evapotranspiration?: Maybe<CalculationResult>;
  getDashboardMeasures: Array<Maybe<DashboardMeasuresString>>;
  getDiseasePredictions: Array<Maybe<DiseasePrediction>>;
  getDiseaseSpots: Array<Maybe<DiseaseSpot>>;
  getDownyMildewPredictions: Array<Maybe<DownyMildewPrediction>>;
  getDownyMildewSpot: Array<Maybe<DownyMildewSpot>>;
  getSensorNodesLastPosition: Array<Maybe<SensorNodeLastPosition>>;
  getTimezone: TimezoneOutput;
  groupAttributes: GroupAttributesResult;
  invalidData: Array<Maybe<InvalidData>>;
  measures?: Maybe<Scalars['MeasuresResult']['output']>;
  nightTimeAverage?: Maybe<CalculationResult>;
  organization?: Maybe<Organization>;
  radiationSum?: Maybe<CalculationResult>;
  sensor?: Maybe<Sensor>;
  sensorNode?: Maybe<SensorNode>;
  status?: Maybe<Scalars['MeasuresResult']['output']>;
  user?: Maybe<UserExtended>;
  userAttributes: UserAttributesResult;
  userGroups: UserGroupsResult;
  userThreshold?: Maybe<UserThreshold>;
};

export type QueryAllOrganizationsArgs = {
  userFilter?: InputMaybe<UserFilter>;
};

export type QueryAllSensorNodesArgs = {
  userFilter?: InputMaybe<UserFilter>;
};

export type QueryAllUsersArgs = {
  userFilter?: InputMaybe<UserFilter>;
};

export type QueryAverageChartDataArgs = {
  input: AverageChartDataInput;
};

export type QueryChartDataArgs = {
  input: ChartDataInput;
};

export type QueryChartsArgs = {
  input: ChartsDataInput;
};

export type QueryDayNightAverageArgs = {
  input: DayNightAverageInput;
};

export type QueryDayTimeAverageArgs = {
  input: DayTimeAverageInput;
};

export type QueryDegreeDaysArgs = {
  input: DegreeDaysInput;
};

export type QueryDiagnosticSensorArgs = {
  type: Scalars['String']['input'];
};

export type QueryEphemerisArgs = {
  input: EphemerisInput;
};

export type QueryEvapotranspirationArgs = {
  input: EvapotranspirationInput;
};

export type QueryGetDashboardMeasuresArgs = {
  input: GetDashboardMeasuresInput;
};

export type QueryGetDiseasePredictionsArgs = {
  input: GetDiseasePredictionsInput;
};

export type QueryGetDiseaseSpotsArgs = {
  input: GetDiseaseSpotsInput;
};

export type QueryGetDownyMildewPredictionsArgs = {
  input: GetDownyMildewPredictionsInput;
};

export type QueryGetDownyMildewSpotArgs = {
  input: GetDownyMildewSpotInput;
};

export type QueryGetSensorNodesLastPositionArgs = {
  input: GetSensorNodesLastPositionInput;
};

export type QueryGetTimezoneArgs = {
  input: TimezoneInput;
};

export type QueryGroupAttributesArgs = {
  group: Scalars['String']['input'];
};

export type QueryInvalidDataArgs = {
  input: InvalidDataInput;
};

export type QueryMeasuresArgs = {
  input: FetchMeasure;
};

export type QueryNightTimeAverageArgs = {
  input: NightTimeAverageInput;
};

export type QueryOrganizationArgs = {
  name: Scalars['String']['input'];
  userFilter?: InputMaybe<UserFilter>;
};

export type QueryRadiationSumArgs = {
  input: RadiationSumInput;
};

export type QuerySensorArgs = {
  type: Scalars['String']['input'];
};

export type QuerySensorNodeArgs = {
  serialNumber: Scalars['String']['input'];
  userFilter?: InputMaybe<UserFilter>;
};

export type QueryStatusArgs = {
  input: FetchStatus;
};

export type QueryUserArgs = {
  username: Scalars['String']['input'];
};

export type QueryUserAttributesArgs = {
  username: Scalars['String']['input'];
};

export type QueryUserGroupsArgs = {
  username: Scalars['String']['input'];
};

export type QueryUserThresholdArgs = {
  id: Scalars['String']['input'];
};

export type RadiationSumInput = {
  endDate: Scalars['String']['input'];
  sensor: Scalars['String']['input'];
  sensorNodeSerialNumber: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

export enum Rate {
  Hor = 'hor',
  Opt = 'opt',
  Vert = 'vert',
}

export type ResourceAccess = {
  __typename?: 'ResourceAccess';
  account?: Maybe<Roles>;
};

export enum Role {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  User = 'USER',
}

export type Roles = {
  __typename?: 'Roles';
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type Sensor = {
  __typename?: 'Sensor';
  deleted: Scalars['Boolean']['output'];
  echarts?: Maybe<Echarts>;
  echartsAverage?: Maybe<Echarts>;
  id: Scalars['String']['output'];
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  multiplier?: Maybe<Scalars['Float']['output']>;
  round?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
  unitMeasure?: Maybe<Scalars['String']['output']>;
};

export type SensorNode = {
  __typename?: 'SensorNode';
  deleted: Scalars['Boolean']['output'];
  diagnosticSensors?: Maybe<Array<Maybe<DiagnosticSensor>>>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sensors?: Maybe<Array<Maybe<Sensor>>>;
  serialNumber: Scalars['String']['output'];
  users?: Maybe<Array<Maybe<User>>>;
  diseases?: Maybe<Array<Maybe<['String']>>>;
};

export type SensorNodeLastPosition = {
  __typename?: 'SensorNodeLastPosition';
  gpsp?: Maybe<Scalars['String']['output']>;
  sensorNodeSerial: Scalars['String']['output'];
  timezone?: Maybe<Scalars['String']['output']>;
};

export type SerialNumberRef = {
  serialNumber?: InputMaybe<Scalars['String']['input']>;
};

export type Status = {
  __typename?: 'Status';
  id: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type StatusKVinput = {
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  lastMeasure?: Maybe<MeasureString>;
  lastStatus?: Maybe<Status>;
};

export type SubscriptionLastMeasureArgs = {
  sensorNodeSerial: Scalars['String']['input'];
};

export type SubscriptionLastStatusArgs = {
  sensorNodeSerial: Scalars['String']['input'];
};

export type TimestampValue = {
  __typename?: 'TimestampValue';
  timestamp?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type TimezoneInput = {
  endDate: Scalars['String']['input'];
  sensorNodeSerial: Scalars['String']['input'];
  sensorTimeZone: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

export type TimezoneOutput = {
  __typename?: 'TimezoneOutput';
  count?: Maybe<Scalars['Int']['output']>;
  timezones?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type TokenInfo = {
  __typename?: 'TokenInfo';
  acr?: Maybe<Scalars['String']['output']>;
  active?: Maybe<Scalars['Boolean']['output']>;
  allowedOrigins?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  aud?: Maybe<Scalars['String']['output']>;
  azp?: Maybe<Scalars['String']['output']>;
  clientID?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  exp?: Maybe<Scalars['Int']['output']>;
  iat?: Maybe<Scalars['Int']['output']>;
  iss?: Maybe<Scalars['String']['output']>;
  jti?: Maybe<Scalars['String']['output']>;
  preferredUsername?: Maybe<Scalars['String']['output']>;
  realmAccess?: Maybe<Roles>;
  resourceAccess?: Maybe<ResourceAccess>;
  scope?: Maybe<Scalars['String']['output']>;
  sessionState?: Maybe<Scalars['String']['output']>;
  sub?: Maybe<Scalars['String']['output']>;
  typ?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type TypeDiagnosticSensorRef = {
  type?: InputMaybe<Scalars['String']['input']>;
};

export type TypeSensorRef = {
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDiagnosticSensorInput = {
  type: Scalars['String']['input'];
  updateDiagnosticSensorPatch: UpdateDiagnosticSensorPatch;
};

export type UpdateDiagnosticSensorPatch = {
  set?: InputMaybe<UpdateDiagnosticSensorPatchSet>;
};

export type UpdateDiagnosticSensorPatchSet = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  max?: InputMaybe<Scalars['String']['input']>;
  min?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  unitMeasure?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGroupAttributeInput = {
  attribute: Scalars['String']['input'];
  group: Scalars['String']['input'];
  merge: Scalars['Boolean']['input'];
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateGroupAttributeResponse = {
  __typename?: 'UpdateGroupAttributeResponse';
  status: Scalars['String']['output'];
};

export type UpdateGroupAttributesInput = {
  attributes?: InputMaybe<Array<GroupAttribute>>;
  group: Scalars['String']['input'];
  merge: Scalars['Boolean']['input'];
};

export type UpdateGroupAttributesResponse = {
  __typename?: 'UpdateGroupAttributesResponse';
  status: Scalars['String']['output'];
};

export type UpdateOrganizationInput = {
  eid: Scalars['String']['input'];
  updateOrganizationPatch: UpdateOrganizationPatch;
};

export type UpdateOrganizationPatch = {
  remove?: InputMaybe<UpdateOrganizationPatchRemove>;
  set?: InputMaybe<UpdateOrganizationPatchSet>;
};

export type UpdateOrganizationPatchRemove = {
  organizations?: InputMaybe<Array<InputMaybe<EidRef>>>;
  users?: InputMaybe<Array<InputMaybe<UsernameRef>>>;
};

export type UpdateOrganizationPatchSet = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  vatid?: InputMaybe<Scalars['String']['input']>;
  organizations?: InputMaybe<Array<InputMaybe<EidRef>>>;
  parent?: InputMaybe<Scalars['Boolean']['input']>;
  users?: InputMaybe<Array<InputMaybe<UsernameRef>>>;
};

export type UpdateSensorInput = {
  type: Scalars['String']['input'];
  updateSensorPatch: UpdateSensorPatch;
};

export type UpdateSensorNodeInput = {
  serialNumber: Scalars['String']['input'];
  updateSensorNodePatch: UpdateSensorNodePatch;
};

export type UpdateSensorNodePatch = {
  remove?: InputMaybe<UpdateSensorNodePatchRemove>;
  set?: InputMaybe<UpdateSensorNodePatchSet>;
};

export type UpdateSensorNodePatchRemove = {
  diagnosticSensors?: InputMaybe<Array<InputMaybe<TypeDiagnosticSensorRef>>>;
  sensors?: InputMaybe<Array<InputMaybe<TypeSensorRef>>>;
  diseases?: InputMaybe<Array<InputMaybe<['String']>>>;
};

export type UpdateSensorNodePatchSet = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  diagnosticSensors?: InputMaybe<Array<InputMaybe<TypeDiagnosticSensorRef>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  sensors?: InputMaybe<Array<InputMaybe<TypeSensorRef>>>;
  diseases?: InputMaybe<Array<InputMaybe<['String']>>>;
};

export type UpdateSensorNodePatchUserSet = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSensorNodeUserInput = {
  serialNumber: Scalars['String']['input'];
  updateSensorNodePatch: UpdateSensorNodeUserPatch;
};

export type UpdateSensorNodeUserPatch = {
  set?: InputMaybe<UpdateSensorNodePatchUserSet>;
};

export type UpdateSensorPatch = {
  set?: InputMaybe<UpdateSensorPatchSet>;
};

export type UpdateSensorPatchSet = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  echarts?: InputMaybe<EchartsRef>;
  echartsAverage?: InputMaybe<EchartsRef>;
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  multiplier?: InputMaybe<Scalars['Float']['input']>;
  round?: InputMaybe<Scalars['Int']['input']>;
  unitMeasure?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserAttributeInput = {
  attribute: Scalars['String']['input'];
  merge: Scalars['Boolean']['input'];
  username: Scalars['String']['input'];
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateUserAttributeResponse = {
  __typename?: 'UpdateUserAttributeResponse';
  status: Scalars['String']['output'];
};

export type UpdateUserAttributesInput = {
  attributes?: InputMaybe<Array<UserAttribute>>;
  merge: Scalars['Boolean']['input'];
  username: Scalars['String']['input'];
};

export type UpdateUserAttributesResponse = {
  __typename?: 'UpdateUserAttributesResponse';
  status: Scalars['String']['output'];
};

export type UpdateUserExtendedAdminInput = {
  updateUserPatch: UpdateUserExtendedAdminPatch;
  username: Scalars['String']['input'];
};

export type UpdateUserExtendedAdminPatch = {
  remove?: InputMaybe<UpdateUserPatchRemove>;
  set?: InputMaybe<UpdateUserExtendedAdminPatchSet>;
};

export type UpdateUserExtendedAdminPatchSet = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<EidRef>;
  profiling?: InputMaybe<ProfilingRef>;
  sensorNodes?: InputMaybe<Array<InputMaybe<SerialNumberRef>>>;
  thresholds?: InputMaybe<Array<InputMaybe<IdRef>>>;
  userRole?: InputMaybe<Role>;
};

export type UpdateUserExtendedInput = {
  updateUserPatch: UpdateUserExtendedPatch;
  username: Scalars['String']['input'];
};

export type UpdateUserExtendedPatch = {
  set?: InputMaybe<UpdateUserExtendedPatchSet>;
};

export type UpdateUserExtendedPatchSet = {
  name?: InputMaybe<Scalars['String']['input']>;
  profiling?: InputMaybe<ProfilingRef>;
  thresholds?: InputMaybe<Array<InputMaybe<IdRef>>>;
};

export type UpdateUserInput = {
  updateUserPatch: UpdateUserPatch;
  username: Scalars['String']['input'];
};

export type UpdateUserPasswordInput = {
  confirmNewPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type UpdateUserPasswordResponse = {
  __typename?: 'UpdateUserPasswordResponse';
  status: Scalars['String']['output'];
};

export type UpdateUserPatch = {
  remove?: InputMaybe<UpdateUserPatchRemove>;
  set?: InputMaybe<UpdateUserPatchSet>;
};

export type UpdateUserPatchRemove = {
  organization?: InputMaybe<EidRef>;
  sensorNodes?: InputMaybe<Array<InputMaybe<SerialNumberRef>>>;
  thresholds?: InputMaybe<Array<InputMaybe<IdRef>>>;
};

export type UpdateUserPatchSet = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<EidRef>;
  profiling?: InputMaybe<ProfilingRef>;
  sensorNodes?: InputMaybe<Array<InputMaybe<SerialNumberRef>>>;
  thresholds?: InputMaybe<Array<InputMaybe<IdRef>>>;
};

export type UpdateUserPatchUserRemove = {
  thresholds?: InputMaybe<Array<InputMaybe<IdRef>>>;
};

export type UpdateUserThresholdInput = {
  id: Scalars['String']['input'];
  updateUserThresholdPatch: UpdateUserThresholdPatch;
};

export type UpdateUserThresholdPatch = {
  set?: InputMaybe<UpdateUserThresholdPatchSet>;
};

export type UpdateUserThresholdPatchSet = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
  sensorNodeSerial?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UploadExtend = {
  delay: Scalars['Int']['input'];
  upload: Scalars['Upload']['input'];
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  profiling?: Maybe<Profiling>;
  sensorNodes?: Maybe<Array<Maybe<SensorNode>>>;
  thresholds?: Maybe<Array<Maybe<UserThreshold>>>;
  username: Scalars['String']['output'];
};

export type UserAttribute = {
  key: Scalars['String']['input'];
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserAttributeResult = {
  __typename?: 'UserAttributeResult';
  key: Scalars['String']['output'];
  value?: Maybe<Array<Scalars['String']['output']>>;
};

export type UserAttributesResult = {
  __typename?: 'UserAttributesResult';
  attributes?: Maybe<Array<UserAttributeResult>>;
};

export type UserExtended = {
  __typename?: 'UserExtended';
  active: Scalars['Boolean']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  profiling?: Maybe<Profiling>;
  sensorNodes?: Maybe<Array<Maybe<SensorNode>>>;
  thresholds?: Maybe<Array<Maybe<UserThreshold>>>;
  userRole?: Maybe<Role>;
  username: Scalars['String']['output'];
};

export type UserFilter = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export type UserGroupsResult = {
  __typename?: 'UserGroupsResult';
  groups?: Maybe<Array<UserGroup>>;
};

export type UserThreshold = {
  __typename?: 'UserThreshold';
  enabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
  sensorNodeSerial: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type UsernameRef = {
  username?: InputMaybe<Scalars['String']['input']>;
};

export type GetChartDataQueryVariables = Exact<{
  input: ChartDataInput;
}>;

export type GetChartDataQuery = {
  __typename?: 'Query';
  chartData: Array<{
    __typename?: 'ChartSerie';
    name: string;
    type: string;
    yAxisIndex: number;
    symbol: string;
    symbolSize: number;
    smoothed: boolean;
    itemStyle: { __typename?: 'ItemStyle'; color: string };
    lineStyle: { __typename?: 'LineStyle'; type: string; width: number };
    data?: Array<{
      __typename?: 'TimestampValue';
      timestamp?: string | null;
      value?: number | null;
    } | null> | null;
  } | null>;
};

export type UpdateUserPasswordMutationVariables = Exact<{
  input: UpdateUserPasswordInput;
}>;

export type UpdateUserPasswordMutation = {
  __typename?: 'Mutation';
  updateUserPassword: {
    __typename?: 'UpdateUserPasswordResponse';
    status: string;
  };
};

export type AddSensorMutationVariables = Exact<{
  input: AddSensorInput;
}>;

export type AddSensorMutation = {
  __typename?: 'Mutation';
  addSensor?: {
    __typename?: 'Sensor';
    id: string;
    type: string;
    deleted: boolean;
    unitMeasure?: string | null;
    model?: string | null;
    min?: number | null;
    max?: number | null;
    multiplier?: number | null;
    round?: number | null;
    echarts?: {
      __typename?: 'Echarts';
      type?: string | null;
      dashOffset?: number | null;
      color?: string | null;
      width?: number | null;
      symbol?: string | null;
      symbolSize?: number | null;
      position?: string | null;
    } | null;
    echartsAverage?: {
      __typename?: 'Echarts';
      type?: string | null;
      dashOffset?: number | null;
      color?: string | null;
      width?: number | null;
      symbol?: string | null;
      symbolSize?: number | null;
      position?: string | null;
    } | null;
  } | null;
};

export type UpdateSensorMutationVariables = Exact<{
  input: UpdateSensorInput;
}>;

export type UpdateSensorMutation = {
  __typename?: 'Mutation';
  updateSensor?: {
    __typename?: 'Sensor';
    id: string;
    type: string;
    deleted: boolean;
    unitMeasure?: string | null;
    model?: string | null;
    min?: number | null;
    max?: number | null;
    multiplier?: number | null;
    round?: number | null;
    echarts?: {
      __typename?: 'Echarts';
      type?: string | null;
      dashOffset?: number | null;
      color?: string | null;
      width?: number | null;
      symbol?: string | null;
      symbolSize?: number | null;
      position?: string | null;
    } | null;
    echartsAverage?: {
      __typename?: 'Echarts';
      type?: string | null;
      dashOffset?: number | null;
      color?: string | null;
      width?: number | null;
      symbol?: string | null;
      symbolSize?: number | null;
      position?: string | null;
    } | null;
  } | null;
};

export type AddDiagnosticSensorMutationVariables = Exact<{
  input: AddDiagnosticSensorInput;
}>;

export type AddDiagnosticSensorMutation = {
  __typename?: 'Mutation';
  addDiagnosticSensor?: {
    __typename?: 'DiagnosticSensor';
    id: string;
    type: string;
    deleted: boolean;
    model?: string | null;
    min?: string | null;
    max?: string | null;
    unitMeasure?: string | null;
  } | null;
};

export type UpdateDiagnosticSensorMutationVariables = Exact<{
  input: UpdateDiagnosticSensorInput;
}>;

export type UpdateDiagnosticSensorMutation = {
  __typename?: 'Mutation';
  updateDiagnosticSensor?: {
    __typename?: 'DiagnosticSensor';
    id: string;
    type: string;
    model?: string | null;
    min?: string | null;
    max?: string | null;
    deleted: boolean;
    unitMeasure?: string | null;
  } | null;
};

export type AddSensorNodeMutationVariables = Exact<{
  input: AddSensorNodeInput;
}>;

export type AddSensorNodeMutation = {
  __typename?: 'Mutation';
  addSensorNode?: {
    __typename?: 'SensorNode';
    id: string;
    serialNumber: string;
    name: string;
    deleted: boolean;
    sensors?: Array<{
      __typename?: 'Sensor';
      id: string;
      type: string;
      deleted: boolean;
      unitMeasure?: string | null;
      model?: string | null;
      min?: number | null;
      max?: number | null;
      multiplier?: number | null;
      round?: number | null;
      echarts?: {
        __typename?: 'Echarts';
        type?: string | null;
        dashOffset?: number | null;
        color?: string | null;
        width?: number | null;
        symbol?: string | null;
        symbolSize?: number | null;
        position?: string | null;
      } | null;
      echartsAverage?: {
        __typename?: 'Echarts';
        type?: string | null;
        dashOffset?: number | null;
        color?: string | null;
        width?: number | null;
        symbol?: string | null;
        symbolSize?: number | null;
        position?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetAverageChartDataQueryVariables = Exact<{
  input: AverageChartDataInput;
}>;

export type GetAverageChartDataQuery = {
  __typename?: 'Query';
  averageChartData: Array<{
    __typename?: 'ChartSerie';
    name: string;
    type: string;
    yAxisIndex: number;
    symbol: string;
    symbolSize: number;
    smoothed: boolean;
    itemStyle: { __typename?: 'ItemStyle'; color: string };
    lineStyle: { __typename?: 'LineStyle'; type: string; width: number };
    data?: Array<{
      __typename?: 'TimestampValue';
      timestamp?: string | null;
      value?: number | null;
    } | null> | null;
  } | null>;
};

export type FetchMeasuresQueryVariables = Exact<{
  input: FetchMeasure;
}>;

export type FetchMeasuresQuery = {
  __typename?: 'Query';
  measures?: any | null;
};

export type GetDashboardMeasuresQueryVariables = Exact<{
  input: GetDashboardMeasuresInput;
}>;

export type GetDashboardMeasuresQuery = {
  __typename?: 'Query';
  getDashboardMeasures: Array<{
    __typename?: 'DashboardMeasuresString';
    sensor: string;
    measures: Array<{
      __typename?: 'MeasureString';
      id: string;
      timestamp: string;
      type?: string | null;
      value?: string | null;
    } | null>;
  } | null>;
};

export type FetchStatusQueryVariables = Exact<{
  input: FetchStatus;
}>;

export type FetchStatusQuery = { __typename?: 'Query'; status?: any | null };

export type UpdateUserAdminMutationVariables = Exact<{
  input: UpdateUserExtendedAdminInput;
}>;

export type UpdateUserAdminMutation = {
  __typename?: 'Mutation';
  updateUserAdmin?: {
    __typename?: 'UserExtended';
    id: string;
    username: string;
    name?: string | null;
    userRole?: Role | null;
    active: boolean;
    deleted: boolean;
    organization?: {
      __typename?: 'Organization';
      id: string;
      eid: string;
      name: string;
      parent: boolean;
      active: boolean;
      deleted: boolean;
    } | null;
    sensorNodes?: Array<{
      __typename?: 'SensorNode';
      id: string;
      name: string;
      serialNumber: string;
      deleted: boolean;
      sensors?: Array<{
        __typename?: 'Sensor';
        id: string;
        type: string;
        deleted: boolean;
        model?: string | null;
        unitMeasure?: string | null;
        min?: number | null;
        max?: number | null;
        multiplier?: number | null;
        round?: number | null;
        echarts?: {
          __typename?: 'Echarts';
          type?: string | null;
          dashOffset?: number | null;
          color?: string | null;
          width?: number | null;
          symbol?: string | null;
          symbolSize?: number | null;
          position?: string | null;
        } | null;
        echartsAverage?: {
          __typename?: 'Echarts';
          type?: string | null;
          dashOffset?: number | null;
          color?: string | null;
          width?: number | null;
          symbol?: string | null;
          symbolSize?: number | null;
          position?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
    thresholds?: Array<{
      __typename?: 'UserThreshold';
      id: string;
      sensorNodeSerial: string;
      type: string;
      min?: number | null;
      max?: number | null;
    } | null> | null;
    profiling?: {
      __typename?: 'Profiling';
      profileImage?: string | null;
      decimalDigits?: number | null;
      sensorNode?: string | null;
      language?: string | null;
      emailNotification?: {
        __typename?: 'Notification';
        alert?: boolean | null;
        disease?: boolean | null;
      } | null;
      webNotification?: {
        __typename?: 'Notification';
        alert?: boolean | null;
        disease?: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserExtendedInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'UserExtended';
    id: string;
    username: string;
    name?: string | null;
    userRole?: Role | null;
    active: boolean;
    deleted: boolean;
    organization?: {
      __typename?: 'Organization';
      id: string;
      eid: string;
      name: string;
      parent: boolean;
      active: boolean;
      deleted: boolean;
    } | null;
    sensorNodes?: Array<{
      __typename?: 'SensorNode';
      id: string;
      name: string;
      serialNumber: string;
      deleted: boolean;
      sensors?: Array<{
        __typename?: 'Sensor';
        id: string;
        type: string;
        deleted: boolean;
        model?: string | null;
        unitMeasure?: string | null;
        min?: number | null;
        max?: number | null;
        multiplier?: number | null;
        round?: number | null;
        echarts?: {
          __typename?: 'Echarts';
          type?: string | null;
          dashOffset?: number | null;
          color?: string | null;
          width?: number | null;
          symbol?: string | null;
          symbolSize?: number | null;
          position?: string | null;
        } | null;
        echartsAverage?: {
          __typename?: 'Echarts';
          type?: string | null;
          dashOffset?: number | null;
          color?: string | null;
          width?: number | null;
          symbol?: string | null;
          symbolSize?: number | null;
          position?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
    thresholds?: Array<{
      __typename?: 'UserThreshold';
      id: string;
      sensorNodeSerial: string;
      type: string;
      min?: number | null;
      max?: number | null;
    } | null> | null;
    profiling?: {
      __typename?: 'Profiling';
      profileImage?: string | null;
      decimalDigits?: number | null;
      sensorNode?: string | null;
      language?: string | null;
      emailNotification?: {
        __typename?: 'Notification';
        alert?: boolean | null;
        disease?: boolean | null;
      } | null;
      webNotification?: {
        __typename?: 'Notification';
        alert?: boolean | null;
        disease?: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateUserThresholdMutationVariables = Exact<{
  input: UpdateUserThresholdInput;
}>;

export type UpdateUserThresholdMutation = {
  __typename?: 'Mutation';
  updateUserThreshold?: {
    __typename?: 'UserThreshold';
    id: string;
    sensorNodeSerial: string;
    type: string;
    enabled: boolean;
    min?: number | null;
    max?: number | null;
  } | null;
};

export type AddUserThresholdMutationVariables = Exact<{
  input: AddUserThresholdInput;
}>;

export type AddUserThresholdMutation = {
  __typename?: 'Mutation';
  addUserThreshold?: {
    __typename?: 'UserThreshold';
    id: string;
    sensorNodeSerial: string;
    type: string;
    enabled: boolean;
    min?: number | null;
    max?: number | null;
  } | null;
};

export type LastMeasureSubscriptionVariables = Exact<{
  sensorNodeSerial: Scalars['String']['input'];
}>;

export type LastMeasureSubscription = {
  __typename?: 'Subscription';
  lastMeasure?: {
    __typename?: 'MeasureString';
    id: string;
    timestamp: string;
    type?: string | null;
    value?: string | null;
  } | null;
};

export type LastStatusSubscriptionVariables = Exact<{
  sensorNodeSerial: Scalars['String']['input'];
}>;

export type LastStatusSubscription = {
  __typename?: 'Subscription';
  lastStatus?: {
    __typename?: 'Status';
    id: string;
    timestamp: string;
    type?: string | null;
    value?: string | null;
  } | null;
};

export type RadiationSumQueryVariables = Exact<{
  input: RadiationSumInput;
}>;

export type RadiationSumQuery = {
  __typename?: 'Query';
  radiationSum?: {
    __typename?: 'CalculationResult';
    result?: string | null;
  } | null;
};

export type EvapotranspirationQueryVariables = Exact<{
  input: EvapotranspirationInput;
}>;

export type EvapotranspirationQuery = {
  __typename?: 'Query';
  evapotranspiration?: {
    __typename?: 'CalculationResult';
    result?: string | null;
  } | null;
};

export type DegreeDaysQueryVariables = Exact<{
  input: DegreeDaysInput;
}>;

export type DegreeDaysQuery = {
  __typename?: 'Query';
  degreeDays?: {
    __typename?: 'CalculationResult';
    result?: string | null;
  } | null;
};

export type FetchUserQueryVariables = Exact<{
  username: Scalars['String']['input'];
}>;

export type FetchUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserExtended';
    id: string;
    username: string;
    name?: string | null;
    userRole?: Role | null;
    active: boolean;
    deleted: boolean;
    organization?: {
      __typename?: 'Organization';
      id: string;
      eid: string;
      name: string;
      parent: boolean;
      active: boolean;
      deleted: boolean;
    } | null;
    sensorNodes?: Array<{
      __typename?: 'SensorNode';
      id: string;
      name: string;
      serialNumber: string;
      deleted: boolean;
      sensors?: Array<{
        __typename?: 'Sensor';
        id: string;
        type: string;
        deleted: boolean;
        unitMeasure?: string | null;
        model?: string | null;
        min?: number | null;
        max?: number | null;
        multiplier?: number | null;
        round?: number | null;
        echarts?: {
          __typename?: 'Echarts';
          type?: string | null;
          dashOffset?: number | null;
          color?: string | null;
          width?: number | null;
          symbol?: string | null;
          symbolSize?: number | null;
          position?: string | null;
        } | null;
        echartsAverage?: {
          __typename?: 'Echarts';
          type?: string | null;
          dashOffset?: number | null;
          color?: string | null;
          width?: number | null;
          symbol?: string | null;
          symbolSize?: number | null;
          position?: string | null;
        } | null;
      } | null> | null;
      diagnosticSensors?: Array<{
        __typename?: 'DiagnosticSensor';
        id: string;
        type: string;
        deleted: boolean;
        model?: string | null;
        min?: string | null;
        max?: string | null;
        unitMeasure?: string | null;
      } | null> | null;
    } | null> | null;
    thresholds?: Array<{
      __typename?: 'UserThreshold';
      id: string;
      sensorNodeSerial: string;
      type: string;
      enabled: boolean;
      min?: number | null;
      max?: number | null;
    } | null> | null;
    profiling?: {
      __typename?: 'Profiling';
      language?: string | null;
      sensorNode?: string | null;
      profileImage?: string | null;
      decimalDigits?: number | null;
      emailNotification?: {
        __typename?: 'Notification';
        alert?: boolean | null;
        disease?: boolean | null;
      } | null;
      webNotification?: {
        __typename?: 'Notification';
        alert?: boolean | null;
        disease?: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type GetSensorNodeQueryVariables = Exact<{
  input: Scalars['String']['input'];
}>;

export type GetSensorNodeQuery = {
  __typename?: 'Query';
  sensorNode?: {
    __typename?: 'SensorNode';
    id: string;
    name: string;
    serialNumber: string;
    deleted: boolean;
    sensors?: Array<{
      __typename?: 'Sensor';
      id: string;
      type: string;
      deleted: boolean;
      unitMeasure?: string | null;
      model?: string | null;
      min?: number | null;
      max?: number | null;
      multiplier?: number | null;
      round?: number | null;
      echarts?: {
        __typename?: 'Echarts';
        type?: string | null;
        dashOffset?: number | null;
        color?: string | null;
        width?: number | null;
        symbol?: string | null;
        symbolSize?: number | null;
        position?: string | null;
      } | null;
      echartsAverage?: {
        __typename?: 'Echarts';
        type?: string | null;
        dashOffset?: number | null;
        color?: string | null;
        width?: number | null;
        symbol?: string | null;
        symbolSize?: number | null;
        position?: string | null;
      } | null;
    } | null> | null;
    diagnosticSensors?: Array<{
      __typename?: 'DiagnosticSensor';
      id: string;
      type: string;
      model?: string | null;
      min?: string | null;
      max?: string | null;
      deleted: boolean;
    } | null> | null;
  } | null;
};

export type GetAllOrganizationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllOrganizationsQuery = {
  __typename?: 'Query';
  allOrganizations?: Array<{
    __typename?: 'Organization';
    eid: string;
    name: string;
    parent: boolean;
    active: boolean;
    deleted: boolean;
    organizations?: Array<{
      __typename?: 'Organization';
      eid: string;
      name: string;
      active: boolean;
      deleted: boolean;
    } | null> | null;
    users?: Array<{
      __typename?: 'User';
      id: string;
      username: string;
      name?: string | null;
      active: boolean;
      deleted: boolean;
      organization?: { __typename?: 'Organization'; eid: string } | null;
      sensorNodes?: Array<{
        __typename?: 'SensorNode';
        id: string;
        serialNumber: string;
        name: string;
        deleted: boolean;
        sensors?: Array<{
          __typename?: 'Sensor';
          id: string;
          type: string;
          deleted: boolean;
          unitMeasure?: string | null;
          model?: string | null;
          min?: number | null;
          max?: number | null;
          multiplier?: number | null;
          round?: number | null;
          echarts?: {
            __typename?: 'Echarts';
            type?: string | null;
            dashOffset?: number | null;
            color?: string | null;
            width?: number | null;
            symbol?: string | null;
            symbolSize?: number | null;
            position?: string | null;
          } | null;
          echartsAverage?: {
            __typename?: 'Echarts';
            type?: string | null;
            dashOffset?: number | null;
            color?: string | null;
            width?: number | null;
            symbol?: string | null;
            symbolSize?: number | null;
            position?: string | null;
          } | null;
        } | null> | null;
      } | null> | null;
      profiling?: {
        __typename?: 'Profiling';
        profileImage?: string | null;
        decimalDigits?: number | null;
        sensorNode?: string | null;
        language?: string | null;
        emailNotification?: {
          __typename?: 'Notification';
          alert?: boolean | null;
          disease?: boolean | null;
        } | null;
        webNotification?: {
          __typename?: 'Notification';
          alert?: boolean | null;
          disease?: boolean | null;
        } | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type GetAllSensorNodesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllSensorNodesQuery = {
  __typename?: 'Query';
  allSensorNodes?: Array<{
    __typename?: 'SensorNode';
    id: string;
    serialNumber: string;
    name: string;
    deleted: boolean;
    active: boolean;
    sensors?: Array<{
      __typename?: 'Sensor';
      id: string;
      type: string;
      deleted: boolean;
      unitMeasure?: string | null;
      model?: string | null;
      min?: number | null;
      max?: number | null;
      multiplier?: number | null;
      round?: number | null;
      echarts?: {
        __typename?: 'Echarts';
        type?: string | null;
        dashOffset?: number | null;
        color?: string | null;
        width?: number | null;
        symbol?: string | null;
        symbolSize?: number | null;
        position?: string | null;
      } | null;
      echartsAverage?: {
        __typename?: 'Echarts';
        type?: string | null;
        dashOffset?: number | null;
        color?: string | null;
        width?: number | null;
        symbol?: string | null;
        symbolSize?: number | null;
        position?: string | null;
      } | null;
    } | null> | null;
    diagnosticSensors?: Array<{
      __typename?: 'DiagnosticSensor';
      id: string;
      type: string;
      deleted: boolean;
      model?: string | null;
      min?: string | null;
      max?: string | null;
      unitMeasure?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetAllSensorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllSensorsQuery = {
  __typename?: 'Query';
  allSensors?: Array<{
    __typename?: 'Sensor';
    id: string;
    type: string;
    deleted: boolean;
    unitMeasure?: string | null;
    model?: string | null;
    min?: number | null;
    max?: number | null;
    multiplier?: number | null;
    round?: number | null;
    echarts?: {
      __typename?: 'Echarts';
      type?: string | null;
      dashOffset?: number | null;
      color?: string | null;
      width?: number | null;
      symbol?: string | null;
      symbolSize?: number | null;
      position?: string | null;
    } | null;
    echartsAverage?: {
      __typename?: 'Echarts';
      type?: string | null;
      dashOffset?: number | null;
      color?: string | null;
      width?: number | null;
      symbol?: string | null;
      symbolSize?: number | null;
      position?: string | null;
    } | null;
  } | null> | null;
};

export type GetAllDiagnosticSensorsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllDiagnosticSensorsQuery = {
  __typename?: 'Query';
  allDiagnosticSensors?: Array<{
    __typename?: 'DiagnosticSensor';
    id: string;
    type: string;
    deleted: boolean;
    model?: string | null;
    min?: string | null;
    max?: string | null;
    unitMeasure?: string | null;
  } | null> | null;
};

export type AddOrganizationMutationVariables = Exact<{
  input: AddOrganizationInput;
}>;

export type AddOrganizationMutation = {
  __typename?: 'Mutation';
  addOrganization?: {
    __typename?: 'Organization';
    id: string;
    eid: string;
    name: string;
    parent: boolean;
    active: boolean;
    deleted: boolean;
  } | null;
};

export type UpdateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;

export type UpdateOrganizationMutation = {
  __typename?: 'Mutation';
  updateOrganization?: {
    __typename?: 'Organization';
    eid: string;
    name: string;
    vatid: string;
    parent: boolean;
    active: boolean;
    deleted: boolean;
    organizations?: Array<{
      __typename?: 'Organization';
      eid: string;
      name: string;
      active: boolean;
      deleted: boolean;
    } | null> | null;
    users?: Array<{
      __typename?: 'User';
      id: string;
      username: string;
      name?: string | null;
      active: boolean;
      deleted: boolean;
      organization?: { __typename?: 'Organization'; eid: string } | null;
      sensorNodes?: Array<{
        __typename?: 'SensorNode';
        id: string;
        serialNumber: string;
        name: string;
        deleted: boolean;
        sensors?: Array<{
          __typename?: 'Sensor';
          id: string;
          type: string;
          deleted: boolean;
          unitMeasure?: string | null;
          model?: string | null;
          min?: number | null;
          max?: number | null;
          multiplier?: number | null;
          round?: number | null;
          echarts?: {
            __typename?: 'Echarts';
            type?: string | null;
            dashOffset?: number | null;
            color?: string | null;
            width?: number | null;
            symbol?: string | null;
            symbolSize?: number | null;
            position?: string | null;
          } | null;
          echartsAverage?: {
            __typename?: 'Echarts';
            type?: string | null;
            dashOffset?: number | null;
            color?: string | null;
            width?: number | null;
            symbol?: string | null;
            symbolSize?: number | null;
            position?: string | null;
          } | null;
        } | null> | null;
      } | null> | null;
      profiling?: {
        __typename?: 'Profiling';
        profileImage?: string | null;
        decimalDigits?: number | null;
        sensorNode?: string | null;
        language?: string | null;
        emailNotification?: {
          __typename?: 'Notification';
          alert?: boolean | null;
          disease?: boolean | null;
        } | null;
        webNotification?: {
          __typename?: 'Notification';
          alert?: boolean | null;
          disease?: boolean | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetCountUsersQueryVariables = Exact<{ [key: string]: never }>;
export type GetCountUsersQuery = {
  __typename?: 'Query';
  allUsers?: Array<{
    id: string;
  }>;
};

export type GetCountOrganizationsQueryVariables = Exact<{
  [key: string]: never;
}>;
export type GetCountOrganizationsQuery = {
  __typename?: 'Query';
  allOrganizations?: Array<{
    eid: string;
  }>;
};

export type GetCountSensorNodesQueryVariables = Exact<{ [key: string]: never }>;
export type GetCountSensorNodesQuery = {
  __typename?: 'Query';
  allSensorNodes?: Array<{
    id: string;
  }>;
};

export type GetCountSensorsQueryVariables = Exact<{ [key: string]: never }>;
export type GetCountSensorsQuery = {
  __typename?: 'Query';
  allSensors?: Array<{
    id: string;
  }>;
};

export type GetCountDiagnosticSensorsQueryVariables = Exact<{
  [key: string]: never;
}>;
export type GetCountDiagnosticSensorsQuery = {
  __typename?: 'Query';
  allDiagnosticSensors?: Array<{
    id: string;
  }>;
};

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllUsersQuery = {
  __typename?: 'Query';
  allUsers?: Array<{
    __typename?: 'User';
    id: string;
    username: string;
    name?: string | null;
    active: boolean;
    deleted: boolean;
    organization?: {
      __typename?: 'Organization';
      id: string;
      eid: string;
      name: string;
      parent: boolean;
      active: boolean;
      deleted: boolean;
    } | null;
    sensorNodes?: Array<{
      __typename?: 'SensorNode';
      id: string;
      name: string;
      serialNumber: string;
      deleted: boolean;
    } | null> | null;
    thresholds?: Array<{
      __typename?: 'UserThreshold';
      id: string;
      sensorNodeSerial: string;
      type: string;
      enabled: boolean;
      min?: number | null;
    } | null> | null;
    profiling?: {
      __typename?: 'Profiling';
      language?: string | null;
      sensorNode?: string | null;
      profileImage?: string | null;
      decimalDigits?: number | null;
      emailNotification?: {
        __typename?: 'Notification';
        alert?: boolean | null;
        disease?: boolean | null;
      } | null;
      webNotification?: {
        __typename?: 'Notification';
        alert?: boolean | null;
        disease?: boolean | null;
      } | null;
    } | null;
  } | null> | null;
};

export type AddUserMutationVariables = Exact<{
  input: AddUserInput;
}>;

export type AddUserMutation = {
  __typename?: 'Mutation';
  addUser?: {
    __typename?: 'UserExtended';
    id: string;
    username: string;
    name?: string | null;
    userRole?: Role | null;
    active: boolean;
    deleted: boolean;
    profiling?: {
      __typename?: 'Profiling';
      profileImage?: string | null;
      decimalDigits?: number | null;
      sensorNode?: string | null;
      language?: string | null;
      emailNotification?: {
        __typename?: 'Notification';
        alert?: boolean | null;
        disease?: boolean | null;
      } | null;
      webNotification?: {
        __typename?: 'Notification';
        alert?: boolean | null;
        disease?: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type LinkUserToOrganizationMutationVariables = Exact<{
  eid: Scalars['String']['input'];
  username: Scalars['String']['input'];
}>;

export type LinkUserToOrganizationMutation = {
  __typename?: 'Mutation';
  updateOrganization?: { __typename?: 'Organization'; eid: string } | null;
};

export type UpdateSensorNodeAdminMutationVariables = Exact<{
  input: UpdateSensorNodeInput;
}>;

export type UpdateSensorNodeAdminMutation = {
  __typename?: 'Mutation';
  updateSensorNodeAdmin?: {
    __typename?: 'SensorNode';
    id: string;
    name: string;
    serialNumber: string;
    deleted: boolean;
    users?: Array<{
      __typename?: 'User';
      id: string;
      username: string;
      name?: string | null;
      active: boolean;
      deleted: boolean;
      profiling?: {
        __typename?: 'Profiling';
        language?: string | null;
        sensorNode?: string | null;
        decimalDigits?: number | null;
        emailNotification?: {
          __typename?: 'Notification';
          alert?: boolean | null;
          disease?: boolean | null;
        } | null;
        webNotification?: {
          __typename?: 'Notification';
          alert?: boolean | null;
          disease?: boolean | null;
        } | null;
      } | null;
    } | null> | null;
    sensors?: Array<{
      __typename?: 'Sensor';
      id: string;
      type: string;
      deleted: boolean;
      unitMeasure?: string | null;
      model?: string | null;
      min?: number | null;
      max?: number | null;
      multiplier?: number | null;
      round?: number | null;
      echarts?: {
        __typename?: 'Echarts';
        type?: string | null;
        dashOffset?: number | null;
        color?: string | null;
        width?: number | null;
        symbol?: string | null;
        symbolSize?: number | null;
        position?: string | null;
      } | null;
      echartsAverage?: {
        __typename?: 'Echarts';
        type?: string | null;
        dashOffset?: number | null;
        color?: string | null;
        width?: number | null;
        symbol?: string | null;
        symbolSize?: number | null;
        position?: string | null;
      } | null;
    } | null> | null;
    diagnosticSensors?: Array<{
      __typename?: 'DiagnosticSensor';
      id: string;
      type: string;
      deleted: boolean;
      model?: string | null;
      unitMeasure?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateSensorNodeMutationVariables = Exact<{
  input: UpdateSensorNodeUserInput;
}>;

export type UpdateSensorNodeMutation = {
  __typename?: 'Mutation';
  updateSensorNode?: {
    __typename?: 'SensorNode';
    id: string;
    name: string;
    serialNumber: string;
    deleted: boolean;
    users?: Array<{
      __typename?: 'User';
      id: string;
      username: string;
      name?: string | null;
      active: boolean;
      deleted: boolean;
      profiling?: {
        __typename?: 'Profiling';
        language?: string | null;
        sensorNode?: string | null;
        decimalDigits?: number | null;
        emailNotification?: {
          __typename?: 'Notification';
          alert?: boolean | null;
          disease?: boolean | null;
        } | null;
        webNotification?: {
          __typename?: 'Notification';
          alert?: boolean | null;
          disease?: boolean | null;
        } | null;
      } | null;
    } | null> | null;
    sensors?: Array<{
      __typename?: 'Sensor';
      id: string;
      type: string;
      deleted: boolean;
      unitMeasure?: string | null;
      model?: string | null;
      min?: number | null;
      max?: number | null;
      multiplier?: number | null;
      round?: number | null;
      echarts?: {
        __typename?: 'Echarts';
        type?: string | null;
        dashOffset?: number | null;
        color?: string | null;
        width?: number | null;
        symbol?: string | null;
        symbolSize?: number | null;
        position?: string | null;
      } | null;
      echartsAverage?: {
        __typename?: 'Echarts';
        type?: string | null;
        dashOffset?: number | null;
        color?: string | null;
        width?: number | null;
        symbol?: string | null;
        symbolSize?: number | null;
        position?: string | null;
      } | null;
    } | null> | null;
    diagnosticSensors?: Array<{
      __typename?: 'DiagnosticSensor';
      id: string;
      type: string;
      deleted: boolean;
      model?: string | null;
      unitMeasure?: string | null;
    } | null> | null;
  } | null;
};

export type AddDownyMildewSpotMutationVariables = Exact<{
  input: AddDownyMildewSpotInput;
}>;

export type AddDownyMildewSpotMutation = {
  __typename?: 'Mutation';
  addDownyMildewSpot?: {
    __typename?: 'DownyMildewSpot';
    id: string;
    spottedOn: string;
    eventDate: string;
  } | null;
};

export type GetDownyMildewSpotQueryVariables = Exact<{
  input: GetDownyMildewSpotInput;
}>;

export type GetDownyMildewSpotQuery = {
  __typename?: 'Query';
  getDownyMildewSpot: Array<{
    __typename?: 'DownyMildewSpot';
    id: string;
    spottedOn: string;
    eventDate: string;
  } | null>;
};

export type GetDownyMildewPredictionsQueryVariables = Exact<{
  input: GetDownyMildewPredictionsInput;
}>;

export type GetDownyMildewPredictionsQuery = {
  __typename?: 'Query';
  getDownyMildewPredictions: Array<{
    __typename?: 'DownyMildewPrediction';
    id: string;
    startDate: string;
    endDate: string;
    detection: string;
    noOutbreak?: string | null;
    infection?: string | null;
    outbreak?: string | null;
  } | null>;
};

export type DayTimeAverageQueryVariables = Exact<{
  input: DayTimeAverageInput;
}>;

export type DayTimeAverageQuery = {
  __typename?: 'Query';
  dayTimeAverage?: {
    __typename?: 'CalculationResult';
    result?: string | null;
  } | null;
};

export type NightTimeAverageQueryVariables = Exact<{
  input: NightTimeAverageInput;
}>;

export type NightTimeAverageQuery = {
  __typename?: 'Query';
  nightTimeAverage?: {
    __typename?: 'CalculationResult';
    result?: string | null;
  } | null;
};

export type EphemerisQueryVariables = Exact<{
  input: EphemerisInput;
}>;

export type EphemerisQuery = {
  __typename?: 'Query';
  ephemeris: Array<{
    __typename?: 'EphemerisOutput';
    sunrise?: string | null;
    sunset?: string | null;
    moonPhase?: string | null;
  } | null>;
};

export type GetInvalidDataQueryVariables = Exact<{
  input: InvalidDataInput;
}>;

export type GetInvalidDataQuery = {
  __typename?: 'Query';
  invalidData: Array<{
    __typename?: 'InvalidData';
    id: string;
    serverTimestamp: string;
    status: string;
    dataTimestamp?: string | null;
    type?: string | null;
    value?: string | null;
  } | null>;
};

export type GetSensorNodesLastPositionQueryVariables = Exact<{
  input: GetSensorNodesLastPositionInput;
}>;

export type GetSensorNodesLastPositionQuery = {
  __typename?: 'Query';
  getSensorNodesLastPosition: Array<{
    __typename?: 'SensorNodeLastPosition';
    sensorNodeSerial: string;
    timezone?: string | null;
    gpsp?: string | null;
  } | null>;
};

export type GetDiseasePredictionsQueryVariables = Exact<{
  input: GetDiseasePredictionsInput;
}>;

export type GetDiseasePredictionsQuery = {
  __typename?: 'Query';
  getDiseasePredictions: Array<{
    __typename?: 'DiseasePrediction';
    id: string;
    type: string;
    startDate: string;
    endDate: string;
    detection: string;
    data: {
      __typename?: 'PredictionData';
      noOutbreak?: string | null;
      infection?: string | null;
      outbreak?: string | null;
      damage?: number | null;
    };
  } | null>;
};

export const GetChartDataDocument = gql`
  query getChartData($input: ChartDataInput!) {
    chartData(input: $input) {
      name
      type
      yAxisIndex
      symbol
      symbolSize
      itemStyle {
        color
      }
      lineStyle {
        type
        width
      }
      smoothed
      data {
        timestamp
        value
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetChartDataGQL extends Apollo.Query<
  GetChartDataQuery,
  GetChartDataQueryVariables
> {
  override document = GetChartDataDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateUserPasswordDocument = gql`
  mutation updateUserPassword($input: UpdateUserPasswordInput!) {
    updateUserPassword(input: $input) {
      status
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateUserPasswordGQL extends Apollo.Mutation<
  UpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables
> {
  override document = UpdateUserPasswordDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddSensorDocument = gql`
  mutation addSensor($input: AddSensorInput!) {
    addSensor(input: $input) {
      id
      type
      deleted
      unitMeasure
      model
      min
      max
      model
      multiplier
      round
      echarts {
        type
        dashOffset
        color
        width
        symbol
        symbolSize
        position
      }
      echartsAverage {
        type
        dashOffset
        color
        width
        symbol
        symbolSize
        position
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddSensorGQL extends Apollo.Mutation<
  AddSensorMutation,
  AddSensorMutationVariables
> {
  override document = AddSensorDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateSensorDocument = gql`
  mutation updateSensor($input: UpdateSensorInput!) {
    updateSensor(input: $input) {
      id
      type
      deleted
      unitMeasure
      model
      min
      max
      model
      multiplier
      round
      echarts {
        type
        dashOffset
        color
        width
        symbol
        symbolSize
        position
      }
      echartsAverage {
        type
        dashOffset
        color
        width
        symbol
        symbolSize
        position
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateSensorGQL extends Apollo.Mutation<
  UpdateSensorMutation,
  UpdateSensorMutationVariables
> {
  override document = UpdateSensorDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddDiagnosticSensorDocument = gql`
  mutation addDiagnosticSensor($input: AddDiagnosticSensorInput!) {
    addDiagnosticSensor(input: $input) {
      id
      type
      deleted
      model
      min
      max
      unitMeasure
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddDiagnosticSensorGQL extends Apollo.Mutation<
  AddDiagnosticSensorMutation,
  AddDiagnosticSensorMutationVariables
> {
  override document = AddDiagnosticSensorDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateDiagnosticSensorDocument = gql`
  mutation updateDiagnosticSensor($input: UpdateDiagnosticSensorInput!) {
    updateDiagnosticSensor(input: $input) {
      id
      type
      model
      min
      max
      deleted
      unitMeasure
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateDiagnosticSensorGQL extends Apollo.Mutation<
  UpdateDiagnosticSensorMutation,
  UpdateDiagnosticSensorMutationVariables
> {
  override document = UpdateDiagnosticSensorDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddSensorNodeDocument = gql`
  mutation addSensorNode($input: AddSensorNodeInput!) {
    addSensorNode(input: $input) {
      id
      serialNumber
      name
      sensors {
        id
        type
        deleted
        unitMeasure
        model
        min
        max
        model
        multiplier
        round
        echarts {
          type
          dashOffset
          color
          width
          symbol
          symbolSize
          position
        }
        echartsAverage {
          type
          dashOffset
          color
          width
          symbol
          symbolSize
          position
        }
      }
      deleted
      diseases
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddSensorNodeGQL extends Apollo.Mutation<
  AddSensorNodeMutation,
  AddSensorNodeMutationVariables
> {
  override document = AddSensorNodeDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAverageChartDataDocument = gql`
  query getAverageChartData($input: AverageChartDataInput!) {
    averageChartData(input: $input) {
      name
      type
      yAxisIndex
      symbol
      symbolSize
      itemStyle {
        color
      }
      lineStyle {
        type
        width
      }
      smoothed
      data {
        timestamp
        value
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAverageChartDataGQL extends Apollo.Query<
  GetAverageChartDataQuery,
  GetAverageChartDataQueryVariables
> {
  override document = GetAverageChartDataDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FetchMeasuresDocument = gql`
  query fetchMeasures($input: FetchMeasure!) {
    measures(input: $input)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FetchMeasuresGQL extends Apollo.Query<
  FetchMeasuresQuery,
  FetchMeasuresQueryVariables
> {
  override document = FetchMeasuresDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetDashboardMeasuresDocument = gql`
  query getDashboardMeasures($input: GetDashboardMeasuresInput!) {
    getDashboardMeasures(input: $input) {
      sensor
      measures {
        id
        timestamp
        type
        value
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetDashboardMeasuresGQL extends Apollo.Query<
  GetDashboardMeasuresQuery,
  GetDashboardMeasuresQueryVariables
> {
  override document = GetDashboardMeasuresDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FetchStatusDocument = gql`
  query fetchStatus($input: FetchStatus!) {
    status(input: $input)
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FetchStatusGQL extends Apollo.Query<
  FetchStatusQuery,
  FetchStatusQueryVariables
> {
  override document = FetchStatusDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateUserAdminDocument = gql`
  mutation updateUserAdmin($input: UpdateUserExtendedAdminInput!) {
    updateUserAdmin(input: $input) {
      id
      username
      name
      userRole
      organization {
        id
        eid
        name
        parent
        active
        deleted
      }
      sensorNodes {
        id
        name
        serialNumber
        diseases
        sensors {
          id
          type
          deleted
          model
          unitMeasure
          min
          max
          model
          multiplier
          round
          echarts {
            type
            dashOffset
            color
            width
            symbol
            symbolSize
            position
          }
          echartsAverage {
            type
            dashOffset
            color
            width
            symbol
            symbolSize
            position
          }
        }
        deleted
      }
      thresholds {
        id
        sensorNodeSerial
        type
        min
        max
      }
      profiling {
        profileImage
        decimalDigits
        sensorNode
        language
        emailNotification {
          alert
          disease
        }
        webNotification {
          alert
          disease
        }
      }
      active
      deleted
      profiling {
        language
        sensorNode
        profileImage
        decimalDigits
        emailNotification {
          alert
          disease
        }
        webNotification {
          alert
          disease
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateUserAdminGQL extends Apollo.Mutation<
  UpdateUserAdminMutation,
  UpdateUserAdminMutationVariables
> {
  override document = UpdateUserAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateUserDocument = gql`
  mutation updateUser($input: UpdateUserExtendedInput!) {
    updateUser(input: $input) {
      id
      username
      name
      userRole
      organization {
        id
        eid
        name
        parent
        active
        deleted
      }
      sensorNodes {
        id
        name
        serialNumber
        diseases
        sensors {
          id
          type
          deleted
          model
          unitMeasure
          min
          max
          model
          multiplier
          round
          echarts {
            type
            dashOffset
            color
            width
            symbol
            symbolSize
            position
          }
          echartsAverage {
            type
            dashOffset
            color
            width
            symbol
            symbolSize
            position
          }
        }
        deleted
      }
      thresholds {
        id
        sensorNodeSerial
        type
        min
        max
      }
      profiling {
        profileImage
        decimalDigits
        sensorNode
        language
        emailNotification {
          alert
          disease
        }
        webNotification {
          alert
          disease
        }
      }
      active
      deleted
      profiling {
        language
        sensorNode
        profileImage
        decimalDigits
        emailNotification {
          alert
          disease
        }
        webNotification {
          alert
          disease
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateUserGQL extends Apollo.Mutation<
  UpdateUserMutation,
  UpdateUserMutationVariables
> {
  override document = UpdateUserDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateUserThresholdDocument = gql`
  mutation updateUserThreshold($input: UpdateUserThresholdInput!) {
    updateUserThreshold(input: $input) {
      id
      sensorNodeSerial
      type
      enabled
      min
      max
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateUserThresholdGQL extends Apollo.Mutation<
  UpdateUserThresholdMutation,
  UpdateUserThresholdMutationVariables
> {
  override document = UpdateUserThresholdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddUserThresholdDocument = gql`
  mutation addUserThreshold($input: AddUserThresholdInput!) {
    addUserThreshold(input: $input) {
      id
      sensorNodeSerial
      type
      enabled
      min
      max
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddUserThresholdGQL extends Apollo.Mutation<
  AddUserThresholdMutation,
  AddUserThresholdMutationVariables
> {
  override document = AddUserThresholdDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const LastMeasureDocument = gql`
  subscription lastMeasure($sensorNodeSerial: String!) {
    lastMeasure(sensorNodeSerial: $sensorNodeSerial) {
      id
      timestamp
      type
      value
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class LastMeasureGQL extends Apollo.Subscription<
  LastMeasureSubscription,
  LastMeasureSubscriptionVariables
> {
  override document = LastMeasureDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const LastStatusDocument = gql`
  subscription lastStatus($sensorNodeSerial: String!) {
    lastStatus(sensorNodeSerial: $sensorNodeSerial) {
      id
      timestamp
      type
      value
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class LastStatusGQL extends Apollo.Subscription<
  LastStatusSubscription,
  LastStatusSubscriptionVariables
> {
  override document = LastStatusDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RadiationSumDocument = gql`
  query radiationSum($input: RadiationSumInput!) {
    radiationSum(input: $input) {
      result
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class RadiationSumGQL extends Apollo.Query<
  RadiationSumQuery,
  RadiationSumQueryVariables
> {
  override document = RadiationSumDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const EvapotranspirationDocument = gql`
  query evapotranspiration($input: EvapotranspirationInput!) {
    evapotranspiration(input: $input) {
      result
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class EvapotranspirationGQL extends Apollo.Query<
  EvapotranspirationQuery,
  EvapotranspirationQueryVariables
> {
  override document = EvapotranspirationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DegreeDaysDocument = gql`
  query degreeDays($input: DegreeDaysInput!) {
    degreeDays(input: $input) {
      result
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DegreeDaysGQL extends Apollo.Query<
  DegreeDaysQuery,
  DegreeDaysQueryVariables
> {
  override document = DegreeDaysDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FetchUserDocument = gql`
  query fetchUser($username: String!) {
    user(username: $username) {
      id
      username
      name
      userRole
      organization {
        id
        eid
        name
        parent
        active
        deleted
      }
      sensorNodes {
        id
        name
        serialNumber
        diseases
        sensors {
          id
          type
          deleted
          unitMeasure
          model
          min
          max
          model
          multiplier
          round
          echarts {
            type
            dashOffset
            color
            width
            symbol
            symbolSize
            position
          }
          echartsAverage {
            type
            dashOffset
            color
            width
            symbol
            symbolSize
            position
          }
        }
        diagnosticSensors {
          id
          type
          deleted
          model
          min
          max
          unitMeasure
        }
        deleted
      }
      thresholds {
        id
        sensorNodeSerial
        type
        enabled
        min
        max
      }
      active
      deleted
      profiling {
        language
        sensorNode
        profileImage
        decimalDigits
        emailNotification {
          alert
          disease
        }
        webNotification {
          alert
          disease
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FetchUserGQL extends Apollo.Query<
  FetchUserQuery,
  FetchUserQueryVariables
> {
  override document = FetchUserDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetSensorNodeDocument = gql`
  query getSensorNode($input: String!) {
    sensorNode(serialNumber: $input) {
      id
      name
      serialNumber
      diseases
      sensors {
        id
        type
        deleted
        unitMeasure
        model
        min
        max
        multiplier
        round
        echarts {
          type
          dashOffset
          color
          width
          symbol
          symbolSize
          position
        }
        echartsAverage {
          type
          dashOffset
          color
          width
          symbol
          symbolSize
          position
        }
      }
      diagnosticSensors {
        id
        type
        model
        min
        max
        deleted
      }
      deleted
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetSensorNodeGQL extends Apollo.Query<
  GetSensorNodeQuery,
  GetSensorNodeQueryVariables
> {
  override document = GetSensorNodeDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAllOrganizationsDocument = gql`
  query getAllOrganizations {
    allOrganizations {
      eid
      name
      parent
      organizations {
        eid
        name
        active
        deleted
        vatid
      }
      users {
        id
        username
        name
        organization {
          eid
        }
        sensorNodes {
          id
          serialNumber
          name
          sensors {
            id
            type
            deleted
            unitMeasure
            model
            min
            max
            model
            multiplier
            round
            echarts {
              type
              dashOffset
              color
              width
              symbol
              symbolSize
              position
            }
            echartsAverage {
              type
              dashOffset
              color
              width
              symbol
              symbolSize
              position
            }
          }
          deleted
        }
        profiling {
          profileImage
          decimalDigits
          sensorNode
          language
          emailNotification {
            alert
            disease
          }
          webNotification {
            alert
            disease
          }
        }
        active
        deleted
      }
      active
      deleted
      vatid
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAllOrganizationsGQL extends Apollo.Query<
  GetAllOrganizationsQuery,
  GetAllOrganizationsQueryVariables
> {
  override document = GetAllOrganizationsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAllSensorNodesDocument = gql`
  query getAllSensorNodes {
    allSensorNodes {
      id
      serialNumber
      name
      active
      sensors {
        id
        type
        deleted
        unitMeasure
        model
        min
        max
        model
        multiplier
        round
        echarts {
          type
          dashOffset
          color
          width
          symbol
          symbolSize
          position
        }
        echartsAverage {
          type
          dashOffset
          color
          width
          symbol
          symbolSize
          position
        }
      }
      diagnosticSensors {
        id
        type
        deleted
        model
        min
        max
        unitMeasure
      }
      deleted
      diseases
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAllSensorNodesGQL extends Apollo.Query<
  GetAllSensorNodesQuery,
  GetAllSensorNodesQueryVariables
> {
  override document = GetAllSensorNodesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAllSensorsDocument = gql`
  query getAllSensors {
    allSensors {
      id
      type
      deleted
      unitMeasure
      model
      min
      max
      model
      multiplier
      round
      echarts {
        type
        dashOffset
        color
        width
        symbol
        symbolSize
        position
      }
      echartsAverage {
        type
        dashOffset
        color
        width
        symbol
        symbolSize
        position
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAllSensorsGQL extends Apollo.Query<
  GetAllSensorsQuery,
  GetAllSensorsQueryVariables
> {
  override document = GetAllSensorsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAllDiagnosticSensorsDocument = gql`
  query getAllDiagnosticSensors {
    allDiagnosticSensors {
      id
      type
      deleted
      model
      min
      max
      unitMeasure
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAllDiagnosticSensorsGQL extends Apollo.Query<
  GetAllDiagnosticSensorsQuery,
  GetAllDiagnosticSensorsQueryVariables
> {
  override document = GetAllDiagnosticSensorsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddOrganizationDocument = gql`
  mutation addOrganization($input: AddOrganizationInput!) {
    addOrganization(input: $input) {
      id
      eid
      name
      parent
      active
      deleted
      vatid
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddOrganizationGQL extends Apollo.Mutation<
  AddOrganizationMutation,
  AddOrganizationMutationVariables
> {
  override document = AddOrganizationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateOrganizationDocument = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      eid
      name
      parent
      vatid
      organizations {
        eid
        name
        active
        deleted
        vatid
      }
      users {
        id
        username
        name
        organization {
          eid
        }
        sensorNodes {
          id
          serialNumber
          name
          sensors {
            id
            type
            deleted
            unitMeasure
            model
            min
            max
            model
            multiplier
            round
            echarts {
              type
              dashOffset
              color
              width
              symbol
              symbolSize
              position
            }
            echartsAverage {
              type
              dashOffset
              color
              width
              symbol
              symbolSize
              position
            }
          }
          deleted
        }
        profiling {
          profileImage
          decimalDigits
          sensorNode
          language
          emailNotification {
            alert
            disease
          }
          webNotification {
            alert
            disease
          }
        }
        active
        deleted
      }
      active
      deleted
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateOrganizationGQL extends Apollo.Mutation<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
> {
  override document = UpdateOrganizationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetAllUsersDocument = gql`
  query getAllUsers {
    allUsers {
      id
      username
      name
      organization {
        id
        eid
        name
        parent
        active
        deleted
      }
      sensorNodes {
        id
        name
        serialNumber
        deleted
        diseases
      }
      thresholds {
        id
        sensorNodeSerial
        type
        enabled
        min
        min
      }
      active
      deleted
      profiling {
        language
        sensorNode
        profileImage
        decimalDigits
        emailNotification {
          alert
          disease
        }
        webNotification {
          alert
          disease
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetAllUsersGQL extends Apollo.Query<
  GetAllUsersQuery,
  GetAllUsersQueryVariables
> {
  override document = GetAllUsersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddUserDocument = gql`
  mutation addUser($input: AddUserInput!) {
    addUser(input: $input) {
      id
      username
      name
      userRole
      profiling {
        profileImage
        decimalDigits
        sensorNode
        language
        emailNotification {
          alert
          disease
        }
        webNotification {
          alert
          disease
        }
      }
      active
      deleted
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddUserGQL extends Apollo.Mutation<
  AddUserMutation,
  AddUserMutationVariables
> {
  override document = AddUserDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const LinkUserToOrganizationDocument = gql`
  mutation linkUserToOrganization($eid: String!, $username: String!) {
    updateOrganization(
      input: {
        eid: $eid
        updateOrganizationPatch: { set: { users: [{ username: $username }] } }
      }
    ) {
      eid
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class LinkUserToOrganizationGQL extends Apollo.Mutation<
  LinkUserToOrganizationMutation,
  LinkUserToOrganizationMutationVariables
> {
  override document = LinkUserToOrganizationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateSensorNodeAdminDocument = gql`
  mutation updateSensorNodeAdmin($input: UpdateSensorNodeInput!) {
    updateSensorNodeAdmin(input: $input) {
      id
      name
      serialNumber
      diseases
      users {
        id
        username
        name
        active
        deleted
        profiling {
          language
          sensorNode
          decimalDigits
          emailNotification {
            alert
            disease
          }
          webNotification {
            alert
            disease
          }
        }
      }
      sensors {
        id
        type
        deleted
        unitMeasure
        model
        min
        max
        model
        multiplier
        round
        echarts {
          type
          dashOffset
          color
          width
          symbol
          symbolSize
          position
        }
        echartsAverage {
          type
          dashOffset
          color
          width
          symbol
          symbolSize
          position
        }
      }
      diagnosticSensors {
        id
        type
        deleted
        model
        unitMeasure
      }
      deleted
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateSensorNodeAdminGQL extends Apollo.Mutation<
  UpdateSensorNodeAdminMutation,
  UpdateSensorNodeAdminMutationVariables
> {
  override document = UpdateSensorNodeAdminDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateSensorNodeDocument = gql`
  mutation updateSensorNode($input: UpdateSensorNodeUserInput!) {
    updateSensorNode(input: $input) {
      id
      name
      serialNumber
      users {
        id
        username
        name
        active
        deleted
        profiling {
          language
          sensorNode
          decimalDigits
          emailNotification {
            alert
            disease
          }
          webNotification {
            alert
            disease
          }
        }
      }
      sensors {
        id
        type
        deleted
        unitMeasure
        model
        min
        max
        model
        multiplier
        round
        echarts {
          type
          dashOffset
          color
          width
          symbol
          symbolSize
          position
        }
        echartsAverage {
          type
          dashOffset
          color
          width
          symbol
          symbolSize
          position
        }
      }
      diagnosticSensors {
        id
        type
        deleted
        model
        unitMeasure
      }
      deleted
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateSensorNodeGQL extends Apollo.Mutation<
  UpdateSensorNodeMutation,
  UpdateSensorNodeMutationVariables
> {
  override document = UpdateSensorNodeDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddDownyMildewSpotDocument = gql`
  mutation addDownyMildewSpot($input: AddDownyMildewSpotInput!) {
    addDownyMildewSpot(input: $input) {
      id
      spottedOn
      eventDate
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class AddDownyMildewSpotGQL extends Apollo.Mutation<
  AddDownyMildewSpotMutation,
  AddDownyMildewSpotMutationVariables
> {
  override document = AddDownyMildewSpotDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetDownyMildewSpotDocument = gql`
  query getDownyMildewSpot($input: GetDownyMildewSpotInput!) {
    getDownyMildewSpot(input: $input) {
      id
      spottedOn
      eventDate
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetDownyMildewSpotGQL extends Apollo.Query<
  GetDownyMildewSpotQuery,
  GetDownyMildewSpotQueryVariables
> {
  override document = GetDownyMildewSpotDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetDownyMildewPredictionsDocument = gql`
  query getDownyMildewPredictions($input: GetDownyMildewPredictionsInput!) {
    getDownyMildewPredictions(input: $input) {
      id
      startDate
      endDate
      detection
      noOutbreak
      infection
      outbreak
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetDownyMildewPredictionsGQL extends Apollo.Query<
  GetDownyMildewPredictionsQuery,
  GetDownyMildewPredictionsQueryVariables
> {
  override document = GetDownyMildewPredictionsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DayTimeAverageDocument = gql`
  query dayTimeAverage($input: DayTimeAverageInput!) {
    dayTimeAverage(input: $input) {
      result
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DayTimeAverageGQL extends Apollo.Query<
  DayTimeAverageQuery,
  DayTimeAverageQueryVariables
> {
  override document = DayTimeAverageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const NightTimeAverageDocument = gql`
  query nightTimeAverage($input: NightTimeAverageInput!) {
    nightTimeAverage(input: $input) {
      result
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class NightTimeAverageGQL extends Apollo.Query<
  NightTimeAverageQuery,
  NightTimeAverageQueryVariables
> {
  override document = NightTimeAverageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const EphemerisDocument = gql`
  query ephemeris($input: EphemerisInput!) {
    ephemeris(input: $input) {
      sunrise
      sunset
      moonPhase
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class EphemerisGQL extends Apollo.Query<
  EphemerisQuery,
  EphemerisQueryVariables
> {
  override document = EphemerisDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetInvalidDataDocument = gql`
  query getInvalidData($input: InvalidDataInput!) {
    invalidData(input: $input) {
      id
      serverTimestamp
      status
      dataTimestamp
      type
      value
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetInvalidDataGQL extends Apollo.Query<
  GetInvalidDataQuery,
  GetInvalidDataQueryVariables
> {
  override document = GetInvalidDataDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetSensorNodesLastPositionDocument = gql`
  query getSensorNodesLastPosition($input: GetSensorNodesLastPositionInput!) {
    getSensorNodesLastPosition(input: $input) {
      sensorNodeSerial
      timezone
      gpsp
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetSensorNodesLastPositionGQL extends Apollo.Query<
  GetSensorNodesLastPositionQuery,
  GetSensorNodesLastPositionQueryVariables
> {
  override document = GetSensorNodesLastPositionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GetDiseasePredictionsDocument = gql`
  query getDiseasePredictions($input: GetDiseasePredictionsInput!) {
    getDiseasePredictions(input: $input) {
      id
      type
      startDate
      endDate
      detection
      data
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetDiseasePredictionsGQL extends Apollo.Query<
  GetDiseasePredictionsQuery,
  GetDiseasePredictionsQueryVariables
> {
  override document = GetDiseasePredictionsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}

export const GetCountUsersDocument = gql`
  query getAllUsers {
    allUsers {
      id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GetCountUsers extends Apollo.Query<
  GetCountUsersQuery,
  GetCountUsersQueryVariables
> {
  override document = GetCountUsersDocument;
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}

export const GetCountOrganizationsDocument = gql`
  query getAllOrganizations {
    allOrganizations {
      id
    }
  }
`;

@Injectable({ providedIn: 'root' })
export class GetCountOrganizations extends Apollo.Query<
  GetCountOrganizationsQuery,
  GetCountOrganizationsQueryVariables
> {
  override document = GetCountOrganizationsDocument;
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}

export const GetCountSensorNodesDocument = gql`
  query getAllSensorNodes {
    allSensorNodes {
      id
    }
  }
`;

@Injectable({ providedIn: 'root' })
export class GetCountSensorNodes extends Apollo.Query<
  GetCountSensorNodesQuery,
  GetCountSensorNodesQueryVariables
> {
  override document = GetCountSensorNodesDocument;
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}

export const GetCountSensorsDocument = gql`
  query getAllSensors {
    allSensors {
      id
    }
  }
`;

@Injectable({ providedIn: 'root' })
export class GetCountSensors extends Apollo.Query<
  GetCountSensorsQuery,
  GetCountSensorsQueryVariables
> {
  override document = GetCountSensorsDocument;
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}

export const GetCountDiagnosticSensorsDocument = gql`
  query getAllDiagnosticSensors {
    allDiagnosticSensors {
      id
    }
  }
`;

@Injectable({ providedIn: 'root' })
export class GetCountDiagnosticSensors extends Apollo.Query<
  GetCountDiagnosticSensorsQuery,
  GetCountDiagnosticSensorsQueryVariables
> {
  override document = GetCountDiagnosticSensorsDocument;
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
