import { Injectable } from '@angular/core';
import { switchMap, map, mergeMap, take, catchError, first, retry } from 'rxjs/operators';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Sensor } from '../../models';
import * as sensorActions from '../actions/sensors.actions';
import * as selectors from '../selectors';
import { Store } from '@ngrx/store';
import { GraphqlService } from '../../services/graphql.service';
import { concat, of } from 'rxjs';
import { MS_IN_A_DAY, getDateToSpecificFormatString } from '../../evja/utils/date';

@Injectable()
export class SensorsEffects {

  constructor(private actions$: Actions, private store: Store, private graphqlService: GraphqlService) { }

  setSensors$ = createEffect(() => this.actions$.pipe(
    ofType(sensorActions.SetSensors),
    map((action) => action.sensors),
    switchMap((payload: Sensor[]) =>
      payload.length > 0
        ? [sensorActions.LoadSensorsData({ payload })]
        : []
      // {
      //   return payload.map((sensor: Sensor) =>
      //     new actions.LoadSensorsData(sensor.type));
      // })
    ))
  );

  // loadSensorsData$ = createEffect(() => this.actions$.pipe(
  //   ofType(actions.LOAD_SENSORS_DATA),
  //   map((action: actions.LoadSensorsData) => action.payload),
  //   mergeMap(payload => this.store
  //     .select(
  //       selectors.selectCurrentSensorNodeSerialNumber
  //     )
  //     .pipe(
  //       first(),
  //       map(currentSensorNodeSerialNumber => {
  //         return {
  //           sensorNodeSerialNumber: currentSensorNodeSerialNumber,
  //           type: payload
  //         };
  //       }),
  //       mergeMap(data =>
  //         this.graphqlService.fetchMeasures
  //           (
  //             {
  //               input: {
  //                 sensorNodeSerial: data.sensorNodeSerialNumber,
  //                 type: data.type,
  //                 limit: 4
  //               }
  //             },
  //             {
  //               fetchPolicy: "no-cache"
  //             }
  //           )
  //           .pipe(
  //             first(),
  //             map(last4Measures => {
  //               return {
  //                 sensorNodeSerialNumber: data.sensorNodeSerialNumber,
  //                 type: data.type,
  //                 last4Measures: last4Measures.data.measures.map(measure => {
  //                   return {
  //                     id: measure.id,
  //                     type: measure.type,
  //                     timestamp: measure.timestamp,
  //                     value: measure.value
  //                   };
  //                 }).slice().reverse()
  //               };
  //             }),
  //             catchError(() => of({
  //               sensorNodeSerialNumber: data.sensorNodeSerialNumber,
  //               type: data.type,
  //               last4Measures: []
  //             }))
  //           )),
  //       switchMap(data =>
  //         zip(
  //           this.store
  //             .select(
  //               selectors.selectUser
  //             )
  //             .pipe(
  //               first()
  //             ),
  //           this.store
  //             .select(
  //               selectors.selectSensorEntity, { type: data.type }
  //             )
  //             .pipe(
  //               first()
  //             )
  //         ).pipe(
  //           map(res => {
  //             return {
  //               sensorNodeSerialNumber: data.sensorNodeSerialNumber,
  //               type: data.type,
  //               last4Measures: data.last4Measures,
  //               threshold: res[0].thresholds.find(threshold =>
  //                 threshold.sensorNodeSerial === data.sensorNodeSerialNumber && threshold.type === data.type),
  //               sensor: res[1]
  //             };
  //           })
  //         )
  //       ),
  //     )),
  //   switchMap(data => [new actions.SetSensorsData(data.type, data.sensor, data.last4Measures, data.threshold)]),
  //   catchError((e) => { console.log("e", e); return EMPTY; }) // TODO
  // ));

  loadSensorsData$ = createEffect(() => this.actions$.pipe(
    ofType(sensorActions.LoadSensorsData),
    map((action) => action.payload),
    switchMap(payload => this.store
      .select(
        selectors.selectCurrentSensorNodeSerialNumber
      )
      .pipe(
        map(sensorNodeSerialNumber => ({ sensors: payload, sensorNodeSerialNumber: sensorNodeSerialNumber })),
        first())
    ),
    switchMap(res =>
      this.store
        .select(
          selectors.selectUserThresholds
        )
        .pipe(
          map(thresholds => ({ ...res, thresholds: thresholds })),
          first()
        )
    ),
    switchMap(res =>
      this.graphqlService.getDashboardMeasures(
        {
          input: {
            sensorNodeSerialNumber: res.sensorNodeSerialNumber,
            sensors: res.sensors.map(s => s.type),
            limit: 4
          }
        },
        {
          fetchPolicy: 'no-cache'
        }
      )
        .pipe(
          map(response => (
            {
              ...res,
              dashboardMeasures: response?.data?.getDashboardMeasures
                .map(item => (
                  {
                    measures: item.measures.map(m => ({ id: m.id, timestamp: m.timestamp, type: m.type, value: m.value })),
                    sensor: res.sensors.find(s => s.type === item.sensor),
                    threshold: res.thresholds.find(t => t.sensorNodeSerial === res.sensorNodeSerialNumber && t.type === item.sensor)
                  }
                ))
            }
          )),
          first(),
          retry(3),
          catchError(() => of({ ...res, dashboardMeasures: null }))
        )
    ),
    switchMap(res => {
      this.store.dispatch(sensorActions.SetSensorsData({payload : res.dashboardMeasures}));
      return concat(
        ...res.sensors
          .filter(s => res?.dashboardMeasures.map(i => i.sensor.type).find(t => t === s.type) == null)
          .map(s => ({ sensor: s, threshold: res?.thresholds.find(t => t.sensorNodeSerial === res.sensorNodeSerialNumber && t.type === s.type) }))
          .map(s =>
            this.graphqlService.fetchMeasures(
              {
                input: {
                  sensorNodeSerial: res.sensorNodeSerialNumber,
                  sensors: [s.sensor.type],
                  limit: 4
                }
              },
              {
                fetchPolicy: 'no-cache'
              }
            )
              .pipe(
                first(),
                map(response => (
                  {
                    measures: response?.data?.measures,
                    sensor: s?.sensor,
                    threshold: s?.threshold
                  }
                )),
                retry(3),
                catchError(() => of({ sensor: s.sensor, threshold: s.threshold, measures: [] }))
              )
          )
      );
    }),
    //da sistemare
    switchMap(sensorData => [sensorActions.SetSensorsData({payload:sensorData[0]})]),
    catchError(() => [sensorActions.SetSensorsData({payload : []})])
  ));

  loadLast24hMeasures$ = createEffect(() => this.actions$.pipe(
    ofType(sensorActions.LoadLast48hMeasures),
    map((action) => action.paylaod),
    mergeMap(payload => this.store
      .select(
        selectors.selectCurrentSensorNodeSerialNumber
      )
      .pipe(
        take(1),
        map(currentSensorNodeSerialNumber => {
          return {
            sensorNodeSerialNumber: currentSensorNodeSerialNumber,
            type: payload
          };
        })
      )),
    mergeMap(currentSnodeSerial =>
      this.store
        .select(
          selectors.selectSensorEntityLastMeasure, { type: currentSnodeSerial.type }
        )
        .pipe(
          take(1),
          map(lastMeasure => {
            return {
              ...currentSnodeSerial,
              lastMeasure: lastMeasure?.timestamp
            };
          }),
        )),
    mergeMap(data => {
      if (data.lastMeasure) {
        return this.graphqlService.fetchMeasures
          (
            {
              input: {
                sensorNodeSerial: data.sensorNodeSerialNumber,
                sensors: [data.type],
                endDate: data.lastMeasure ? getDateToSpecificFormatString(data.lastMeasure) : null, // we should use the sensor node time zone
                startDate: data.lastMeasure ? getDateToSpecificFormatString(new Date(new Date(data.lastMeasure).getTime() - MS_IN_A_DAY)) : null,
                asc: true
              }
            },
            {
              fetchPolicy: "network-only",
            }
          )
          .pipe(
            map(last24hMeasures => {
              return {
                type: data.type,
                last24hMeasures: last24hMeasures?.data?.measures.map(measure => {
                  return {
                    id: measure.id,
                    type: measure.type,
                    timestamp: measure.timestamp,
                    value: Number(measure.value)
                  };
                })
              };
            }),
            first(),
            catchError(() => of({ type: data.type, last24hMeasures: [] }))
          );
      }
      return of({ type: data.type, last24hMeasures: [] });
    }),
    switchMap(data => [sensorActions.SetLast48hMeasures({ id : data.type, last48hMeasures : data.last24hMeasures})]),
  ));
}
