// THIS REPRESENTS THE LIST OF COMPONENTS THAT CAN BE RETRIED WHEN FAILED
// EACH COMPONENT WILL HAVE A UNIQUE ID
// LOADING SERVICE WILL USE THIS TO FILTER BETWEEN COMPONENTS AND KNOW WICH ONE TO RETY IF AN ERROR OCCURS
export enum COMPONENTS_LIST {
  WEATHER = 'DASHBOARD_WEATHER_COMPONENT',
  PREDICTIONS = 'DASHBOARD_PREDICITIONS_COMPONENT',
  CALCULATIONS = 'DASHBOARD_CALCULATIONS_COMPONENT',
  SENSORS = 'DASHBOARD_SENSORS_COMPONENT',
  GREENHOUSE = 'GREENHOUSE_COMPONENT',
  FERTIGATION = 'FERTIGATION_COMPONENT',
  YIELD = 'DASHBOARD_YIELD_COMPONENT',
  MAP = 'DASHBOARD_MAP_COMPONENT',
  APP = 'APP_ACCESS_MANAGER',
  CHARTS = 'CHARTS_PAGE',
  CALENDAR = 'CALENDAR_PAGE',
  HISTORY = 'HISTORY_PAGE',
  SENSOR_WINDOW = 'SENSOR_WINDOW',
  ADD_CALENDAR_EVENT_DIALOG = 'ADD_CALENDAR_EVENT_DIALOG',
}
