import { PlatformLocation, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as fromStore from '../../../store';
import { isMobile } from '../../utils/breakpoints';
import { DateWithTimezoneApplied } from '../../utils/date';
import { NOTIFICATIONS_TYPE } from '../../../constants/notification_type';

@Component({
  selector: 'pwa-notifications-window',
  templateUrl: './notifications-window.component.html',
  styleUrls: ['./notifications-window.component.scss']
})
export class NotificationsWindowComponent implements OnInit {
  notifications: any[] = [];
  rowPageOpt: number[] = [10,25,50,100];

  constructor(
    private store: Store,
    public config: DynamicDialogConfig,
    private translate: TranslateService,
    private platformLocation: PlatformLocation,
    private location: Location,
    private dialogRef: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    // this code is used to close dialog with back button
    this.location.go(this.platformLocation.pathname);
    this.platformLocation.onPopState(() => {
      this.location.go(this.platformLocation.pathname);
      this.dialogRef.close();
    });

    this.notifications = this.config.data.notifications;
  }

  handleComplete($event) {
    // TODO cannot use translate service so listen for changes in the store is useless since
    // we cant assamble the content, this is an inefficient workaroud
    this.notifications = this.notifications.map((v: any) => {
      if (v.id === $event.currentTarget.value) {
        return { ...v, completed: true };
      }
      return v;
    });
    this.store.dispatch(fromStore.UpdateNotifications({payload :[$event.currentTarget.value]}));
  }

  isMobile(): boolean {
    return isMobile();
  }

  formatTimestamp(timestamp: string): string {
    return DateWithTimezoneApplied(timestamp, this.translate.currentLang);
  }

  getNotificationTimestamp(notification){
    switch (notification.type) {
      case NOTIFICATIONS_TYPE.THRESHOLD:
        return notification.terms.timestamp;
      case NOTIFICATIONS_TYPE.DISEASE:
        return notification.terms.infection_date;
    }
  }

  getNotificationIcon(notification){
    switch (notification.type) {
      case NOTIFICATIONS_TYPE.THRESHOLD:
        return "pi pi-exclamation-triangle";
      case NOTIFICATIONS_TYPE.DISEASE:
        return notification.icon;
    }
    
  }

}
