import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbButtonModule, NbSpinnerModule, NbToastrModule } from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { PasswordModule } from 'primeng/password';
import { ChangePasswordWindowComponent } from './change-password-window.component';


@NgModule({
  declarations: [ChangePasswordWindowComponent],
  imports: [
    CommonModule,
    NbButtonModule,
    ReactiveFormsModule,
    FormsModule,
    NbToastrModule,
    NbSpinnerModule,
    PasswordModule,
    TranslateModule
  ],
  exports: [ChangePasswordWindowComponent]
})
export class ChangePasswordWindowModule { }
