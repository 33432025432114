<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <i class="eva eva-menu-2-outline"></i>
    </a>
    <a *ngIf="!userPictureOnly" href="#" (click)="navigateHome()">
      <img
        alt="logo"
        class="logo"
        src="assets/images/opi_logo-vector_v01.svg"
      />
    </a>
  </div>
  <pwa-select-sensor-node class="sensorNodeSelection"></pwa-select-sensor-node>
</div>

<div class="header-container">
  <div class="lastDetectionFontSize" *ngIf="isMobile()">
    {{ 'SENSORSCOMPONENT.LASTDETECTION' | translate }}:
    {{ lastMeasureTimestamp }}
  </div>

  <div *ngIf="isMobile()" style="margin-left: 0.7rem">
    <pwa-battery-icon [batteryStatus]="batteryStatus"> </pwa-battery-icon>
  </div>
  <nb-actions size="small">
    <nb-action
      class="control-item smallActions"
      badgeText="{{
        notificationsNotCompleted === 0 ? '' : notificationsNotCompleted
      }}"
      badgePosition="top end"
      badgeStatus="success"
      [nbPopover]="notificationsTemplate"
      nbPopoverPlacement="bottom"
    >
      <i style="font-size: 1.5rem !important" class="fas fa-bell"></i>
    </nb-action>
    <ng-template #notificationsTemplate>
      <pwa-notifications-popover
        (destroy)="destroyNotificationsPopoverComponent()"
      ></pwa-notifications-popover>
    </ng-template>
    <nb-action
      class="user-action"
      *nbIsGranted="['view', 'user']"
      [link]="'/history'"
      (click)="
        getEventHeader('HeaderComponent.getEventHeader.UserMenu', {
          type: 'menu',
        })
      "
    >
      <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="name | async"
        showTitle="true"
        title="{{ organizationName | async }}"
        [picture]="picture | async"
        nbContextMenuTag="my-context-menu"
        shape="semi-round"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
