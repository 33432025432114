import { Measure } from "@pwa/graphql";
import { EChartsSeries , Sensor, Threshold } from "../../models";
import { MS_IN_A_DAY } from "./date";

export function getCardStatus(last4measures: Measure[], threshold: Threshold): string {
  if (threshold) {
    if (!threshold.enabled) {
      return 'basic';
    }
    if (threshold.enabled) {
      if (last4measures.length <= 0) {
        return 'basic';
      }
      if (last4measures[last4measures.length - 1].value <= threshold.min || last4measures[last4measures.length - 1].value >= threshold.max) {
        return 'danger';
      } else {
        return 'success';
      }
    }
  }
  return 'basic';
}

export function getDefaultThreshold(type: string): Threshold {
  return {
    id: null,
    sensorNodeSerial: null,
    type: type,
    enabled: false,
    min: 0,
    max: 100
  };
}

export function buildChartSeries(markLine: boolean, sensor?: Sensor, measures?: Measure[], threshold?: Threshold): EChartsSeries {

  let data: (string | number)[][];
  let typeSensor: string;
  if (measures) {
    data = measures.map(measure => {
      typeSensor = measure.type;
      return [measure.timestamp, measure.value];
    });
  }
  else {
    data = null;
  }

  return {
    series: {
      name: typeSensor,
      type: 'line',
      smooth: true,
      symbol: sensor?.echarts?.symbol ? sensor.echarts.symbol : 'circle',
      symbolSize: sensor?.echarts?.symbolSize ? sensor.echarts.symbolSize : 4,
      itemStyle: (measures !== undefined ?
        {
          color: sensor?.echarts?.color ? sensor.echarts.color : '#5C7BD9',
        } : null
      ),
      lineStyle: (measures !== undefined ?
        {
          //this ternary check the type on db and corrisponding the number
          // solid -> 0
          // dotted -> 1
          // dashed -> 2
          type: sensor?.echarts?.type === "solid" ?
            0 : sensor?.echarts?.type === "dotted" ?
              1 : sensor?.echarts?.type === "dashed" ?
                2 : 0,
          width: sensor?.echarts?.width ? sensor.echarts.width : 2,
        } : null
      ),
      markLine: markLine ? (!!threshold ? (threshold.enabled ? {
        symbol: 'none',
        data: [
          {
            yAxis: Number(threshold.min),
            type: "min",
            lineStyle: {
              color: "red",
            },
            symbolOffset: null,
            // label: {
            //   show: true,
            //   position: "insideStartBottom",
            //   formatter: "MIN"
            // }
          },
          {
            yAxis: Number(threshold.max),
            type: "max",
            lineStyle: {
              color: "red",
            },
            symbolOffset: null,
            // label: {
            //   show: true,
            //   position: "insideStartTop",
            //   formatter: "MAX"
            // }
          }
        ]
      } : null) : null) : null,
      data: data
    }
  };
}


export function addMeasureToLast4Measures(measure: Measure, last4Measures: Measure[]): Measure[] {
  if (last4Measures.length == 0) {
    return [measure];
  }

  if (measure.timestamp < last4Measures[0].timestamp) {
    return last4Measures;
  }

  let newLast4Measures = last4Measures.slice();

  if (measure?.timestamp > last4Measures[last4Measures.length - 1].timestamp) {
    newLast4Measures.push(measure);
  } else {
    for (let i: number = 1; last4Measures.length; i++) {
      if (last4Measures[i]?.timestamp > measure?.timestamp) {
        newLast4Measures.splice(i, 0, measure);
        break;
      }
    }
  }

  if (newLast4Measures.length > 4) {
    newLast4Measures.shift();
  }

  return newLast4Measures;
}

export function AddMeasureToLast24hMeasures(measure: Measure, last24hMeasures: Measure[], lastMeasure: string): Measure[] {
  let startDateLimit: string = new Date(new Date(lastMeasure).getTime() - MS_IN_A_DAY).toISOString();
  if (measure.timestamp < startDateLimit) {
    return cleanLast24hMeasures(startDateLimit, last24hMeasures);
  }

  if (last24hMeasures.length < 1) {
    return [measure];
  }

  if (measure.timestamp > last24hMeasures[last24hMeasures.length - 1].timestamp) {
    return cleanLast24hMeasures(startDateLimit, [...last24hMeasures, measure]);
  } else {
    for (let i: number = 1; i < last24hMeasures.length; i++) {
      if (last24hMeasures[i].timestamp > measure.timestamp) {
        let newLast24hMeasures = last24hMeasures.slice();
        newLast24hMeasures.splice(i, 0, measure);
        return cleanLast24hMeasures(startDateLimit, newLast24hMeasures);
      }
    }
  }
}

function cleanLast24hMeasures(startDateLimit: string, last24hMeasures: Measure[]): Measure[] {
  let newLast24hMeasures = last24hMeasures.slice();

  while (true) {
    if (newLast24hMeasures.length > 0) {
      if (newLast24hMeasures[0].timestamp < startDateLimit) {
        newLast24hMeasures.shift();
      } else {
        break;
      }
    } else {
      break;
    }
  }

  return newLast24hMeasures;
}

export function getWeekDay(timezone): string[] {
  let weekday = new Date(new Date().getTime() + timezone * 1000).toUTCString().substring(0, 3);
  switch (weekday) {
    case "Mon":
      return ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY'];
    case "Tue":
      return ['TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];
    case "Wed":
      return ['WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    case "Thu":
      return ['THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
    case "Fri":
      return ['FRIDAY', 'SATURDAY', 'SUNDAY', 'MONDAY'];
    case "Sat":
      return ['SATURDAY', 'SUNDAY', 'MONDAY', 'TUESDAY',];
    case "Sun":
      return ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY'];
    default:
      return [];
  }
}
