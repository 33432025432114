<p-accordion [activeIndex]="isMobile() ? 0 : null" *ngIf="withAccordion == true; else elseBlock" class="mapAccordion">
  <p-accordionTab>
    <ng-template pTemplate="header" style="min-height: 4.5rem">
      <div class="container">
        <div class="row">
          <div class="col-12">
            {{ 'MAPCOMPONENT.TITLE' | translate }}
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <div
        class="mapInAccordion"
        leaflet
        [leafletOptions]="mapOptions"
        [leafletLayers]="layers"
        (leafletMapReady)="onMapReady($event)"
      ></div>
    </ng-template>
  </p-accordionTab>
</p-accordion>
<ng-template #elseBlock>
  <div
    *ngIf="withAccordion == false && windowMap == false; else elseWindow"
    class="map"
    leaflet
    [leafletOptions]="mapOptions"
    [leafletLayers]="layers"
    (leafletMapReady)="onMapReady($event)"
  ></div>
</ng-template>
<ng-template #elseWindow>
  <div
    class="mapWindow"
    leaflet
    [leafletOptions]="mapOptions"
    [leafletLayers]="layers"
    (leafletMapReady)="onMapReadyForSensorWindow($event)"
  ></div>
</ng-template>
