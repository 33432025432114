import { PlatformLocation, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NbToastrService, NbWindowRef } from '@nebular/theme';
import { NGXLogger } from 'ngx-logger';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EMPTY, } from 'rxjs';
import { catchError, first, retry, } from 'rxjs/operators';
import { GraphqlService } from '../../../services/graphql.service';
import { KeycloakService } from 'keycloak-angular';
import { TranslateService } from '@ngx-translate/core';
interface Dictionary<T> {
  [Key: string]: T;
}
@Component({
  selector: 'pwa-change-password-window',
  templateUrl: './change-password-window.component.html',
  styleUrls: ['./change-password-window.component.scss']
})
export class ChangePasswordWindowComponent implements OnInit {
  loading: boolean = false;

  context: any;


  changePasswordFormGroup: FormGroup = new FormGroup({
    oldPassword: new FormControl("", [Validators.required]),
    newPassword: new FormControl("", [Validators.required]),
    confirmNewPassword: new FormControl("", [Validators.required, this.matchValues('newPassword')])
  });

  constructor(
    private graphqlService: GraphqlService,
    private nbToastrService: NbToastrService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private logger: NGXLogger,
    private platformLocation: PlatformLocation,
    private location: Location,
    private keycloackService:KeycloakService,
    private translateService:TranslateService
  ) { }

  ngOnInit(): void {
    // this code is used to close dialog with back button
    this.location.go(this.platformLocation.pathname);
    this.platformLocation.onPopState(() => {
      this.location.go(this.platformLocation.pathname);
      this.ref.close();
    });
    this.context = this.config.data;
  }

  close() {
    this.ref.close();
  };

  confirmUpdate() {
    this.loading = true;

    this.graphqlService.updateUserPassword
      (
        {
          input: {
            username: this.keycloackService.getUsername(),
            oldPassword: this.changePasswordFormGroup.get('oldPassword').value,
            newPassword: this.changePasswordFormGroup.get('newPassword').value,
            confirmNewPassword: this.changePasswordFormGroup.get('confirmNewPassword').value
          }
        }
      )
      .pipe(
        retry(3),
        catchError(e => {
          this.logger.error("ChangePasswordWindowComponent.confirmUpdate.updateUserPassword.catchError: ", e);
          this.nbToastrService.show(this.translateService.instant("COMMON.ERRORMESSAGE") , this.translateService.instant('COMMON.ERROR'), { status: 'danger', duration: 5000 });
          this.loading = false;
          this.close();
          return EMPTY;
        }),
        first()
      )
      .subscribe(v => {
        switch (true) {
          case (v?.data?.updateUserPassword.status === "please check username and/or password"):
            this.nbToastrService.show(this.translateService.instant("CHANGEPASSWORDWINDOW.INCORRECTPASSWORDMESSAGE",this.translateService.instant("COMMON.ERROR")), { status: 'danger', duration: 5000 });
            break;
          case (v?.data?.updateUserPassword.status === "success"):
            this.nbToastrService.show(this.translateService.instant("CHANGEPASSWORDWINDOW.SUCCESSMESSAGE",this.translateService.instant("CHANGEPASSWORDWINDOW.SUCCESSMESSAGE")), { status: 'success', duration: 5000 });
            break;
          default:
            this.nbToastrService.show(this.translateService.instant("COMMON.ERRORMESSAGE") , this.translateService.instant("COMMON.ERROR"), { status: 'danger', duration: 5000 });
        }
        this.loading = false;
        this.close();
      });
  }

  matchValues(
    matchTo: string // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }

}
