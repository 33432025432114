<div class="container" style="width: 26rem; max-width: 70vw">
  <div *ngIf="last5NotCompletedNotifications.length > 0" class="row">
    <div class="col-12 border ps-0 pe-0">
      <button
        nbButton
        fullWidth
        hero
        status="basic"
        style="
          display: block;
          white-space: normal;
          text-align: right;
          padding-left: 0;
          padding-right: 0;
          text-transform: none;
        "
        (click)="handleMarkAllAsRead($event)"
      >
        <div class="container">
          <div class="row justify-content-end">
            <div class="col-1 text-end align-self-center">
              <i class="fas fa-check"></i>
            </div>
            <div class="col-auto">
              {{ 'NOTIFICATIONS.MARKALLASREAD' | translate }}
            </div>
          </div>
        </div>
      </button>
    </div>
    <div
      *ngFor="let notification of last5NotCompletedNotifications"
      class="col-12 border ps-0 pe-0"
    >
      <div class="container">
        <div class="row">
          <button
            nbButton
            [value]="notification.id"
            fullWidth
            hero
            status="basic"
            style="
              white-space: normal;
              text-align: left;
              padding-left: 0;
              padding-right: 0;
              text-transform: none;
            "
            (click)="handleNotificationClick($event)"
          >
            <div class="col-2 align-self-center text-center">
              <i class="{{ notification.icon }}"></i>
            </div>
            <div class="col-10">
              {{ notification.content }}
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 border pe-0 ps-0">
      <button
        nbButton
        fullWidth
        hero
        status="basic"
        style="
          white-space: normal;
          padding-left: 0;
          padding-right: 0;
          text-transform: none;
        "
        (click)="handleMore($event)"
      >
        {{ 'NOTIFICATIONS.MOREBUTTON' | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="last5NotCompletedNotifications.length === 0" class="row">
    <div (click)="destroy.emit()" class="col-12 border g-0">
      <button
        nbButton
        fullWidth
        hero
        status="basic"
        style="
          white-space: normal;
          text-align: left;
          padding-left: 10px;
          padding-right: 10px;
          text-transform: none;
        "
      >
        <div class="col-12">
          {{ 'NOTIFICATIONS.EMPTYMESSAGE' | translate }}
        </div>
      </button>
    </div>
  </div>
</div>
