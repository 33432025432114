import { Injectable } from '@angular/core';
import { switchMap, map, mergeMap, take, tap, catchError, first, skipWhile } from 'rxjs/operators';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as NotificationsActions from '../actions/notifications.actions';
import * as NotificationsSelectors from '../selectors/notifications.selectors';
import { NotificationsService } from '../../services/notifications.service';
import { Store } from '@ngrx/store';
import { selectCurrentSensorNodeSerialNumber } from '../selectors';
import { of } from 'rxjs';

@Injectable()
export class NotificationsEffects {

  constructor(private actions$: Actions, private notificationsService: NotificationsService, private store: Store) {
  }

  loadNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(NotificationsActions.LoadNotifications),
    map((action) => action.payload),
    mergeMap(payload => this.notificationsService.login()
      .pipe(
        (take(1)),
        map(login => {
          return {
            loggedIn: login.ok,
            payload: payload
          };
        })
      )
    ),
    mergeMap(r => this.store.select(selectCurrentSensorNodeSerialNumber).pipe(
      skipWhile(serial => serial === null),
      take(1),
      map(serialNumber => {
        return {
          language: r.payload.language,
          payload: r.payload,
          serialNumber: serialNumber,
          loggedIn: r.loggedIn
        }
      })
    )),
    mergeMap(r =>
      this.notificationsService.fetchNotifications(r.payload.username, r.serialNumber)
        .pipe(
          take(1),
          map(notifications => {
            return {
              doc: notifications,
              loginInfo: r
            };
          }),
          catchError(err => {
            return of({
              loginInfo: r,
              doc: []
            });
          }),
        )),
    mergeMap(r =>
      this.notificationsService.fetchTemplates()
        .pipe(
          take(1),
          map(v => {
            let templates: any = v;
            return {
              templates: templates[r.loginInfo.payload.language],
              ...r
            };
          })
        )),
    switchMap((r:any) => {
      if (!r.loginInfo.loggedIn) {
        return [NotificationsActions.LoadNotificationsFailed()];
      } else {
        this.notificationsService.subscribeToChanges(r.loginInfo.payload.username, r.loginInfo.serialNumber)
          .subscribe((notifications) => {
            this.store.dispatch(NotificationsActions.ChangeNotifications({ notifications: notifications }));
          });
        return [NotificationsActions.SetNotifications({
          notifications: r.doc,
          templates: r.templates
        })];
      }
    }),
    catchError(() => [NotificationsActions.LoadNotificationsFailed()])
  ));

  updateNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(NotificationsActions.UpdateNotifications),
    map((action) => action),
    switchMap(action =>
      this.store.select(NotificationsSelectors.selectAllNotifications)
        .pipe(map(notifications => ({ notifications, ids: action.payload })), first())
    ),
    switchMap(({ notifications, ids }) =>
      this.store.select(NotificationsSelectors.selectCurrentTemplates)
        .pipe(map(templates => ({ notifications, ids, templates })), first())
    ),
    tap(({ notifications, ids }) => {
      this.notificationsService.updateNotification(notifications, ids)
    }),
    switchMap(({ notifications, ids, templates }) => [
      NotificationsActions.SetNotifications({ notifications, templates })
    ])
  ), { dispatch: true }); // since the effect doesn't dispatch an action we have to manually unsubscribe to every inner observable to prevent an infinite loop

  changeLanguage$ = createEffect(() => this.actions$.pipe(
    ofType(NotificationsActions.ChangeLanguage),
    map((action) => action.payload),
    switchMap(language => this.notificationsService.fetchTemplates()
      .pipe(
        take(1),
        map(v => {
          let templates: any = v;
          return {
            templates: templates[language],
          };
        })
      )),
    switchMap((templates) => [NotificationsActions.SetLanguage(templates)]),
    catchError(() => [NotificationsActions.ChangeLanguageFailed()])
  ));
}
