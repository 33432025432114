<div class="container">
  <div class="row">
    <div class="col-6 align-self-center text-center" style="font-size: 0.78rem">
      <div class="lastDetectionFontSize" *ngIf="!isMobile()">
        {{ 'SENSORSCOMPONENT.LASTDETECTION' | translate }}:
        {{ lastMeasureTimestamp }}
      </div>
    </div>
    <div class="col-6 align-self-center text-center">
      <pwa-battery-icon [batteryStatus]="batteryStatus">
      </pwa-battery-icon>
    </div>
  </div>
</div>
