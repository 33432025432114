import { createAction, props } from '@ngrx/store';
import { DashboardSensor , Sensor, SensorExtended, Threshold } from '../../models';
import { Measure } from '@pwa/graphql';

export const SET_SENSORS = '[Sensors] Load Sensors Success';

export const LOAD_SENSORS_DATA = '[Sensors] Load Sensors Data';

export const SET_SENSORS_DATA = '[Sensors] Load Sensors Data Success';

export const LOAD_LAST_48H_MEASURES = '[Sensors] Load Last 48h Measures';

export const SET_LAST_48H_MEASURES = '[Sensors] Load Last 48h Measures Success';

export const UPDATE_THRESHOLD = '[Sensors] Update Threshold';

export const ADD_MEASURE = '[Sensors] Add Measure';

export const SetSensors = createAction(
  SET_SENSORS,
  props<{
    sensors: SensorExtended[]
  }>()
)

export const LoadSensorsData = createAction(
  LOAD_SENSORS_DATA,
  props<{
    payload: Sensor[]
  }>()
)

export const SetSensorsData = createAction(
  SET_SENSORS_DATA,
  props<{
    payload: DashboardSensor[]
  }>()
)

export const LoadLast48hMeasures = createAction(
  LOAD_LAST_48H_MEASURES,
  props<{
    paylaod: string
  }>()
)

export const SetLast48hMeasures = createAction(
  SET_LAST_48H_MEASURES,
  props<{
    id: string,
    last48hMeasures: Measure[]
  }>()
)

export const UpdateThreshold = createAction(
  UPDATE_THRESHOLD,
  props<{
    payload: Threshold
  }>()
)


export const AddMeasure = createAction(
  ADD_MEASURE,
  props<{
    payload: Measure
  }>()
)
